import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export const PageError = () => {
	const { t } = useTranslation();
	return (
		<div className="error">
			<div className="error-container">
				<header>{t("errorPage.title")}</header>
				<p>{t("errorPage.text")}</p>
			</div>
		</div>
	);
};
