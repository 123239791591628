import { t } from "i18next";
import React, { ReactNode, createContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getDealerList } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const DealerContext = createContext({
	list: [] as any[],
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	isLoading: false,
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {},
	onGetDealerList: (params: any) => {}
});

const defaultParams = defaultListParams();

export const DealerProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const listParams = useRef<ListParams>(defaultParams);

	const [list, setList] = useState<any[]>([]);

	const handleGetDealerList = async () => {
		try {
			const { limit, offset, term } = { ...listParams.current };
			const currentPage = offset - 1;

			const params = {
				term,
				offset: currentPage,
				limit
			};
			setIsLoading(true);
			const response = await getDealerList(params);
			setList(response.data.dealerList);
			updateParams(Math.ceil(response.data.total / limit), "total");
		} catch (error) {
			toast.error(t("equipmentContext.getEquipmentsListError"));
		} finally {
			setIsLoading(false);
		}
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		handleGetDealerList();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetDealerList();
	};

	const value = {
		list,
		...listParams.current,
		isLoading,
		onSearchParams,
		onPageChange,
		onGetDealerList: handleGetDealerList
	};

	return (
		<DealerContext.Provider value={value}>{children}</DealerContext.Provider>
	);
};
