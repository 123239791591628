import React, { useState } from "react";
import { LuAlertTriangle } from "react-icons/lu";
import { ModalAlternativeProduct } from "../../../../components/ModalAlternativeProduct";
import "./styles.scss";

type OrderItemsContentViewingModeProps = {
	order?: any;
};

export const OrderItemsContentViewingMode = ({
	order
}: OrderItemsContentViewingModeProps) => {
	const [showAlternativeProduct, setShowAlternativeProduct] = useState<any>();

	return (
		<div className="items-information-contain">
			<div className="items-table">
				<div className="items-body-container">
					{order?.items?.map((item: any, index: number) => {
						return (
							<div key={item?.id} className="container-item">
								<div
									id={`items-list-details-${index}`}
									className="items-list-details"
								>
									<ul className="items-list-order-item items-list-order-product-code">
										<div className="order-product-container">
											<span>{item?.code}</span>
										</div>
									</ul>
									<ul className="items-list-order-item items-list-order-description">
										<span title={item?.product?.b1Desc}>
											{item?.product?.b1Desc}
										</span>
									</ul>
									<ul className="items-list-order-item items-list-order-amount">
										<span>{item?.amount}</span>
									</ul>
									<ul className="items-list-order-item items-list-order-price">
										<span>{item?.price}</span>
									</ul>
									<ul className="items-list-order-item items-list-order-total-price">
										<span>{item?.amount * item?.price || 0}</span>
									</ul>
									<ul className="items-list-order-item items-list-order-ipi">
										<span>{item?.product?.b1Ipi}</span>
									</ul>
									<ul
										className="items-list-order-item items-list-order-alert"
										onClick={() =>
											item?.product?.sgi010?.length > 0 &&
											setShowAlternativeProduct(item)
										}
									>
										{item?.product?.sgi010.length > 0 && (
											<LuAlertTriangle
												title={"Produto Com Código Alternativo"}
											/>
										)}
									</ul>
									<ul className="items-list-order-item items-list-order-options">
										<div />
									</ul>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{showAlternativeProduct && (
				<ModalAlternativeProduct
					item={showAlternativeProduct}
					onClose={() => setShowAlternativeProduct(undefined)}
				/>
			)}
		</div>
	);
};
