import {
	Button,
	CheckBox,
	Divider,
	GenericTable,
	Input,
	LoadingProgress,
	Modal,
	Select
} from "@praticabr/ppsa";
import { TrashIcon } from "lucide-react";
import React, { useContext, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PartnerContext } from "../../context/PartnerContext";
import {
	managerPartnerType,
	updatePartnerRequest
} from "../../models/usecases";
import { store } from "../../store";
import { roles, tables } from "../../utils/constants";
import { AddButton } from "../AddButton";
import { DealerSearchModal } from "../DealerSearchModal";
import "./styles.scss";

type Props = {
	onClose: () => void;
	partner: managerPartnerType;
};

export const ManagePartnerModal = ({ onClose, partner }: Props) => {
	const [showDealerSearchShowModal, setShowDealerSearchShowModal] =
		useState(false);
	const [partnerIndex, setPartnerIndex] = useState<number | null>(null);
	const { onUpdatePartner, isLoading } = useContext(PartnerContext);
	const { t } = useTranslation();

	const adminCS = store
		.getState()
		.auth.roles.some((rol: number) => rol === 3 || rol === 4);

	const {
		handleSubmit,
		register,
		setValue,
		getValues,
		formState: { errors },
		control,
		getFieldState,
		watch
	} = useForm<updatePartnerRequest>({
		defaultValues: {
			sellersOnPartners: partner?.sellersOnPartners?.map((item) => ({
				...item,
				partnerId: item.id
			})),
			partnerId: partner.id,
			name: partner.name,
			code: partner.code,
			table: partner.table,
			isActive: partner.isActive,
			type: partner.type
		}
	});

	const { fields, append, remove, update } = useFieldArray({
		control,
		name: "sellersOnPartners"
	});

	watch("sellersOnPartners");
	watch("type");

	return (
		<>
			<Modal.root>
				<Modal.header title={t("managePartnerModal.title")} onClose={onClose} />
				<Modal.body>
					<Modal.body.main>
						<div className="manage-partner-modal">
							<div className="content">
								<Divider title={t("managePartnerModal.divider.dealers")} />
								<div className="manage-partner-modal-partner-table">
									<GenericTable
										data={[
											...fields.map((item, index) => ({
												...item,
												code: item.code,
												name: item.name,
												table: item.table,
												actionTable: (
													<>
														{fields.length > 1 && (
															<div
																onClick={() => remove(index)}
																className="action-icon"
															>
																<TrashIcon
																	size={"24px"}
																	color="var(--primary-light)"
																/>
															</div>
														)}
													</>
												)
											}))
										]}
										columns={[
											{
												key: "code",
												header: t("managePartnerModal.inputCode.label"),
												width: "20%"
											},
											{
												key: "name",
												header: t("managePartnerModal.inputPartnerName.label"),
												width: "35%"
											},
											{
												key: "table",
												header: t("managePartnerModal.selectTable.label"),
												width: "25%"
											},
											{
												key: "actionTable",
												header: "",
												width: "24px"
											}
										]}
									/>
								</div>
								<div>
									{adminCS && (
										<AddButton
											label={t("managePartnerModal.addButton")}
											onAddItem={() => setShowDealerSearchShowModal(true)}
										/>
									)}
								</div>
								<Divider title={t("managePartnerModal.divider.partner")} />
								<div className="form">
									<div className="form-item">
										<label htmlFor="name">
											{t("managePartnerModal.codeInput.label")}
										</label>
										<Input
											variant="light"
											value={partner.code}
											readOnly
											disabled
											errors={errors.code}
											{...register("code")}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="name">
											{t("managePartnerModal.nameInput.label")}
										</label>
										<Input
											variant="light"
											defaultValue={partner.name}
											placeholder={t(
												"managePartnerModal.nameInput.placeholder"
											)}
											{...register("name", {
												required: {
													value: true,
													message: t(
														"managePartnerModal.nameInput.requiredAlert"
													)
												}
											})}
										/>
									</div>
								</div>
								<div className="form">
									<div className="form-item">
										<label htmlFor="table">
											{t("managePartnerModal.tableSelect.label")}
										</label>
										<Select
											id="table"
											onSelectOptions={(option) => setValue("table", option[0])}
											options={tables}
											selectedOption={[getValues("table")]}
										/>
									</div>
								</div>
								<Divider title={t("managePartnerModal.divider.roles")} />
								<div className="roles">
									{roles.slice(0, 2).map((role, index) => {
										return (
											<div className="checkbox" key={index}>
												<CheckBox
													format="square"
													variant="standard"
													id={role.label}
													type="checkbox"
													checked={getValues("type") === role.name}
													onChange={(e) =>
														getValues("type") === role.name
															? setValue("type", "")
															: setValue("type", role.name)
													}
												/>
												<label htmlFor={role.label}>{role.label}</label>
											</div>
										);
									})}
									{errors.type && (
										<p>{t("managePartnerModal.roleInput.checkedAlert")}</p>
									)}
								</div>
							</div>
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="manage-partner-modal-actions">
							<Button
								title={t("managePartnerModal.submitAction")}
								size="lg"
								variant="confirmation-solid"
								onClick={handleSubmit((data) =>
									onUpdatePartner(data, () => onClose())
								)}
							/>
							<Button
								size="lg"
								title={t("managePartnerModal.cancelAction")}
								variant="cancellation"
								onClick={onClose}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{isLoading && <LoadingProgress />}
			{showDealerSearchShowModal && (
				<DealerSearchModal
					onClose={() => setShowDealerSearchShowModal(false)}
					onChangeDealer={(partner: any) => {
						append(partner);
						setShowDealerSearchShowModal(false);
					}}
					arrayDealers={fields}
				/>
			)}
		</>
	);
};
