import React, { useContext } from "react";
import { PraticaContext } from "../../../context/PraticaContext";
import {
	BRANCH_TYPES,
	CATEGORY_TYPES,
	FREIGHT_TYPES,
	dateProtheusToDate,
	handleType
} from "../../../utils/protheus-functions";
import "./styles.scss";

export const InfoTimeLine = () => {
	const { sc5010 } = useContext(PraticaContext);

	return (
		<>
			<div className="information">
				<div className="header">
					<h1>
						<strong>Pedido: </strong>
						{sc5010?.c5Num}
					</h1>
					<div className="double">
						<div className="info">
							<strong>Local: </strong>
							{handleType({ value: sc5010?.c5Filial, types: BRANCH_TYPES })}
						</div>
						<div className="info">
							<strong>Data: </strong>
							{dateProtheusToDate(sc5010?.c5Emissao)?.toLocaleDateString(
								"pt-BR"
							)}
						</div>
					</div>
				</div>
				<div className="main-contain">
					<div className="double">
						<div className="info">
							<header>Cliente</header>
							<span>{sc5010?.c5Client}</span>
						</div>
						<div className="info">
							<header>Loja</header>
							<span>{sc5010?.c5Lojacli}</span>
						</div>
					</div>
					<div className="double">
						<div className="info">
							<header>Categoria</header>
							<span>
								{handleType({
									value: sc5010?.c5Categor,
									types: CATEGORY_TYPES
								})}
							</span>
						</div>
						<div className="info">
							<header>Tipo de frete</header>
							<span>
								{handleType({
									value: sc5010?.c5Tpfrete,
									types: FREIGHT_TYPES
								})}
							</span>
						</div>
					</div>
					{(sc5010?.c5Ordserv || sc5010?.c5Xprp) && (
						<div className="double">
							<div className="info">
								<header>OS Protheus</header>
								<span>{sc5010?.c5Ordserv}</span>
							</div>
							<div className="info">
								<header>OS PRP</header>
								<span>{sc5010?.c5Xprp}</span>
							</div>
						</div>
					)}
					<div className="info">
						<header>Observação para nota</header>
						<span>{sc5010?.c5Mennota}</span>
					</div>
				</div>
			</div>
		</>
	);
};
