export function handleStatusRequestTypes(status_request?: string) {
	if (!status_request) return "-";
	const statusRequestTypes = StatusRequestTypes.filter(
		(StatusRequestTypes) => StatusRequestTypes.value === status_request
	);
	return statusRequestTypes[0]?.label || "-";
}

export const StatusRequestTypes = [
	{ value: "NEW", label: "Novo" },
	{ value: "PARTIALLY_MET", label: "Parcialmente Atendido" },
	{ value: "TOTALLY_MET", label: "Totalmente atendido" },
	{ value: "CANCELLATION_REQUEST", label: "Solicitado cancelamento" },
	{ value: "CANCELED", label: "Cancelado" },
	{ value: "OBSERVATION", label: "Observação" }
];

export function handleStatusItemTypes(status_item?: string) {
	if (!status_item) return "-";
	const statusItemTypes = StatusItemTypes.filter(
		(StatusItemTypes) => StatusItemTypes.value === status_item
	);
	return statusItemTypes[0]?.label || "-";
}

export const StatusItemTypes = [
	{ value: "NEW", label: "Novo" },
	{ value: "NOT_MET", label: "Não atendido" },
	{ value: "TOTAL", label: "Total atendido" },
	{ value: "PARTIAL", label: "Atendido Parcial" }
];
