import React from "react";
import { useSelector } from "react-redux";
import PraticaIcon from "../../../assets/SvgIcons/PraticaIcon";
import "./style.scss";

interface HangTagProps {
	data: any[];
	targetRef: React.MutableRefObject<any>;
}

export const HangTag: React.FC<HangTagProps> = ({ data, targetRef }) => {
	const user = useSelector((state: any) => state.auth);

	return (
		<div className={"hang-tag-style"} ref={targetRef}>
			{data.map((part, index) => (
				<div className="hang-tag" key={index}>
					<div className="hang-tag-first-line">
						<div className="hang-tag-icon">
							<PraticaIcon height="100%" width="100%" />
						</div>
						<div className="hang-tag-item">
							<label>DATA:</label>
							<span>{new Date().toLocaleDateString()}</span>
						</div>
						<div className="hang-tag-item">
							<label>OS:</label>
							<span>
								{part?.zegNumos}/{part?.ab6010?.ab6Xprp}
							</span>
						</div>
					</div>
					<div className="hang-tag-second-line">
						<div className="hang-tag-item">
							<label>NOTA FISCAL:</label>
							<span>{part?.invoice}</span>
						</div>
						<div className="hang-tag-item">
							<label>N° SÉRIE:</label>
							<span>{part?.ab6010?.ab6Numser}</span>
						</div>
					</div>
					<div className="hang-tag-third-line">
						<div className="hang-tag-item">
							<label>CÓDIGO:</label>
							<span>{part?.zegProdut}</span>
						</div>
						<div className="hang-tag-item">
							<label>DESCRIÇÃO:</label>
							<span>{part?.zegDescri}</span>
						</div>
					</div>
					<div className="hang-tag-fourth-line">
						<div className="hang-tag-item">
							<label>DEFEITO:</label>
							<span></span>
						</div>
					</div>
					<div className="hang-tag-fifth-line">
						<div className="hang-tag-item">
							<label>AUTORIZADA:</label>
							<span>
								{user.partner.code.slice(0, 6)}-{user.partner.code.slice(6, 8)}{" "}
								{user.partner.name}
							</span>
						</div>
						<div className="hang-tag-item">
							<label>TÉCNICO:</label>
							<span></span>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
