import React, { useState } from "react";
import ArrowDropIcon from "../../assets/SvgIcons/ArrowDropIcon";
import UserIcon from "../../assets/images/usericon2.svg";
import ClickAwayListener from "../../utils/ClickAwayListener";
import { UserConfigModal } from "../UserConfigModal";
import { UserSelector } from "./UserSelector";
import "./styles.scss";

export const UserDropdown: React.FC = () => {
	const [showConfigModal, setShowConfigModal] = useState<boolean>(false);
	const [showUserSelector, setShowUserSelector] = useState<boolean>(false);

	const handleConfigModalToggle = () => {
		setShowConfigModal(!showConfigModal);
	};

	const handlerUserSelectorToggle = () => {
		setShowUserSelector(!showUserSelector);
	};

	const handleClientDropDownIcon = () => {
		return showUserSelector ? (
			<ArrowDropIcon width="10px" height="10px" fill="#025CB7" direction="up" />
		) : (
			<ArrowDropIcon
				width="10px"
				height="10px"
				fill="#025CB7"
				direction="down"
			/>
		);
	};

	const userSelectorContent = () => {
		return showUserSelector ? (
			<UserSelector
				{...{
					handleConfigModalToggle,
					onClose: handlerUserSelectorToggle
				}}
			/>
		) : (
			<></>
		);
	};

	const userContentBadge = () => {
		return (
			<ClickAwayListener onClickAway={() => setShowUserSelector(false)}>
				<div onClick={handlerUserSelectorToggle} className="user-area">
					<img
						width="30px"
						src={UserIcon}
						alt="Usuário ícone"
						style={{ border: "solid #red " }}
					/>
					{handleClientDropDownIcon()}
					{userSelectorContent()}
				</div>
			</ClickAwayListener>
		);
	};

	return (
		<>
			{userContentBadge()}
			{showConfigModal && <UserConfigModal onClose={handleConfigModalToggle} />}
		</>
	);
};
