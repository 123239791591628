import React from "react";
import { useTranslation } from "react-i18next";
import { store } from "../../../store";
import "./styles.scss";

interface Params {
	handleChangePartner: (partnerId: number) => void;
}

export const PartnerSelector = ({ handleChangePartner }: Params) => {
	const user = store.getState().auth;
	const { availablePartners = [], partnerId } = user;

	const {
		i18n: { language },
		t
	} = useTranslation();

	return (
		<section className="partner-area-selector">
			{availablePartners?.map((item: any, index: number) => (
				<div
					key={index}
					className="partner-area-selector-item"
					onClick={() =>
						partnerId !== item?.id && handleChangePartner(item?.id)
					}
				>
					<span>{item?.name}</span>
				</div>
			))}
		</section>
	);
};
