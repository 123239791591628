import { GenericTable, LoadingProgress, Paginator } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { LuBadge, LuBadgeCheck } from "react-icons/lu";
import { HeaderSection } from "../../components";
import { PraticaContext } from "../../context/PraticaContext";
import i18n from "../../i18n/i18n";
import {
	BRANCH_TYPES,
	CATEGORY_TYPES,
	dateProtheusToDate,
	handleType
} from "../../utils/protheus-functions";
import "./styles.scss";

export function PraticaOrders() {
	const { orders, isLoading, onChangeParams, total, offset } =
		useContext(PraticaContext);
	const [legendSelect, setLegendSelect] = useState<string>("pending");

	const legends = {
		pending: {
			key: "pending",
			status: <LuBadge color="#5DAEFF" size={18} />,
			label: t("PraticaOrders.legends.pending")
		},
		invoiced: {
			key: "invoiced",
			status: <LuBadgeCheck color="#048243" size={18} />,
			label: t("PraticaOrders.legends.invoiced")
		}
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const legend = urlParams.get("legends") as "pending" | "invoiced";
		if (legend) {
			setTimeout(() => {
				setLegendSelect(legends[legend].key);
			}, 100);
		}
	}, []);

	useEffect(() => {
		onChangeParams({ status: legendSelect });
	}, [legendSelect]);

	const statusChange = ({
		c5Nota,
		c5Liberok,
		c5Blq
	}: {
		c5Nota: string;
		c5Liberok: string;
		c5Blq: string;
	}) => {
		if (c5Nota !== "" || (c5Liberok === "E" && c5Blq === "")) {
			return "invoiced";
		} else {
			return "pending";
		}
	};

	const onSearch = (term: string) => {
		onChangeParams({ term, offset: 1, status: legendSelect });
	};

	function onRowClicked(data: any) {
		window.location.href = `/${i18n.language}/pratica/order/${data.c5Filial}/${data.c5Num}`;
	}

	return (
		<>
			<article className="protheus-orders">
				<HeaderSection.root>
					<div className="protheus-orders-header">
						<div className="protheus-orders-search">
							<HeaderSection.search
								onChangeValues={onSearch}
								text={t("PraticaOrders.searchHeader")}
							/>
						</div>
						<HeaderSection.legend>
							{Object.values(legends).map((legend) => {
								return (
									<div
										className="legend"
										key={legend.key}
										onClick={() =>
											setLegendSelect((prevLegend) =>
												prevLegend === legend.key ? "" : legend.key
											)
										}
									>
										{legend.status}
										<span
											className={`${legendSelect === legend.key && "active"}`}
										>
											{legend.label}
										</span>
									</div>
								);
							})}
						</HeaderSection.legend>
					</div>
				</HeaderSection.root>
				<div className="protheus-orders-table">
					<GenericTable
						onRowClicked={onRowClicked}
						data={orders.map((sc5) => ({
							...sc5,
							id: sc5?.rECNO,
							status: legends[statusChange({ ...sc5 })]?.status,
							issueDate: dateProtheusToDate(sc5?.c5Emissao)?.toLocaleDateString(
								"pt-BR"
							),
							branch: handleType({
								value: sc5?.c5Filial,
								types: BRANCH_TYPES
							}),
							order: sc5?.c5Num,
							category: handleType({
								value: sc5?.c5Categor,
								types: CATEGORY_TYPES
							}),
							observation: sc5?.c5Mennota
						}))}
						columns={[
							{
								key: "status",
								header: "",
								width: "38px"
							},
							{
								key: "issueDate",
								header: t("PraticaOrders.tableColumns.issueDate"),
								width: "10%"
							},
							{
								key: "branch",
								header: t("PraticaOrders.tableColumns.branch"),
								width: "10%"
							},
							{
								key: "order",
								header: t("PraticaOrders.tableColumns.order"),
								width: "10%"
							},
							{
								key: "category",
								header: t("PraticaOrders.tableColumns.category"),
								width: "20%"
							},
							{
								key: "observation",
								header: t("PraticaOrders.tableColumns.observation"),
								width: "calc(50% - 38px)"
							}
						]}
					/>
				</div>
				<div className="pratica-orders-paginator">
					<Paginator
						currentPage={offset}
						totalPages={total || 1}
						onPageChange={(value) =>
							onChangeParams({ offset: value, status: legendSelect })
						}
					/>
				</div>
			</article>
			{isLoading && <LoadingProgress />}
		</>
	);
}
