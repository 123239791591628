import { t } from "i18next";
import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RequestStatusUpdateForm } from "../pages/Order/OrderView";
import {
	cancelRequestById,
	deleteOrderById,
	getOrderById,
	getOrders,
	handleRequest,
	putStatusOrder
} from "../services";
import { store } from "../store";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const OrderContext = createContext({
	list: [] as any[],
	isLoading: false,
	limit: 20,
	total: 1,
	offset: 1,
	order: {} as any,
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {},
	onGetOrderList: (params: any) => {},
	handleOrderById: (id: number) => {},
	handleRequestUpdateStatus: (
		data: RequestStatusUpdateForm,
		callback: (data: any) => void
	) => {},
	HandleCancelRequestById: (id: number) => {},
	handleOrderDelete: (id: number) => {},
	handleMeetRequest: (data: any, callback: (data: any) => void) => {}
});

const defaultParams = defaultListParams();

export const OrderProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [order, setOrder] = useState<any>(null);
	const [list, setList] = useState<any[]>([]);

	const { roles } = useSelector((state: any) => state?.auth);

	const { i18n } = useTranslation();

	const language = i18n.language || store.getState().auth.language;

	const listParams = useRef<ListParams>(defaultParams);

	const navigate = useNavigate();

	const handleGetOrderList = async () => {
		try {
			const { limit, filter, offset, term, total } = { ...listParams.current };

			const params = {
				limit,
				filter,
				offset: offset - 1,
				term,
				total
			};
			setIsLoading(true);
			const response = await getOrders(params);
			setList(response.data.orders);
			updateParams(Math.ceil(response.data.count / limit), "total");
		} catch (error) {
			toast.error(t("equipmentContext.getEquipmentsListError"));
		} finally {
			setIsLoading(false);
		}
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		handleGetOrderList();
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetOrderList();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const handleOrderById = async (id: number) => {
		const params = {
			id
		};
		try {
			setIsLoading(true);
			const response = await getOrderById(params);
			setOrder(response.data.order);
		} catch (error) {
			toast.error(t("equipmentContext.getEquipmentsListError"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleOrderDelete = async (id: number) => {
		const params = {
			id
		};
		try {
			const response = await deleteOrderById(params);
			setIsLoading(true);
			if (response.status === 200) {
				toast.success(t("orderContext.deleteOrderSuccess"));
				navigate(`/${language}/requests`);
			}
		} catch (error) {
			toast.error(t("equipmentContext.getEquipmentsListError"));
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleRequestUpdateStatus = (
		data: RequestStatusUpdateForm,
		callback: (data?: any) => void
	) => {
		setIsLoading(true);
		putStatusOrder(data)
			.then(() => {
				handleOrderById(data?.requestId);
				callback();
			})
			.catch((error) => {
				setIsLoading(false);
				callback(error);
			});
	};

	const HandleCancelRequestById = async (id: number) => {
		setIsLoading(true);
		cancelRequestById({ requestId: id })
			.then(() => {
				setIsLoading(false);
				navigate(`/${language}/requests`);
				toast.success(t("orderContext.cancelRequestSuccess"));
			})
			.catch((error) => {
				setIsLoading(false);
				toast.error(t("orderContext.cancelRequestError"));
				console.log(error);
			});
	};

	const handleMeetRequest = async (
		data: any,
		callback: (data?: any) => void
	) => {
		setIsLoading(true);
		handleRequest(data)
			.then(() => {
				handleOrderById(data?.requestId);
				callback();
			})
			.catch((error) => {
				setIsLoading(false);
				callback(error);
			});
	};

	const handleOrderCreate = async (data: any) => {};

	const value = {
		list,
		...listParams.current,
		isLoading,
		order,
		onSearchParams,
		onPageChange,
		onGetOrderList: handleGetOrderList,
		handleOrderById,
		handleOrderDelete,
		handleRequestUpdateStatus,
		HandleCancelRequestById,
		handleMeetRequest
	};

	return (
		<OrderContext.Provider value={value}>{children}</OrderContext.Provider>
	);
};
