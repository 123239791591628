import React, {
	ReactNode,
	createContext,
	useEffect,
	useRef,
	useState
} from "react";
import { toast } from "react-toastify";
import { upsertProductSchema } from "../components/ManageProductModal";
import { Sb1010sCustomList } from "../models/usecases/sb1010s-custom-list";
import { getProducts, getSubTags, getTags, upsertProduct } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	clearListParams,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export interface ContextType {
	productList: Sb1010sCustomList.Return["list"];
	tags: any[];
	subTags: any[];
	selectedTags: number[];
	setSelectedTags: React.Dispatch<React.SetStateAction<number[]>>;
	selectedSubTags: number[];
	setSelectedSubTags: React.Dispatch<React.SetStateAction<number[]>>;
	isLoading: boolean;
	limit: number;
	total: number;
	offset: number;
	onUpsertProduct: (data: upsertProductSchema, callback?: () => void) => void;
	onUpdateList: () => void;
	onSearchParams: (searchTerm: string) => void;
	onPageChange: (page: number) => void;
}

export const ProductsContext = createContext<ContextType>({
	productList: [],
	tags: [],
	subTags: [],
	selectedTags: [],
	setSelectedTags: () => {},
	selectedSubTags: [],
	setSelectedSubTags: () => {},
	isLoading: false,
	limit: 20,
	total: 1,
	offset: 1,
	onUpsertProduct: () => {},
	onUpdateList: () => {},
	onPageChange: (page: number) => {},
	onSearchParams: (searchTerm: string) => {}
});

const defaultParams = defaultListParams();

export const ProductsProvider = ({ children }: { children: ReactNode }) => {
	const [productList, setProductList] = useState<
		Sb1010sCustomList.Return["list"]
	>([]);
	const [tags, setTags] = useState<any[]>([]);
	const [subTags, setSubTags] = useState<any[]>([]);
	const [selectedTags, setSelectedTags] = useState<number[]>([]);
	const [selectedSubTags, setSelectedSubTags] = useState<number[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const params = useRef<ListParams>(defaultParams);

	useEffect(() => {
		if (!tags.length) {
			onGetTags();
		}
		if (!subTags.length) {
			onGetSubTags();
		}

		onGetProducts();
	}, [selectedTags, selectedSubTags]);

	const onGetProducts = async () => {
		const { limit, filter, offset, term, total } = { ...params.current };

		setIsLoading(true);
		await getProducts(
			{ limit, term, filter, offset, total },
			selectedTags,
			selectedSubTags
		)
			.then((response) => {
				setProductList(response.data.list);
				updateParams(
					Math.ceil(response.data.count / params.current.limit),
					"total"
				);
			})
			.catch(() => {
				toast.error("Erro ao buscar produtos");
				clearParams(true);
			})
			.finally(() => setIsLoading(false));
	};

	const onGetTags = () => {
		getTags()
			.then((response) => {
				setTags(response.data);
			})
			.catch(() => {
				toast.error("Erro ao buscar tags");
			});
	};

	const onGetSubTags = () => {
		getSubTags()
			.then((response) => {
				setSubTags(response.data);
			})
			.catch(() => {
				toast.error("Erro ao buscar tags");
			});
	};

	const onUpsertProduct = (
		data: upsertProductSchema,
		callback?: () => void
	) => {
		if (isLoading) return;

		setIsLoading(true);
		upsertProduct(data)
			.then(async () => {
				await onGetProducts();
				toast.success("Produto atualizado com sucesso!");
				callback && callback();
			})
			.catch(() => toast.error("Erro ao atualizar o produto"))
			.finally(() => setIsLoading(false));
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		params.current = updateListParams(value, field, params.current);
	};

	const clearParams = (clearAll = false) => {
		params.current = clearListParams(params.current, clearAll);
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		updateParams(1, "offset");
		onGetProducts();
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		onGetProducts();
	};

	const value = {
		productList,
		tags,
		subTags,
		selectedTags,
		setSelectedTags,
		selectedSubTags,
		setSelectedSubTags,
		isLoading,
		...params.current,
		onUpsertProduct,
		onUpdateList: () => onGetProducts(),
		onSearchParams,
		onPageChange
	};

	return (
		<ProductsContext.Provider value={value}>
			{children}
		</ProductsContext.Provider>
	);
};
