import { Modal, Search } from "@praticabr/ppsa";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import SearchSvg from "../../../../assets/SvgIcons/SearchSvg";
import LoadingList from "../../../../components/LoadingList";
import { SellerContext } from "../../../../context/SellerContext";
import "./styles.scss";

interface OrderDealerSearchProps {
	onClose: (value: boolean) => void;
	onChangeDealer: (item: any) => void;
}

const OrderDealerSearch = ({
	onClose,
	onChangeDealer
}: OrderDealerSearchProps) => {
	const { onSearchParams, list, isLoading } = useContext(SellerContext);

	const { partner } = useSelector((state: any) => state?.auth);

	const {
		formState: { errors }
	} = useForm();

	useEffect(() => {
		onSearchParams("");
	}, []);

	const onChangeValues = (value: string) => {
		onSearchParams(value.toUpperCase());
	};

	const orderDealerSearchHeader = () => {
		return (
			<div className="dealers-header-list">
				<div className="dealers-details-list">
					<ul className="dealers-details-list-item dealers-details-list-code">
						<span>Código</span>
					</ul>
					<ul className="dealers-details-list-item dealers-details-list-company-name">
						<span>Nome</span>
					</ul>
				</div>
			</div>
		);
	};

	const orderDealerSearchBodyMobile = () => {
		return (
			<div className="dealers-body-list">
				{list?.map((item: any, index: number) => {
					return (
						<div
							className="dealers-body-item"
							key={index}
							onClick={() => {
								onChangeDealer(item);
							}}
						>
							<div className="dealers-details-list">
								<ul className="dealers-details-list-item dealers-details-list-code">
									<span>Código:</span>
									<span>{item?.id}</span>
								</ul>
								<ul className="dealers-details-list-item dealers-details-list-company-name">
									<span>Razão social:</span>
									<span>{item?.contact}</span>
								</ul>
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	const orderDealerSearchBodyWeb = () => {
		return (
			<div className="dealers-body-list">
				{list
					?.filter((item: any) => item?.id !== partner.id)
					.map((item: any, index: number) => {
						return (
							<div
								className="dealers-body-item"
								key={index}
								onClick={() => {
									onChangeDealer(item);
								}}
							>
								<div className="dealers-details-list">
									<ul className="dealers-details-list-item dealers-details-list-code">
										<span>{item?.code}</span>
									</ul>
									<ul className="dealers-details-list-item dealers-details-list-company-name">
										<span>{item?.name}</span>
									</ul>
								</div>
							</div>
						);
					})}
			</div>
		);
	};

	return (
		<Modal.root>
			<Modal.header
				title="BUSCAR CONCESSIONÁRIA"
				onClose={() => {
					onClose(false);
				}}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="dealers-list-container">
						<div className="dealer-input-container">
							<div>
								<Search.root>
									<Search.icon>
										<SearchSvg />
									</Search.icon>
									<Search.input
										{...{ onChangeValues }}
										placeholder={"Buscar concessionária"}
									/>
								</Search.root>
							</div>
						</div>
						<div className="dealers-body">
							{isLoading ? (
								<LoadingList show={true} />
							) : (
								<>
									{orderDealerSearchHeader()}
									{orderDealerSearchBodyWeb()}
								</>
							)}
						</div>
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	);
};
export default OrderDealerSearch;
