import { LoadingProgress } from "@praticabr/ppsa";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { PraticaContext } from "../../../context/PraticaContext";
import { useViewVariable } from "../../../context/ViewContext";
import { InfoTimeLine } from "../InfoTimeline";
import { InfoTimelineMobile } from "../InfoTimeline/InfoTimelineMobile";
import "./styles.scss";

type Props = {
	tabs: {
		title: string;
		children: ReactNode;
	}[];
};

export const OrderView = ({ tabs }: Props) => {
	const { isLoading } = useContext(PraticaContext);
	const [selectedTab, setSelectedTab] = useState<string>();
	const { mobileSize } = useViewVariable();
	useEffect(() => {
		if (tabs?.length) {
			setSelectedTab(tabs[0]?.title);
		}
	}, [tabs]);

	return (
		<div className="pratica-order-view">
			<div className="timeline-container">
				{!mobileSize ? <InfoTimeLine /> : <InfoTimelineMobile />}
				<div className="timeline">
					<div className="menu">
						<div className="line">
							{tabs.map((tab) => (
								<div
									key={tab.title}
									className="items-list"
									onClick={() => setSelectedTab(tab.title)}
								>
									<span className={`${selectedTab === tab?.title && "active"}`}>
										{tab?.title.toUpperCase()}
									</span>
								</div>
							))}
						</div>
					</div>
					<div className="content-items">
						{tabs.filter((tab) => tab?.title === selectedTab)[0]?.children}
					</div>
				</div>
			</div>
			{isLoading && <LoadingProgress />}
		</div>
	);
};
