import React, { useContext } from "react";
import { PraticaContext } from "../../context/PraticaContext";
import { OrderView } from "./OrderView";
import { PraticaOrderBillets } from "./PraticaOrderBillets";
import { PraticaOrderItems } from "./PraticaOrderItems";

export const PraticaOrder = {
	View: OrderView,
	Itens: PraticaOrderItems,
	Billets: PraticaOrderBillets
};

export function PraticaOrderPage() {
	const { sc5010 } = useContext(PraticaContext);
	const tabs = [
		{
			title: "Itens",
			children: <PraticaOrder.Itens />
		}
	];

	if (sc5010 && sc5010.se1010.length > 0) {
		tabs.push({
			title: "Boletos",
			children: <PraticaOrder.Billets />
		});
	}

	return <PraticaOrder.View tabs={tabs} />;
}
