import { CheckCheckIcon } from "lucide-react";
import React from "react";
import "./styles.scss";

type Props = {
	label: string;
	color: string;
	bolder?: boolean;
	selected?: boolean;
};

export function Tag({ label, color, bolder = false, selected = false }: Props) {
	return (
		<div
			className="tag-container"
			style={{
				border: `1.8px solid ${color}`,
				backgroundColor: `${color}15`,
				boxShadow: `0px 4px 4px ${color}15`,
				fontWeight: bolder ? "bold" : "normal"
			}}
		>
			{selected && (
				<CheckCheckIcon
					id="check"
					size={18}
					style={{ color, fontWeight: bolder ? "bold" : "normal" }}
				/>
			)}
			<p style={{ color, fontWeight: bolder ? "bold" : "normal" }}>{label}</p>
		</div>
	);
}
