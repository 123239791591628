import { Button, Input, Modal } from "@praticabr/ppsa";
import React from "react";
import { useForm } from "react-hook-form";
import { RequestStatusUpdateForm } from "../../pages/Order/OrderView";
import "./styles.scss";

type Props = {
	requestId: number;
	onClose: () => void;
	onSubmitObservation: (data: RequestStatusUpdateForm) => void;
};

export const ModalObservation = ({
	requestId,
	onClose,
	onSubmitObservation
}: Props) => {
	const {
		register,
		formState: { errors },
		handleSubmit
	} = useForm<RequestStatusUpdateForm>({
		defaultValues: {
			requestId,
			type: "OBSERVATION"
		}
	});

	return (
		<React.Fragment>
			<Modal.root>
				<Modal.header title={"Observação"} onClose={onClose} />
				<Modal.body>
					<Modal.body.main>
						<div className="modal-observation">
							<div className="header">
								<span>Solicitação:</span> {requestId}
							</div>
							<div className="content">
								<label htmlFor="content">Motivo</label>
								<Input
									id="content"
									variant="light"
									{...register("content", {
										required: { value: true, message: "Campo obrigatório" }
									})}
									placeholder="Informe a observação..."
									errors={errors?.content}
								/>
							</div>
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="modal-observation-actions">
							<Button
								title="Enviar"
								variant="confirmation-solid"
								size="lg"
								onClick={handleSubmit(onSubmitObservation)}
							/>
							<Button
								title="Cancelar"
								variant="cancellation"
								size="lg"
								onClick={onClose}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
		</React.Fragment>
	);
};
