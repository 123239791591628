import { GenericTable, LoadingProgress } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { HeaderSection } from "../../../components";
import { ManageTagModal } from "../../../components/ManageTagModal";
import { RegisterTagModal } from "../../../components/RegisterTagModal";
import { Tag } from "../../../components/Tag";
import { Sb1010sContext } from "../../../context/Sb1010sContext";
import "./style.scss";

export const ManagementTag = () => {
	const { isLoading, tagsList, onGetTagsList, onSearchParamsTag } =
		useContext(Sb1010sContext);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showEditTag, setShowEditTag] = useState<boolean>(false);
	const [data, setData] = useState<any>({} as any);

	useEffect(() => {
		onGetTagsList();
	}, [tagsList.length]);

	return (
		<div className="management-catalog-tag">
			<HeaderSection.root>
				<HeaderSection.search
					text="Busque pelo nome da TAG"
					onChangeValues={(e) => onSearchParamsTag(e, "TAG")}
				/>
				<HeaderSection.button
					text="INCLUIR TAG"
					onClick={() => setShowModal(true)}
				/>
			</HeaderSection.root>
			<div className="management-catalog-tag-table">
				<GenericTable
					onRowClicked={(e) => {
						setData(e);
						setShowEditTag(true);
					}}
					data={[
						...tagsList.map((tag: any) => {
							return {
								...tag,
								example: <Tag label={tag?.label} color={tag?.color} />
							};
						})
					]}
					columns={[
						{ width: "50%", header: "Nome", key: "label" },
						{ width: "30%", header: "Cor", key: "color" },
						{ width: "20%", header: "Exemplo", key: "example" }
					]}
				/>
			</div>
			{isLoading && <LoadingProgress />}
			{showModal && (
				<RegisterTagModal type="TAG" onClose={() => setShowModal(false)} />
			)}
			{showEditTag && (
				<ManageTagModal
					type="TAG"
					data={data}
					onClose={() => setShowEditTag(false)}
				/>
			)}
		</div>
	);
};
