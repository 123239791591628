import React from "react";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./context";
import SocketProvider from "./context/SocketContext";
import { ViewProvider } from "./context/ViewContext";
import { useClarity } from "./hooks/use-clarity";
import { useSentry } from "./hooks/use-sentry";
import { useInnerHeightResize } from "./hooks/useInnerHeightResize";
import { LanguageSwitcher } from "./i18n/LanguageSwitcher";
import Routes from "./routes";

export function App() {
	useSentry();
	useClarity();
	useInnerHeightResize();
	return (
		<ViewProvider>
			<AuthProvider>
				<SocketProvider>
					<LanguageSwitcher />
					<Routes />
					<ToastContainer
						position="top-center"
						autoClose={5000}
						newestOnTop={false}
						closeOnClick={true}
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						closeButton={false}
						className="toast-main-content"
						bodyClassName="toast-body-content"
					/>
				</SocketProvider>
			</AuthProvider>
		</ViewProvider>
	);
}
