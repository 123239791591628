import {
    Button,
    CheckBox,
    Divider,
    Input,
    LoadingProgress,
    Modal,
    Select
} from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { AuthContext } from "../../context";
import { PartnerContext } from "../../context/PartnerContext";
import { NewPartnerModel, TechnicalModel } from "../../models";
import { PartnerSearch } from "../../pages/Users/PartnerSearch";
import { roles, tables } from "../../utils/constants";
import "./styles.scss";

type Props = {
	onClose: () => void;
};

const languageOptions = [
	{
		value: "en",
		label: t("language.english")
	},
	{
		value: "pt",
		label: t("language.portuguese")
	},
	{
		value: "es",
		label: t("language.spanish")
	}
];

export const RegisterPartnerModal = ({ onClose }: Props) => {
	const { handleNewPartner, isLoading } = useContext(PartnerContext);
	const { user } = useContext(AuthContext);
	const adminCS = user.roles.includes(4);
	const [check, setCheck] = useState<number>();
	const [showModal, setShowModal] = useState<boolean>(false);

	const { t } = useTranslation();

	const {
		handleSubmit,
		register,
		setValue,
		getValues,
		formState: { errors },
		watch,
		control
	} = useForm<NewPartnerModel>({
		defaultValues: {
			name: "",
			code: "",
			branch: "",
			table: "",
			roles: []
		}
	});

	const setValues = (data: TechnicalModel) => {
		setValue("name", data.a1Nreduz.trim());
		setValue("code", data.a1Cod.trim());
		setValue("branch", data.a1Loja.trim());
		setValue("table", "C20");
	};

	watch("roles");

	return (
		<>
			<Modal.root>
				<Modal.header
					title={t("registerPartnerModal.title")}
					onClose={onClose}
				/>
				<Modal.body>
					<Modal.body.main>
						<div className="register-partner-modal">
							<div className="content">
								<Divider title={t("registerPartnerModal.divider.account")} />
								<div className="form">
									<div className="form-item">
										<label htmlFor="partnerCode">
											{t("registerPartnerModal.partnerCodeInput.label")}
										</label>
										<div className="register-partner-input-with-svg">
											<Input
												variant="light"
												disabled
												errors={errors.code}
												placeholder={t(
													"registerPartnerModal.partnerCodeInput.placeholder"
												)}
												{...register("code", {
													required: {
														value: true,
														message: t(
															"registerPartnerModal.partnerCodeInput.requiredAlert"
														)
													},
													minLength: {
														value: 6,
														message: t(
															"registerPartnerModal.partnerCodeInput.lengthRequired"
														)
													},
													maxLength: {
														value: 6,
														message: t(
															"registerPartnerModal.partnerCodeInput.lengthRequired"
														)
													}
												})}
											/>
											<div
												className="search"
												onClick={() => setShowModal(true)}
											>
												<SearchSvg />
											</div>
										</div>
									</div>
									<div className="form-item">
										<label htmlFor="branch">
											{t("registerPartnerModal.branchInput.label")}
										</label>
										<Input
											variant="light"
											errors={errors.branch}
											disabled
											placeholder={t(
												"registerPartnerModal.branchInput.placeholder"
											)}
											{...register("branch", {
												required: {
													value: true,
													message: t(
														"registerPartnerModal.branchInput.requiredAlert"
													)
												},
												minLength: {
													value: 2,
													message: t(
														"registerPartnerModal.branchInput.lengthRequired"
													)
												},
												maxLength: {
													value: 2,
													message: t(
														"registerPartnerModal.branchInput.lengthRequired"
													)
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="name">
											{t("registerPartnerModal.nameInput.label")}
										</label>
										<Input
											variant="light"
											errors={errors.name}
											placeholder={t(
												"registerPartnerModal.nameInput.placeholder"
											)}
											{...register("name", {
												required: {
													value: true,
													message: t(
														"registerPartnerModal.nameInput.requiredAlert"
													)
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="table">
											{t("registerPartnerModal.tableInput.label")}
										</label>
										<Select
											id="table"
											onSelectOptions={(option) => setValue("table", option[0])}
											options={tables}
											defaultValue={tables[0].value}
											selectedOption={[tables[0].value]}
											errors={errors.table}
											placeholder={t(
												"registerPartnerModal.tableInput.placeholder"
											)}
											{...register("table", {
												required: {
													value: true,
													message: t(
														"registerPartnerModal.tableInput.requiredAlert"
													)
												},
												minLength: {
													value: 3,
													message: t(
														"registerPartnerModal.tableInput.lengthRequired"
													)
												},
												maxLength: {
													value: 3,
													message: t(
														"registerPartnerModal.tableInput.lengthRequired"
													)
												}
											})}
										/>
									</div>
								</div>
								<Divider title={t("registerPartnerModal.divider.role")} />
								<div className="roles">
									{roles.slice(0, 2).map((role, index) => {
										return (
											<div className="checkbox" key={index}>
												<CheckBox
													format="square"
													variant="standard"
													id={role.label}
													type="checkbox"
													checked={getValues("roles")?.includes(role.value)}
													onChange={(e) =>
														getValues("roles")[0] === role.value
															? setValue("roles", [])
															: setValue("roles", [role.value])
													}
												/>
												<label htmlFor={role.label}>{role.label}</label>
											</div>
										);
									})}
									{(errors.roles || errors.roles) && (
										<p>{t("registerPartnerModal.roleInput.checkedAlert")}</p>
									)}
								</div>
							</div>
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="register-partner-modal-actions">
							<Button
								title={t("registerPartnerModal.submitAction")}
								size="lg"
								variant="confirmation-solid"
								onClick={handleSubmit((data: NewPartnerModel) =>
									handleNewPartner(data, onClose)
								)}
							/>
							<Button
								size="lg"
								title={t("registerPartnerModal.cancelAction")}
								variant="cancellation"
								onClick={onClose}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{showModal && (
				<PartnerSearch
					onChangePartner={(e) => {
						setValues(e);
						setShowModal(false);
					}}
					onClose={() => setShowModal(false)}
				/>
			)}
			{isLoading && <LoadingProgress />}
		</>
	);
};
