import {
    Button,
    GenericTable,
    LoadingProgress,
    Paginator
} from "@praticabr/ppsa";
import { t } from "i18next";
import { Printer } from "lucide-react";
import React, { useContext, useState } from "react";
import { Margin, Resolution, usePDF } from "react-to-pdf";
import { toast } from "react-toastify";
import { HeaderSection } from "../../components";
import { PraticaContext } from "../../context/PraticaContext";
import { dateProtheusToDate } from "../../utils/protheus-functions";
import { HangTag } from "./HangTag";
import "./styles.scss";

export function PraticaPartsToReturn() {
	const [selectedParts, setSelectedParts] = useState<any[]>([] as any[]);
	const [loading, setLoading] = useState<boolean>(false);

	const { partsToReturn, isLoading, onChangeParams, total, offset } =
		useContext(PraticaContext);

	const onSearch = (term: string) => {
		onChangeParams({ term, offset: 1 });
	};

	const { targetRef, toPDF } = usePDF({
		filename: "etiquetas.pdf",
		resolution: Resolution.NORMAL,
		method: "open",
		page: {
			orientation: "landscape",
			margin: Margin.SMALL
		}
	});

	const handleSelectItems = (item: any) => {
		delete item.action;
		const currentIndex = selectedParts.findIndex(
			(equipment) => equipment.id === item?.id && equipment?.code === item?.code
		);
		const newChecked = [...selectedParts];
		if (currentIndex === -1) {
			newChecked.push(item);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		if (newChecked.length <= 12) {
			setSelectedParts(newChecked);
		} else {
			toast.error(
				"Para prosseguir, selecione um máximo de 12 peças de uma vez."
			);
		}
	};

	const handlePrint = async () => {
		if (selectedParts.length <= 0) {
			toast.error("Nenhuma peça selecionada");
		} else if (selectedParts.length > 12) {
			toast.error(
				"Para prosseguir, selecione um máximo de 12 peças de uma vez."
			);
		} else {
			setLoading(true);
			try {
				await toPDF();
			} catch (error) {
				console.error("Erro ao gerar PDF:", error);
				toast.error("Erro ao gerar PDF");
			} finally {
				setLoading(false);
			}
		}
	};

	const MOBILE = window.innerWidth <= 480;

	return (
		<>
			<article className="protheus-parts-to-return">
				<HeaderSection.root>
					<div className="protheus-parts-to-return-search">
						<HeaderSection.search
							onChangeValues={onSearch}
							text={t("ProtheusPartsToReturnListPage.searchHeader")}
						/>

						<Button
							variant="confirmation-solid"
							size={!MOBILE ? "lg" : "md"}
							title={!MOBILE ? "Imprimir etiquetas" : ""}
							onClick={() => handlePrint()}
							icon={<Printer />}
							disabled={partsToReturn.length <= 0}
						/>
					</div>
				</HeaderSection.root>
				<div className="protheus-parts-to-return-table">
					<GenericTable
						data={partsToReturn?.map((part) => ({
							...part,
							id: part.rECNO,
							description: `${part.zegProdut} - ${part.zegDescri}`,
							invoice: `${part.zegNf} - ${part.zegSerie}`,
							amount: part.zegQuant,
							value: Number(part.zegValor || 0).toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL"
							}),
							dueDate: dateProtheusToDate(part.zegDtlimi)?.toLocaleDateString(
								"pt-BR"
							),
							protocol: part.zegNumos
						}))}
						columns={[
							{
								width: "40%",
								checkbox: true,
								selected: selectedParts,
								key: "description",
								header: t(
									"ProtheusPartsToReturnListPage.tableColumns.description"
								)
							},
							{
								width: "15%",
								key: "protocol",
								header: t("ProtheusPartsToReturnListPage.tableColumns.protocol")
							},
							{
								width: "15%",
								key: "invoice",
								header: t("ProtheusPartsToReturnListPage.tableColumns.invoice")
							},
							{
								width: "10%",
								key: "amount",
								header: t("ProtheusPartsToReturnListPage.tableColumns.amount")
							},
							{
								width: "10%",
								key: "value",
								header: t("ProtheusPartsToReturnListPage.tableColumns.value")
							},
							{
								width: "10%",
								key: "dueDate",
								header: t("ProtheusPartsToReturnListPage.tableColumns.dueDate")
							}
						]}
						onRowClicked={handleSelectItems}
					/>
				</div>
				<HangTag targetRef={targetRef} data={selectedParts} />
				<Paginator
					currentPage={offset}
					totalPages={total || 1}
					onPageChange={(value) => onChangeParams({ offset: value })}
				/>
			</article>
			{(isLoading || loading) && <LoadingProgress />}
		</>
	);
}
