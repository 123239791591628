import { Button, LoadingProgress } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Authenticate } from "../../components";
import { AuthContext } from "../../context";
import {
	forgotPasswordRequest,
	recoveryPasswordRequest
} from "../../models/usecases/users";
import { history } from "../../routes/history";
import { store } from "../../store";

export type loginRequestForm = {
	email: string;
	password: string;
	confirmPassword: string;
};

export const AuthenticatePage = () => {
	const [view, setView] = useState<
		"login" | "recoverPassword" | "forgotPassword"
	>("login");
	const {
		isLoading,
		isCompleted,
		onLogin,
		handleRecoveryPassword,
		onForgotPassword
	} = useContext(AuthContext);

	const { i18n } = useTranslation();

	const language = i18n.language || store.getState().auth.language;

	const pathname = location.pathname.split("/");

	useEffect(() => {
		if (pathname.includes("recover-password")) {
			setView("recoverPassword");
		}
	}, [pathname]);

	const loginForm = useForm<loginRequestForm>();
	const recoverPasswordForm = useForm<recoveryPasswordRequest>();
	const forgotPasswordForm = useForm<forgotPasswordRequest>();

	const handlerLogin = async (data: loginRequestForm) => onLogin(data);

	const handlerRecoverPassword = (data: recoveryPasswordRequest) => {
		handleRecoveryPassword(
			data,
			() => (window.location.href = `/${language}/requests`)
		);
	};

	const handleForgotPassword = (data: forgotPasswordRequest) => {
		onForgotPassword(data);
	};

	const loginFormRender = () => (
		<Authenticate.loginForm
			form={loginForm}
			view={setView}
			submit={handlerLogin}
		>
			<Button
				title={t("loginPage.signInAction")}
				variant="standard"
				size="lg"
				onClick={loginForm.handleSubmit(handlerLogin)}
			/>
		</Authenticate.loginForm>
	);

	const recoverPasswordFormRender = () => (
		<Authenticate.newPasswordForm form={recoverPasswordForm}>
			<Button
				title={t("loginPage.saveAction")}
				variant="standard"
				size="lg"
				onClick={recoverPasswordForm.handleSubmit(handlerRecoverPassword)}
			/>
			<Button
				title={t("loginPage.cancelAction")}
				variant="cancellation-light"
				size="lg"
				onClick={onCancelClick}
			/>
		</Authenticate.newPasswordForm>
	);

	const forgotPasswordFormRender = () =>
		!isCompleted ? (
			<Authenticate.recoverPasswordForm form={forgotPasswordForm}>
				<Button
					title={t("loginPage.sendAction")}
					variant="standard"
					size="lg"
					onClick={forgotPasswordForm.handleSubmit(handleForgotPassword)}
				/>
				<Button
					title={t("loginPage.cancelAction")}
					variant="cancellation-light"
					size="lg"
					onClick={onCancelClick}
				/>
			</Authenticate.recoverPasswordForm>
		) : (
			<Authenticate.resetPasswordSuccessContain
				sendAgain={forgotPasswordForm.handleSubmit(handleForgotPassword)}
			/>
		);

	const onCancelClick = () => {
		setView("login");
		loginForm.reset();
		recoverPasswordForm.reset();
		forgotPasswordForm.reset();
		history.replace(`/${language}/login`);
		recoverPasswordForm.reset();
	};

	return (
		<Authenticate.root>
			{view === "login" && loginFormRender()}
			{view === "recoverPassword" && recoverPasswordFormRender()}
			{view === "forgotPassword" && forgotPasswordFormRender()}
			{isLoading && <LoadingProgress />}
		</Authenticate.root>
	);
};
