import { Button, Input, LoadingProgress, Modal } from "@praticabr/ppsa";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Sb1010sContext } from "../../context/Sb1010sContext";
import "./style.scss";

type ManageTagModalProps = {
	onClose: () => void;
	type: "TAG" | "SUBTAG";
};

export const RegisterTagModal = ({ onClose, type }: ManageTagModalProps) => {
	const {
		isLoading,
		onCreateTag,
		onCreateSubTag,
		onGetSubTagsList,
		onGetTagsList
	} = useContext(Sb1010sContext);
	const {
		handleSubmit,
		register,
		formState: { errors },
		setValue
	} = useForm();

	const isTag = type === "TAG";

	const onSubmit = (data: any) => {
		if (isTag) {
			onCreateTag(data, handleClose);
		} else {
			onCreateSubTag(data, handleClose);
		}
	};

	const handleClose = () => {
		onClose();
		if (isTag) {
			onGetTagsList();
		} else {
			onGetSubTagsList();
		}
	};

	return (
		<Modal.root>
			<Modal.header onClose={onClose} title={`INCLUIR ${type}`} />
			<Modal.body>
				<Modal.body.main>
					<div className="register-tag-modal">
						<div className="register-tag-modal-form">
							<div className="register-tag-modal-form-item">
								<label>Nome</label>
								<Input
									variant="light"
									{...register("label", {
										required: {
											value: true,
											message: "Campo nome é obrigatório"
										}
									})}
									onChange={(e) => setValue("label", e.target.value)}
								/>
							</div>
							<div className="register-tag-modal-form-item">
								<label>Cor</label>
								<Input
									variant="light"
									type="color"
									{...register("color", {
										required: {
											value: true,
											message: "Campo cor é obrigatório"
										}
									})}
									onChange={(e) => setValue("color", e.target.value)}
								/>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="register-tag-modal-action">
						<Button
							size="lg"
							title="Salvar"
							variant="confirmation-solid"
							onClick={() => handleSubmit(onSubmit)()}
						/>
						<Button
							size="lg"
							title="Cancelar"
							variant="cancellation"
							onClick={onClose}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
			{isLoading && <LoadingProgress />}
		</Modal.root>
	);
};
