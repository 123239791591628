import React, {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState
} from "react";

// IMPORTANTE!! NÃO PASSAR COMO DEPENDÊNCIA PARA CONSULTAS APENAS PARA OBTER AS DIMENSÕES DA TELA.
// IMPORTANT!! DO NOT PASS AS A DEPENDENCY FOR QUERIES ONLY TO GET SCREEN DIMENSIONS.

type ViewContextType = {
	screenSize: { width: number; hight: number };
	mobileSize: boolean;
	orientation: string;
};

const ViewContext = createContext<ViewContextType | null>(null);

export const ViewProvider = ({ children }: { children: ReactNode }) => {
	const [screenSize, setScreenSize] = useState<{
		width: number;
		hight: number;
	}>({
		width: window.innerWidth,
		hight: window.innerHeight
	});
	const [orientation, setOrientation] = useState<string>("");

	const mobileSize = screenSize.width < 481;

	useEffect(() => {
		const handleResize = () => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				setScreenSize({
					width: window.innerWidth,
					hight: window.innerHeight
				});
				setOrientation(
					window.innerWidth > window.innerHeight ? "landscape" : "portrait"
				);
			}, debounceDelay);
		};

		let resizeTimer: any;
		const debounceDelay = 100; // Ajuste o atraso conforme necessário

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<ViewContext.Provider value={{ screenSize, mobileSize, orientation }}>
			{children}
		</ViewContext.Provider>
	);
};

export const useViewVariable = () => {
	const context = useContext(ViewContext);
	if (!context) {
		throw new Error("useGlobalVariable must be used within a GlobalProvider");
	}
	return context;
};
