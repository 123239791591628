import { Divider, GenericTable } from "@praticabr/ppsa";
import React, { useContext } from "react";
import { PraticaContext } from "../../../context/PraticaContext";
import { dateProtheusToDate } from "../../../utils/protheus-functions";
import "./styles.scss";

export function PraticaOrderItems() {
	const { sc5010 } = useContext(PraticaContext);

	return (
		<>
			{!!sc5010?.sc6010?.filter((sc6) => sc6?.c6Nota === "")?.length && (
				<div className="order-list-items">
					<Divider title="Produtos pendentes" />
					<GenericTable
						data={[
							...(sc5010?.sc6010
								?.filter((sc6) => sc6?.c6Nota === "")
								?.map((sc6) => ({
									...sc6,
									id: sc6?.rECNO,
									item: sc6?.c6Item,
									deliveryDate: dateProtheusToDate(
										sc6?.c6Entreg
									)?.toLocaleDateString("pt-BR"),
									description: `${sc6?.c6Produto} - ${sc6?.sb1010?.b1Desc}`,
									ipi: sc6?.sb1010?.b1Ipi,
									priceTable: sc6?.c6Prunit?.toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL"
									}),
									total: sc6?.c6Valor?.toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL"
									}),
									value: sc6?.c6Prcven?.toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL"
									}),
									invoice: `${sc6?.c6Nota} - ${sc6?.c6Serie}`,
									amount: sc6?.c6Qtdven
								})) || [])
						]}
						columns={[
							{
								key: "item",
								width: "5%",
								header: "Item"
							},
							{
								width: "10%",
								key: "deliveryDate",
								header: "Dt. Prevista"
							},
							{
								width: "28%",
								key: "description",
								header: "Produto"
							},
							{
								width: "7%",
								key: "amount",
								header: "Qtd"
							},
							{
								width: "8%",
								key: "ipi",
								header: "IPI (%)"
							},
							{
								width: "14%",
								key: "priceTable",
								header: "Valor tabela (R$)"
							},
							{
								width: "14%",
								key: "value",
								header: "Valor unitário (R$)"
							},
							{
								width: "14%",
								key: "total",
								header: "Total s/impostos (R$)"
							}
						]}
					/>
				</div>
			)}
			{!!sc5010?.sc6010?.filter((sc6) => sc6?.c6Nota !== "").length && (
				<div className="order-list-items">
					<Divider title="Produtos Faturados" />
					<GenericTable
						data={[
							...(sc5010?.sc6010
								?.filter((sc6) => sc6?.c6Nota !== "")
								?.map((sc6) => ({
									...sc6,
									id: sc6?.rECNO,
									item: sc6?.c6Item,
									invoicedDate: dateProtheusToDate(
										sc6?.c6Datfat
									)?.toLocaleDateString("pt-BR"),
									description: `${sc6?.c6Produto} - ${sc6?.sb1010?.b1Desc}`,
									ipi: sc6?.sb1010?.b1Ipi,
									priceTable: sc6?.c6Prunit?.toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL"
									}),
									total: sc6?.c6Valor?.toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL"
									}),
									value: sc6?.c6Prcven?.toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL"
									}),
									invoice: `${sc6?.c6Nota} - ${sc6?.c6Serie}`,
									amount: sc6?.c6Qtdven
								})) || [])
						]}
						columns={[
							{
								key: "item",
								width: "5%",
								header: "Item"
							},
							{
								width: "30%",
								key: "description",
								header: "Produto"
							},
							{
								width: "7%",
								key: "amount",
								header: "Quantidade"
							},
							{
								width: "8%",
								key: "ipi",
								header: "IPI"
							},
							{
								width: "10%",
								key: "priceTable",
								header: "Valor tabela (R$)"
							},
							{
								width: "10%",
								key: "value",
								header: "Valor unitário (R$)"
							},
							{
								width: "10%",
								key: "total",
								header: "Total s/impostos (R$)"
							},
							{
								width: "9%",
								key: "invoicedDate",
								header: "Dt. Faturamento"
							},
							{
								width: "11%",
								key: "invoice",
								header: "Nota Fiscal"
							}
						]}
					/>
				</div>
			)}
		</>
	);
}
