import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { NewPartnerModel } from "../models";
import { updatePartnerRequest } from "../models/usecases";
import { PartnerTypes } from "../models/usecases/get-partners";
import {
	createNewPartner,
	getPartnerList,
	handleUpdatePartner
} from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const PartnerContext = createContext({
	list: [] as PartnerTypes[],
	isLoading: false,
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	onPageChange: (page: number) => {},
	onSearchParams: (searchTerm: string) => {},
	onGetPartnerLists: (partnersCode?: number[]) => {},
	handleNewPartner: (data: NewPartnerModel, callback?: () => void) => {},
	onUpdatePartner: (data: updatePartnerRequest, callback?: () => void) => {}
});

const defaultParams = defaultListParams();

export const PartnerProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [partnerLists, setPartnerLists] = useState<PartnerTypes[]>(
		[] as PartnerTypes[]
	);
	const listParams = useRef<ListParams>(defaultParams);

	const { t } = useTranslation();

	const onGetPartnerLists = async (partnersCode?: number[]) => {
		const { limit, offset, term } = { ...listParams.current };
		const currentPage = offset - 1;
		setIsLoading(true);
		getPartnerList({
			limit,
			term: term || "",
			offset: currentPage,
			arrayPartners: partnersCode
		})
			.then((response) => {
				setPartnerLists(response.data.partnerList);
				updateParams(Math.ceil(response.data.total / limit), "total");
			})
			.catch(() => toast.error(t("partnerContext.getPartnersListsError")))
			.finally(() => setIsLoading(false));
	};

	const handleNewPartner = async (
		data: NewPartnerModel,
		callback?: () => void
	) => {
		const { branch, ...rest } = data;
		const dataUser = {
			...rest
		};
		dataUser.code = `${dataUser.code}${branch}`;
		setIsLoading(true);
		createNewPartner(dataUser)
			.then((response) => {
				if (response.data.statusCode === 409) {
					toast.error(t("authContext.userAlreadyExists"));
				} else {
					toast.success(t("authContext.registerUser.success"));
					onGetPartnerLists();
					callback && callback();
				}
			})
			.catch(() => toast.error(t("authContext.registerUser.error")))
			.finally(() => setIsLoading(false));
	};

	const onUpdatePartner = (
		data: updatePartnerRequest,
		callback?: () => void
	) => {
		setIsLoading(true);
		handleUpdatePartner(data)
			.then(() => {
				toast.success(t("authContext.updateUserSuccess"));
				onGetPartnerLists();
				callback && callback();
			})
			.catch(() => toast.error(t("authContext.updateUserError")))
			.finally(() => setIsLoading(false));
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		onGetPartnerLists();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		onGetPartnerLists();
	};

	const value = {
		list: partnerLists,
		...listParams.current,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetPartnerLists,
		onUpdatePartner,
		handleNewPartner
	};

	return (
		<PartnerContext.Provider value={value}>{children}</PartnerContext.Provider>
	);
};
