import React from "react";
import "./styles.scss";

interface SvgProps extends React.SVGProps<SVGSVGElement> {
	width?: string;
	height?: string;
	fill?: string;
	id?: string;
}

const PIcon: React.FC<SvgProps> = ({
	fill = "#FFFFFF",
	width = "25",
	height = "28",
	id
}) => {
	return (
		<svg
			id={id}
			width={width}
			height={height}
			viewBox="0 0 25 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23.8098 10.2336L24.7596 6.73519L24.8387 6.12573V5.51629L24.8071 4.98599L24.7279 4.45569L24.5696 3.95704L24.3876 3.45841L24.1185 2.99934L23.8494 2.57985L23.4774 2.20785L23.0895 1.85959L22.6225 1.51924L22.0922 1.25014L21.5224 0.988946L20.9129 0.759412L20.2243 0.521968L19.4962 0.371582H8.09865L0.824829 27.6069H6.14366L9.01678 17.0879H13.5679L14.5335 16.9771L15.4041 16.8188L16.2906 16.6368L17.0979 16.3677L17.8578 16.0986L18.578 15.7582L19.2745 15.3704L19.9236 14.9509L20.533 14.4997L21.1108 14.0011L21.6411 13.4708L22.1397 12.893L22.6305 12.2836L23.0658 11.6424L23.4378 10.9538L23.8256 10.2336H23.8098ZM19.7969 5.01765L19.7178 5.59544L18.491 10.2415L18.119 11.3179L17.652 12.1886L17.1533 12.8772L16.5835 13.3758L15.974 13.7162L15.3171 13.9457L14.5889 14.0565L13.8607 14.0248H9.80827L12.7922 3.01517H18.2535L18.673 3.09432L19.0608 3.25262L19.322 3.48215L19.5595 3.75126L19.7099 4.12326L19.789 4.54275V5.04139L19.7969 5.01765Z"
				fill={fill}
			/>
		</svg>
	);
};

export default PIcon;
