import { Menu, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MenuMobile } from "../MenuMobile";
import "./styles.scss";

const HeaderMenuTabsMobile = () => {
	const { pathname } = useLocation();
	const [selectedTab, setSelectedTab] = useState<string>("");
	const [showOptions, setShowOptions] = useState<boolean>(false);

	useEffect(() => {
		if (pathname.split("/").includes("order-items")) {
			return setSelectedTab("order-items");
		}

		if (pathname.split("/").includes("billets")) {
			return setSelectedTab("billets");
		}

		if (pathname.split("/").includes("partsToReturn")) {
			return setSelectedTab("partsToReturn");
		}

		if (pathname.split("/").includes("catalog")) {
			return setSelectedTab("catalog");
		}

		if (pathname.split("/").includes("tags")) {
			return setSelectedTab("tags");
		}

		if (pathname.split("/").includes("subTags")) {
			return setSelectedTab("subTags");
		}
		if (pathname.split("/").includes("users")) {
			return setSelectedTab("users");
		}
		if (pathname.split("/").includes("partners")) {
			return setSelectedTab("partners");
		}
	}, [pathname]);

	return (
		<div className="header-menu-tabs-mobile-switch">
			<div className="header-menu-tabs-mobile">
				<div
					className="header-menu-icon"
					onClick={() => setShowOptions(!showOptions)}
				>
					{showOptions ? (
						<X onClick={() => setShowOptions(false)} />
					) : (
						<Menu onClick={() => setShowOptions(true)} />
					)}
				</div>
			</div>
			<MenuMobile show={showOptions} onClose={() => setShowOptions(false)} />
		</div>
	);
};
export default HeaderMenuTabsMobile;
