import { GenericTable, Modal } from "@praticabr/ppsa";
import React, { useEffect, useState } from "react";
import "./styles.scss";

type Props = {
	item: any;
	onClose: () => void;
};

export const ModalAlternativeProduct = ({ item, onClose }: Props) => {
	const [alternativeList, setAlternativeList] = useState<any>([]);

	useEffect(() => {
		if (item?.product?.sgi010) {
			setAlternativeList(item?.product?.sgi010);
		} else if (item?.sgi010) {
			setAlternativeList(item?.sgi010);
		}
	}, [item]);

	return (
		<Modal.root>
			<Modal.header {...{ onClose }} title={"Produtos Alternativo"} />
			<Modal.body>
				<Modal.body.main>
					<div className="modal-alternative-product">
						<header>
							<span>Produto:</span>
							{`${item?.code || item?.b1Cod} - ${item?.description || item?.product?.b1Desc || item?.b1Desc}`}
						</header>
						<div className="list">
							<GenericTable
								data={[
									...alternativeList?.map((item: any) => ({
										code: item?.giProdalt,
										description: item?.details?.b1Desc
									}))
								]}
								columns={[
									{
										key: "code",
										header: "Código",
										width: "15%"
									},
									{
										key: "description",
										header: "Descrição",
										width: "85%"
									}
								]}
							/>
						</div>
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	);
};
