export const dateProtheusToDate = (date?: string) => {
	if (date) {
		const year = date.substring(0, 4);
		const month = date.substring(4, 6);
		const day = date.substring(6, 8);
		const newDate = new Date(`${year}-${month}-${day}T00:00:00-03:00`);
		return newDate;
	}
	return null;
};

type HandleTypeProps = {
	value?: any;
	types: { value: string; label: string }[];
};
export function handleType({ value, types }: HandleTypeProps) {
	if (!value) return "-";
	const filteredType = types.filter((type) => type.value === value);
	return filteredType[0]?.label || "-";
}

export const TABLE_TYPES = [
	{ value: "C20", label: "C20 - Desconto 0%" },
	{ value: "C21", label: "C21 - Desconto 5%" },
	{ value: "C22", label: "C22 - Desconto 10%" },
	{ value: "C23", label: "C23 - Desconto 15%" },
	{ value: "C24", label: "C24 - Desconto 30%" },
	{ value: "C25", label: "C25 - Desconto 50%" },
	{ value: "C26", label: "C26 - Desconto 55%" },
	{ value: "C27", label: "C27 - Desconto 20%" },
	{ value: "C28", label: "C28 - Desconto 35%" },
	{ value: "C29", label: "C29 - Desconto 40%" }
];

export const CATEGORY_TYPES = [
	{ value: "1", label: "1 - Assistência vendas" },
	{ value: "2", label: "2 - Acessórios" },
	{ value: "3", label: "3 - Fornos" },
	{ value: "4", label: "4 - Outros" },
	{ value: "5", label: "5 - Assistência garantia" },
	{ value: "6", label: "6 - Klimaquip" },
	{ value: "7", label: "7 - Technipan" },
	{ value: "8", label: "8 - Consignado" },
	{ value: "9", label: "9 - Locação" },
	{ value: "A", label: "A - Prestação de serviço - Preventiva" },
	{ value: "B", label: "B - Prestação de serviço - Fora de garantia" },
	{ value: "C", label: "C - Prestação de serviço - Assistência Técnica" },
	{ value: "D", label: "D - Vendas de peças - Preventiva" },
	{ value: "E", label: "E - Vendas de peças - Fora de garantia" }
];

export const FREIGHT_TYPES = [
	{ value: "C", label: "CIF" },
	{ value: "F", label: "FOB" }
];

export const BRANCH_TYPES = [
	{ value: "01", label: "Prática MG" },
	{ value: "02", label: "Prática SP" },
	{ value: "06", label: "Prática PE" }
];

export const BRANCH_ACRONYM_TYPES = [
	{ value: "01", label: "MG" },
	{ value: "02", label: "SP" },
	{ value: "06", label: "PE" }
];
