import { z } from "zod";

const itemSchema = z.object({
	code: z.string().min(1),
	description: z.string().min(1),
	amount: z
		.number({ required_error: "", invalid_type_error: "" })
		.positive({ message: "O valor deve ser positivo!" }),
	price: z.number().nonnegative().default(0),
	totalPrice: z.number().nonnegative().default(0),
	itemId: z.number().optional()
});

export const orderCreateSchema = z.object({
	id: z.number().optional(),
	userId: z.number(),
	partnerId: z.number(),
	dealerId: z
		.number({ required_error: "O concessionário é obrigatório!" })
		.min(1),
	observation: z.string(),
	freightType: z
		.string({ required_error: "O campo tipo de frete é obrigatório!" })
		.min(1),
	items: z.array(itemSchema).min(1, { message: "Escolha pelo menos 1 item!" }),
	carrierName: z.string().optional(),
	carrierContact: z.string().optional()
});

export type orderCreateSchema = z.infer<typeof orderCreateSchema>;
