export const roles = [
	{ value: 1, label: "Técnico de Campo", name: "technician" },
	{ value: 2, label: "Concessionário", name: "dealer" },
	{
		value: 3,
		label: "Administrador de Serviços Técnicos",
		name: "technicalServicesAdministrator"
	},
	{
		value: 4,
		label: "Administrador Customer Success",
		name: "customerSuccessAdministrator"
	}
];

export const tables = [
	{ value: "C20", label: "C20 - Desconto 0%" },
	{ value: "C21", label: "C21 - Desconto 5%" },
	{ value: "C22", label: "C22 - Desconto 10%" },
	{ value: "C23", label: "C23 - Desconto 15%" },
	{ value: "C24", label: "C24 - Desconto 30%" },
	{ value: "C25", label: "C25 - Desconto 50%" },
	{ value: "C26", label: "C26 - Desconto 55%" },
	{ value: "C27", label: "C27 - Desconto 20%" },
	{ value: "C28", label: "C28 - Desconto 35%" },
	{ value: "C29", label: "C29 - Desconto 40%" }
];
