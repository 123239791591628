import { ProgressBarCircular } from "@praticabr/ppsa";
import { Trash2 } from "lucide-react";
import React from "react";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import movIcon from "../../assets/images/MOV.svg";
import mp4Icon from "../../assets/images/MP4.svg";
import pdfIcon from "../../assets/images/PDF.svg";
import { UploadFile } from "../../models";
import { upload } from "../../services";

type Props = {
	onFileDeleted: (file: any) => void;
	filesForm: UseFormReturn<UploadFile, "files", undefined>;
	fieldFiles: UseFieldArrayReturn<UploadFile, "files", "id">;
};

const acceptedFileTypes = [
	"image/png",
	"image/jpeg",
	"image/jpg",
	"application/pdf",
	"video/mp4",
	"video/mov",
	"video/quicktime"
];

const MAX_SIZE = 30 * 1024 * 1024;

export const UploadFiles = ({
	filesForm,
	fieldFiles,
	onFileDeleted
}: Props) => {
	const { t } = useTranslation();

	const [uploading, setUploading] = React.useState(false);
	const [sumProgress, setSumProgress] = React.useState(
		fieldFiles.fields.filter((field) => field.progress === 100).length * 100
	);

	const addItem = () => {
		const element = document.getElementById("file-upload");
		element?.click();
	};

	const { fields, replace, update } = fieldFiles;

	const filePreview = (file: any) => {
		switch (file.type) {
			case "video/mp4":
				return mp4Icon;
			case "video/mov":
				return movIcon;
			case "video/quicktime":
				return movIcon;
			case "application/pdf":
				return pdfIcon;
			default:
				return URL.createObjectURL(file);
		}
	};

	const onUploadFile = (files: File[]) => {
		const newFormattedFile = Array.from(files)
			.map((file) => {
				let newDefaultFile;
				if (acceptedFileTypes.includes(file.type) && file.size < MAX_SIZE) {
					newDefaultFile = {
						name: file.name,
						preview: filePreview(file),
						url: "",
						type: file.type,
						file
					};
				} else {
					file.size < MAX_SIZE && toast.error(t("uploadFiles.toast.typeError"));
					file.size > MAX_SIZE && toast.error(t("uploadFiles.toast.sizeError"));
				}
				return newDefaultFile;
			})
			.filter((file) => file !== undefined);

		const newUploadedFiles = [...fields, ...newFormattedFile];

		replace(
			newUploadedFiles.map((field, index) => {
				return {
					...field,
					description: field?.name?.split(".")[0]
				};
			}) as any
		);

		filesForm.handleSubmit(onUploadClick)();
	};

	const onUploadClick = (data: UploadFile) => {
		let sum = sumProgress;
		Array.from(data.files).forEach((file, index) => {
			if (file.url === "") {
				upload(file, (e) => {
					setUploading(true);
					file.progress = e;
					update(index, file);
				}).then((response) => {
					const { key, url } = response.data;
					if (response.data) {
						file.url = url;
						file.name = key;
						update(index, file);
						sum += file.progress;
						if (sum === fields.length * 100) {
							setUploading(false);
						}
					}
				});
			}
		});
	};

	const onDeleteFile = (file: any, index: number) => {
		onFileDeleted(file);
		fields.splice(index, 1);
		replace(fields);
	};

	return (
		<div className="new-modal-upload-files">
			<div id="upload-file-container" className="upload-file-container">
				{fields.map((file, index) => (
					<div key={file.id} className="preview-file-container">
						<div className="preview-files">
							{file.progress < 100 && (
								<ProgressBarCircular progress={file.progress} />
							)}
							<img src={file.preview || file.url} alt="" />
							<div
								className="upload-file-list-item-remove"
								onClick={() => onDeleteFile(file, index)}
							>
								<Trash2 size={16} color="red" />
							</div>
						</div>
					</div>
				))}
				<div className="preview-file-container">
					<div className="preview-files-empty" onClick={addItem}>
						<div>+</div>
						<input
							multiple
							type="file"
							id="file-upload"
							style={{ display: "none" }}
							accept=".jpg, .png, .jpeg, .mp4, .mov, .pdf"
							onChange={(e) =>
								onUploadFile(e.target.files as unknown as File[])
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
