import React from "react";
import "./styles.scss";

interface Params {
	show: boolean;
	text?: string;
}

const LoadingList: React.FC<Params> = ({ show, text }) => {
	const mainContent = () => {
		return (
			<div className="loading-main-list-content">
				<div className="loading-main">
					<div className="loading-spinner">
						<div className="spinners">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
					<span>{text || "Carregando informações..."}</span>
				</div>
			</div>
		);
	};

	return show ? mainContent() : <></>;
};

export default LoadingList;
