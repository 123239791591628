import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ArrowBack from "../../assets/SvgIcons/ArrowBack";
import { useViewVariable } from "../../context/ViewContext";
import { history } from "../../routes/history";
import HeaderMenuTabs from "../HeaderMenuTabs";
import "./styles.scss";

type TParams = {
	backButton?: boolean;
};

export const HeaderArticle = ({ backButton = false }: TParams) => {
	const { mobileSize } = useViewVariable();

	const { pathname } = useLocation();
	const { t } = useTranslation();

	const showTabs =
		pathname.split("/").includes("orders") ||
		pathname.split("/").includes("billets") ||
		pathname.split("/").includes("partsToReturn") ||
		pathname.split("/").includes("editor") ||
		pathname.split("/").includes("users") ||
		pathname.split("/").includes("partners");

	return (
		<div
			className="header-article"
			style={
				mobileSize
					? showTabs
						? { display: "flex" }
						: { display: "none" }
					: { display: "flex" }
			}
		>
			{!!backButton && (
				<div className="back-link" onClick={() => history.back()}>
					<ArrowBack width="24px" height="24px" />
					<span>{t("navbar.backLink")}</span>
				</div>
			)}
			{showTabs &&
				(backButton ? (
					<div className="add-space-back-button">
						<HeaderMenuTabs />
					</div>
				) : (
					<HeaderMenuTabs />
				))}
		</div>
	);
};
