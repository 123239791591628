import React, { ReactNode } from "react";
import PtpIconLogin from "../../assets/SvgIcons/PtpIconLogin";
import Pratica from "../../assets/images/pratica-fab.png";
import "./styles.scss";

export const AuthenticateRoot = ({ children }: { children: ReactNode }) => {
	return (
		<div className="authenticate-page">
			<div className="content">
				<div className="logo">
					<PtpIconLogin height="100%" width="100%" />
				</div>
				{children}
			</div>
			<div className="background">
				<img width={"100%"} src={Pratica} alt="background" />
			</div>
		</div>
	);
};
