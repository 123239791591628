import React from "react";

import "./styles.scss";
interface ArrowIconProps extends React.SVGProps<SVGSVGElement> {
	width?: string;
	height?: string;
	fill?: string;
}
const ArrowIcon: React.FC<ArrowIconProps> = ({ width, height, ...rest }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="7.828"
			height="15"
			viewBox="0 0 7.828 15"
			{...rest}
		>
			<path
				id="Caminho_401"
				data-name="Caminho 401"
				d="M7.5,0,15,7.828l-7.5-.76L0,7.828Z"
				transform="translate(0 15) rotate(-90)"
				fill="#f8f9fa"
			/>
		</svg>
	);
};

export default ArrowIcon;
