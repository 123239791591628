import {
	Button,
	CheckBox,
	Divider,
	Input,
	LoadingProgress,
	Modal,
	Select
} from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { AuthContext, UserContext } from "../../context";
import { TechnicalModel } from "../../models";
import { NewAccountModel } from "../../models/account";
import { PartnerSearch } from "../../pages/Users/PartnerSearch";
import { roles, tables } from "../../utils/constants";
import "./styles.scss";

type Props = {
	onClose: () => void;
};

const languageOptions = [
	{
		value: "en",
		label: t("language.english")
	},
	{
		value: "pt",
		label: t("language.portuguese")
	},
	{
		value: "es",
		label: t("language.spanish")
	}
];

export const RegisterUserModal = ({ onClose }: Props) => {
	const { handleNewUser, isLoading } = useContext(UserContext);
	const { user } = useContext(AuthContext);
	const adminCS = user.roles.includes(4);
	const [check, setCheck] = useState<number>();
	const [showModal, setShowModal] = useState<boolean>(false);

	const { t } = useTranslation();

	const {
		handleSubmit,
		register,
		setValue,
		getValues,
		formState: { errors },
		control,
		watch
	} = useForm<NewAccountModel>({
		defaultValues: {
			email: "",
			contact: "",
			partner: {},
			adminRoles: [],
			roles: []
		}
	});

	const setValues = (data: TechnicalModel) => {
		setValue("email", data.a1Email.trim());
		setValue("partner.name", data.a1Nreduz.trim());
		setValue("partner.code", data.a1Cod.trim());
		setValue("branch", data.a1Loja.trim());
		setValue("partner.table", "C20");
	};

	watch("roles");

	return (
		<>
			<Modal.root>
				<Modal.header title={t("registerUserModal.title")} onClose={onClose} />
				<Modal.body>
					<Modal.body.main>
						<div className="register-user-modal">
							<div className="content">
								<Divider title={t("registerUserModal.divider.account")} />
								<div className="form">
									<div className="form-item">
										<label htmlFor="partnerCode">
											{t("registerUserModal.partnerCodeInput.label")}
										</label>
										<div className="register-user-input-with-svg">
											<Input
												variant="light"
												disabled
												errors={errors.partner?.code}
												placeholder={t(
													"registerUserModal.partnerCodeInput.placeholder"
												)}
												{...register("partner.code", {
													required: {
														value: true,
														message: t(
															"registerUserModal.partnerCodeInput.requiredAlert"
														)
													},
													minLength: {
														value: 6,
														message: t(
															"registerUserModal.partnerCodeInput.lengthRequired"
														)
													},
													maxLength: {
														value: 6,
														message: t(
															"registerUserModal.partnerCodeInput.lengthRequired"
														)
													}
												})}
											/>
											<div
												className="search"
												onClick={() => setShowModal(true)}
											>
												<SearchSvg />
											</div>
										</div>
									</div>
									<div className="form-item">
										<label htmlFor="branch">
											{t("registerUserModal.branchInput.label")}
										</label>
										<Input
											variant="light"
											errors={errors.branch}
											disabled
											placeholder={t(
												"registerUserModal.branchInput.placeholder"
											)}
											{...register("branch", {
												required: {
													value: true,
													message: t(
														"registerUserModal.branchInput.requiredAlert"
													)
												},
												minLength: {
													value: 2,
													message: t(
														"registerUserModal.branchInput.lengthRequired"
													)
												},
												maxLength: {
													value: 2,
													message: t(
														"registerUserModal.branchInput.lengthRequired"
													)
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="contact">
											{t("registerUserModal.contactInput.label")}
										</label>
										<Input
											variant="light"
											errors={errors.contact}
											placeholder={t(
												"registerUserModal.contactInput.placeholder"
											)}
											{...register("contact", {
												required: {
													value: true,
													message: t(
														"registerUserModal.contactInput.requiredAlert"
													)
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="email">
											{t("registerUserModal.emailInput.label")}
										</label>
										<Input
											variant="light"
											placeholder={t(
												"registerUserModal.emailInput.placeholder"
											)}
											{...register("email", {
												required: {
													value: true,
													message: t(
														"registerUserModal.emailInput.requiredAlert"
													)
												},
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: t(
														"registerUserModal.emailInput.invalidAlert"
													)
												}
											})}
											errors={errors.email}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="table">
											{t("registerUserModal.tableInput.label")}
										</label>
										<Select
											id="table"
											onSelectOptions={(option) =>
												setValue("partner.table", option[0])
											}
											options={tables}
											defaultValue={tables[0].value}
											selectedOption={[tables[0].value]}
											errors={errors.partner?.table}
											placeholder={t(
												"registerUserModal.tableInput.placeholder"
											)}
											{...register("partner.table", {
												required: {
													value: true,
													message: t(
														"registerUserModal.tableInput.requiredAlert"
													)
												},
												minLength: {
													value: 3,
													message: t(
														"registerUserModal.tableInput.lengthRequired"
													)
												},
												maxLength: {
													value: 3,
													message: t(
														"registerUserModal.tableInput.lengthRequired"
													)
												}
											})}
										/>
									</div>
								</div>
								<Divider title={t("registerUserModal.divider.role")} />
								<div className="roles">
									{roles.slice(0, 2).map((role, index) => {
										return (
											<div className="checkbox" key={index}>
												<CheckBox
													format="square"
													variant="standard"
													id={role.label}
													type="checkbox"
													value={role.value}
													checked={check === role.value}
													{...register("roles", {
														required: getValues("adminRoles")?.length < 1
													})}
													onChange={(e) => {
														if (e.target.checked) {
															if (!getValues("roles").includes(role.value)) {
																setValue("roles", [role.value]);
																setCheck(role.value);
															}
														} else {
															setValue(
																"roles",
																getValues("roles").filter(
																	(val) => val !== role.value
																)
															);
															setCheck(0);
														}
													}}
												/>
												<label htmlFor={role.label}>{role.label}</label>
											</div>
										);
									})}
									{adminCS &&
										roles.slice(2, 4).map((role, index) => {
											return (
												<div className="checkbox" key={index}>
													<CheckBox
														format="square"
														variant="standard"
														id={role.label}
														type="checkbox"
														defaultChecked={getValues("adminRoles")?.includes(
															role.value
														)}
														onChange={(e) => {
															const newAdminRoles = [
																...(getValues("adminRoles") || [])
															];
															if (e.target.checked) {
																newAdminRoles.push(role.value);
																setValue("adminRoles", newAdminRoles);
															} else {
																newAdminRoles.splice(
																	newAdminRoles.indexOf(role.value),
																	1
																);
																setValue("adminRoles", newAdminRoles);
															}
														}}
													/>
													<label htmlFor={role.label}>{role.label}</label>
												</div>
											);
										})}
									{(errors.roles || errors.adminRoles) && (
										<p>{t("registerUserModal.roleInput.checkedAlert")}</p>
									)}
								</div>
							</div>
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="register-user-modal-actions">
							<Button
								title={t("registerUserModal.submitAction")}
								size="lg"
								variant="confirmation-solid"
								onClick={handleSubmit((data: NewAccountModel) =>
									handleNewUser(data, onClose)
								)}
							/>
							<Button
								size="lg"
								title={t("registerUserModal.cancelAction")}
								variant="cancellation"
								onClick={onClose}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{showModal && (
				<PartnerSearch
					onChangePartner={(e) => {
						setValues(e);
						setShowModal(false);
					}}
					onClose={() => setShowModal(false)}
				/>
			)}
			{isLoading && <LoadingProgress />}
		</>
	);
};
