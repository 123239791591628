import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { all, call, put, takeLatest } from "redux-saga/effects";
import i18n from "../../../i18n/i18n";
import {
	authenticateResponse,
	userResponse
} from "../../../models/usecases/users";
import { api, getDataUser, login } from "../../../services";
import { signInSuccess } from "./actions";

interface SignInRequestAction extends PayloadAction<any> {
	resolve: () => void;
	reject: (reason?: any) => void;
}

function setApiHeaders(accessToken: string) {
	api.defaults.headers.common.Authorization = accessToken;
}

function* signIn({
	payload,
	resolve,
	reject
}: SignInRequestAction): Generator<any, void, any> {
	try {
		const loginResponse: AxiosResponse<authenticateResponse> = yield call(
			login,
			payload
		);
		const { id, userId } = loginResponse.data;

		if (!id) {
			return;
		}

		yield call(setApiHeaders, id);

		const userResponse: AxiosResponse<userResponse> = yield call(
			getDataUser,
			id
		);

		const {
			contact,
			email,
			roles,
			partner,
			availablePartners,
			alterPass,
			allowEmailNotification,
			notificationManager
		} = userResponse.data;

		const signInSuccessPayload = {
			availablePartners,
			contact,
			token: id,
			email,
			roles,
			partner,
			userId,
			alterPass,
			allowEmailNotification,
			notificationManager
		};

		yield put(signInSuccess(signInSuccessPayload));
	} catch (error) {
		toast.error("Erro ao autenticar");
	} finally {
		window.location.replace(`/${i18n.language}/`);
		resolve();
	}
}

export function setToken(data: any) {
	if (!data?.payload?.auth?.token) return;
	const { token } = data.payload.auth;
	setApiHeaders(token);
}

export function signOut(data: any) {
	localStorage.clear();
}

export default all([
	takeLatest("persist/REHYDRATE", setToken),
	takeLatest("@auth/SIGN_IN_REQUEST", signIn),
	takeLatest("@auth/SIGN_OUT", signOut)
]);
