import React from "react";
import AddIcon from "../../../../assets/SvgIcons/AddIcon";
import "./styles.scss";

type Props = {
	showModal: (boolean: boolean) => void;
};

export const OrderItemsSectionAddItem = ({ showModal }: Props) => (
	<div className="items-information-contain-action">
		<div className="items-information-contain-information">
			<div
				className="new-order-select-item-action"
				onClick={() => showModal(true)}
			>
				<AddIcon width="35px" height="35px" fill="#004687" />
				<label htmlFor="addItemIcon">Adicionar item</label>
			</div>
		</div>
	</div>
);
