import { ConfirmModal, Input } from "@praticabr/ppsa";
import React, { useEffect, useState } from "react";
import { UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { LuAlertTriangle } from "react-icons/lu";
import TrashIcon from "../../../../assets/SvgIcons/TrashIcon";
import { ModalAlternativeProduct } from "../../../../components/ModalAlternativeProduct";
import { orderCreateSchema } from "../../../../schemas/orderCreateSchema";
import "./styles.scss";

type OrderItemsContentEditingModeProps = {
	order?: any;
	fieldArray: UseFieldArrayReturn<any, "items", "id">;
	isEditing?: boolean;
	handleDeleteItem?: (item: any) => void;
};

const resolveNanNumber = (number: any): number =>
	typeof number === "string" ? 0 : number;

export const OrderItemsContentEditingMode = ({
	order,
	fieldArray,
	handleDeleteItem,
	isEditing = false
}: OrderItemsContentEditingModeProps) => {
	const [itemDeleting, setItemDeleting] = useState<any>();
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
		useState<boolean>(false);
	const [showAlternativeProduct, setShowAlternativeProduct] = useState<any>();

	const {
		register,
		setValue,
		getValues,
		getFieldState,
		formState: { errors }
	} = useFormContext<orderCreateSchema>();

	const { fields, remove } = fieldArray;

	const handleChangesRemove = (item: any, index: number) => {
		setShowConfirmDeleteModal(true);
		setItemDeleting({ item, index });
	};

	const handleOnConfirmDelete = () => {
		if (itemDeleting) {
			setShowConfirmDeleteModal(false);
			if (handleDeleteItem) {
				handleDeleteItem(itemDeleting?.item);
			}
			onDeleteItem(itemDeleting.index);
		}
	};

	const handleOnCancelConfirmDelete = () => {
		setShowConfirmDeleteModal(false);
		setItemDeleting(undefined);
	};

	const onChangePriceTotal = (index: number, value: number, option: string) => {
		if (option === `items.${index}.amount`) {
			const price_total = value * getValues(`items.${index}.price`);
			setValue(
				`items.${index}.totalPrice`,
				Number(price_total.toFixed(2)) || 0
			);
		}
		if (option === `items.${index}.price`) {
			const price_total = value * getValues(`items.${index}.amount`);
			setValue(
				`items.${index}.totalPrice`,
				Number(price_total.toFixed(2)) || 0
			);
		}
	};

	const onDeleteItem = (index: number) => {
		remove(index);
	};

	useEffect(() => {
		const elementItem = document.querySelector(
			".items-information"
		) as HTMLElement;
		!order &&
			elementItem &&
			elementItem.scrollIntoView({ behavior: "smooth", block: "start" });
	}, []);

	return (
		<div className="items-information-contain">
			<div className="items-table">
				<div className="items-body-container">
					{fields.map((item: any, index: number) => {
						setValue(`items.${index}.price`, resolveNanNumber(item.price));
						return (
							!item?.deleted && (
								<div key={item?.id} className="container-item">
									<div
										id={`items-list-details-${index}`}
										className="items-list-details"
									>
										<ul className="items-list-order-item items-list-order-product-code">
											<span>{item?.code}</span>
										</ul>
										<ul className="items-list-order-item items-list-order-description">
											<span>{item?.description}</span>
										</ul>
										<ul className="items-list-order-item items-list-order-amount">
											<Input
												variant="light"
												type="number"
												defaultValue={item?.amount}
												{...register(`items.${index}.amount`, {
													required: true,
													min: 0.01,
													valueAsNumber: true
												})}
												onChange={(event: any) => {
													onChangePriceTotal(
														index,
														Number(event.target.value),
														`items.${index}.amount`
													);
												}}
												min={0}
												maxLength={9}
												errors={getFieldState(`items.${index}.amount`).error}
											/>
										</ul>
										<ul className="items-list-order-item items-list-order-price">
											<Input
												money={true}
												type="number"
												variant="light"
												defaultValue={getValues(`items.${index}.price`)}
												disabled={true}
												{...register(`items.${index}.price`, {
													required: true,
													valueAsNumber: true
												})}
												errors={getFieldState(`items.${index}.price`).error}
											/>
										</ul>
										<ul className="items-list-order-item items-list-order-total-price">
											<Input
												money={true}
												variant="light"
												type="number"
												disabled={true}
												defaultValue={getValues(`items.${index}.totalPrice`)}
												{...register(`items.${index}.totalPrice`)}
											/>
										</ul>
										<ul className="items-list-order-item items-list-order-ipi">
											<span>{item?.product?.b1Ipi}</span>
										</ul>
										<ul
											className="items-list-order-item items-list-order-alert"
											onClick={() =>
												item?.product?.sgi010?.length > 0 &&
												setShowAlternativeProduct(item)
											}
										>
											{item?.product?.sgi010?.length > 0 && (
												<LuAlertTriangle title="Produto Com Código Alternativo" />
											)}
										</ul>
										<ul className="items-list-order-item items-list-order-options">
											<div onClick={() => handleChangesRemove(item, index)}>
												<TrashIcon width={"auto"} fill="#5DAEFF" />
											</div>
										</ul>
									</div>
									{showConfirmDeleteModal && (
										<ConfirmModal
											title="EXCLUIR ITEM"
											confirmLabel="SIM"
											cancelLabel="NÃO"
											onCancel={handleOnCancelConfirmDelete}
											onConfirm={handleOnConfirmDelete}
											buttonWidth={"68px"}
										>
											<span>
												Deseja excluir o item {itemDeleting?.item.b1Cod}?
											</span>
										</ConfirmModal>
									)}
								</div>
							)
						);
					})}
				</div>
			</div>
			{showAlternativeProduct && (
				<ModalAlternativeProduct
					item={showAlternativeProduct}
					onClose={() => setShowAlternativeProduct(undefined)}
				/>
			)}
		</div>
	);
};
