import { LoadingProgress } from "@praticabr/ppsa";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ArrowDropIcon from "../../assets/SvgIcons/ArrowDropIcon";
import { updatePartnerId } from "../../services";
import { updatePartner } from "../../store/modules/auth/actions";
import ClickAwayListener from "../../utils/ClickAwayListener";
import { PartnerSelector } from "./PartnerSelector";
import "./styles.scss";

export const PartnerDropdown = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [showPartnerSelector, setShowPartnerSelector] =
		useState<boolean>(false);

	const dispatch = useDispatch();

	const handlerUserSelectorToggle = () => {
		setShowPartnerSelector(!showPartnerSelector);
	};

	const {
		availablePartners = [],
		partner = null,
		id = null
	} = useSelector((state: any) => state.auth);

	const handleCatchPartnerName = (): string => {
		if (!availablePartners) {
			return "";
		}

		const partnerName = availablePartners?.filter(
			(item: any) => item?.id === partner.id
		)[0].name;

		return partnerName;
	};

	const handleChangePartner = async (partnerId: number) => {
		setIsLoading(true);
		await updatePartnerId({ userId: id, partnerId })
			.then((response) => {
				dispatch(updatePartner(response?.data?.partner));
				window.location.reload();
			})
			.catch(() => {
				toast.error("Erro ao atualizar o parceiro");
				setIsLoading(false);
			});
	};

	const handleDropDownIcon = () => {
		return showPartnerSelector ? (
			<ArrowDropIcon width="10px" height="10px" fill="#025CB7" direction="up" />
		) : (
			<ArrowDropIcon
				width="10px"
				height="10px"
				fill="#025CB7"
				direction="down"
			/>
		);
	};

	const partnerSelectorContent = () => {
		return (
			<PartnerSelector
				{...{
					handleChangePartner,
					onClose: handlerUserSelectorToggle
				}}
			/>
		);
	};

	return (
		<>
			{availablePartners?.length > 1 && (
				<>
					<ClickAwayListener onClickAway={() => setShowPartnerSelector(false)}>
						<div onClick={handlerUserSelectorToggle} className="partner-area">
							<span className="partner-name">{handleCatchPartnerName()}</span>
							{handleDropDownIcon()}
							{showPartnerSelector && partnerSelectorContent()}
						</div>
					</ClickAwayListener>
					{isLoading && <LoadingProgress />}
				</>
			)}
		</>
	);
};
