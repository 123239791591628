import React, {
	ReactNode,
	createContext,
	useEffect,
	useRef,
	useState
} from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { NewAccountModel } from "../models/account";
import { UserTypes } from "../models/usecases/get-accounts";
import { updateAccountRequest } from "../models/usecases/users";
import {
	createNewUser,
	customResetPassword,
	getUserList,
	handleUpdateUser
} from "../services";
import { store } from "../store";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const UserContext = createContext({
	list: [] as UserTypes[],
	isLoading: false,
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	onPageChange: (page: number, whece?: string) => {},
	onSearchParams: (searchTerm: string, whece?: string) => {},
	onGetUserLists: () => {},
	onUpdateUser: (params: {
		data: updateAccountRequest;
		callback?: () => void;
	}) => {},
	handleNewUser: (data: NewAccountModel, callback?: () => void) => {},
	handleResetPassword: (userId: number, callback?: () => void) => {}
});

const defaultParams = defaultListParams();

export const UserProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [userLists, setUserLists] = useState<UserTypes[]>([] as UserTypes[]);
	const listParams = useRef<ListParams>(defaultParams);
	const { roles } = store.getState().auth;

	const { t } = useTranslation();

	useEffect(() => {
		if (!roles?.includes(3) && !roles?.includes(4)) {
			window.location.href = "/home";
			return;
		}

		onGetUserLists();
	}, []);

	const onUpdateUser = (params: {
		data: updateAccountRequest;
		callback?: () => void;
	}) => {
		const { data, callback } = params;
		const roles = data.roles.concat(data.adminRoles);
		const dataUser = {
			...data,
			partner: data.partner,
			roles
		};
		setIsLoading(true);
		handleUpdateUser(data.userId, dataUser)
			.then(() => {
				toast.success(t("authContext.updateUserSuccess"));
				onGetUserLists();
				callback && callback();
			})
			.catch(() => toast.error(t("authContext.updateUserError")))
			.finally(() => setIsLoading(false));
	};

	const handleNewUser = async (
		data: NewAccountModel,
		callback?: () => void
	) => {
		const { branch, adminRoles, roles, ...rest } = data;
		const rolesConcat = data.roles.concat(data.adminRoles);
		const dataUser = {
			...rest,
			roles: rolesConcat
		};
		dataUser.partner.code = `${dataUser.partner.code}${branch}`;
		setIsLoading(true);
		createNewUser(dataUser)
			.then((response) => {
				if (response.data.statusCode === 409) {
					toast.error(t("authContext.userAlreadyExists"));
				} else {
					toast.success(t("authContext.registerUser.success"));
					onGetUserLists();
					callback && callback();
				}
			})
			.catch(() => toast.error(t("authContext.registerUser.error")))
			.finally(() => setIsLoading(false));
	};

	const onGetUserLists = async () => {
		const { limit, offset, term } = { ...listParams.current };
		const currentPage = offset - 1;
		setIsLoading(true);
		getUserList({
			limit,
			term: term || "",
			offset: currentPage
		})
			.then((response) => {
				setUserLists(response.data.userList);
				updateParams(Math.ceil(response.data.total / limit), "total");
			})
			.catch(() => toast.error(t("userContext.getUserListsError")))
			.finally(() => setIsLoading(false));
	};

	const handleResetPassword = (userId: number, callback?: () => void) => {
		setIsLoading(true);
		customResetPassword(userId)
			.then(() => {
				toast.success(t("userContext.successResetPassword"));
				callback && callback();
			})
			.catch(() => toast.error(t("userContext.errorResetPassword")))
			.finally(() => setIsLoading(false));
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		onGetUserLists();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		onGetUserLists();
	};

	const value = {
		list: userLists,
		...listParams.current,
		isLoading,
		onPageChange,
		onSearchParams,
		onUpdateUser,
		onGetUserLists,
		handleNewUser,
		handleResetPassword
	};

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
