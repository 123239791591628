import { Button, Input, LoadingProgress } from "@praticabr/ppsa";
import { t } from "i18next";
import { ChevronLeft, LockIcon } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context";
import { updateAccountRequest } from "../../models/usecases/users";
import { UpdatePasswordMenu } from "../UpdatePasswordMenu";
import "./style.scss";

interface ProfileMenuProps {
	show: boolean;
	onClose: () => void;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ show, onClose }) => {
	const [showMenuPassword, setShowMenuPassword] = useState<boolean>(false);
	const { isLoading, user, onUpdateUser } = useContext(AuthContext);
	const [editOn, setEditOn] = useState<boolean>(false);

	useEffect(() => {}, [onUpdateUser]);

	const {
		handleSubmit,
		register,
		formState: { errors },
		control
	} = useForm<updateAccountRequest>({
		defaultValues: {
			partner: user.partner,
			token: user.token
		}
	});

	const onSubmit = (data: any) => {
		const { contact, email, token } = data;

		const updatedData = {
			email,
			contact,
			token
		};

		onUpdateUser(updatedData, onClose);
	};

	return (
		<div className={`profile-menu ${show ? "show" : ""}`}>
			<div className="profile-menu-item" onClick={onClose}>
				<ChevronLeft size={32} />
			</div>
			<div className="profile-menu-data">
				<div className="profile-menu-line">
					<label>{t("userConfigModal.emailInput.label")}</label>
					<Input
						variant="light"
						defaultValue={user.email}
						errors={errors.email}
						placeholder={t("userConfigModal.emailInput.placeholder")}
						disabled={!editOn}
						{...register("email", {
							required: {
								value: true,
								message: t("userConfigModal.emailInput.requiredAlert")
							},
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: t("userConfigModal.emailInput.invalidAlert")
							}
						})}
					/>
				</div>
				<div className="profile-menu-line">
					<label>{t("userConfigModal.contactInput.label")}</label>
					<Input
						variant="light"
						defaultValue={user.contact}
						errors={errors.contact}
						placeholder={t("userConfigModal.contactInput.placeholder")}
						disabled={!editOn}
						{...register("contact", {
							required: {
								value: true,
								message: t("userConfigModal.contactInput.requiredAlert")
							}
						})}
					/>
				</div>
				<div className="profile-menu-line">
					<label>{t("userConfigModal.partnerCodeInput")}</label>
					<Input
						name="partnerCode"
						variant="light"
						disabled
						value={
							user.partner.code.slice(0, 6) +
							"-" +
							user.partner.code.slice(6, 8)
						}
					/>
				</div>
				<div
					className="profile-menu-line-password"
					onClick={() => setShowMenuPassword(true)}
				>
					<LockIcon />
					<span>{t("userConfigModal.changePasswordAction")}</span>
				</div>
			</div>
			<div className="profile-menu-action">
				{!editOn ? (
					<Button
						size="lg"
						title={t("userConfigModal.editAction")}
						variant="confirmation-solid"
						onClick={() => setEditOn(true)}
					/>
				) : (
					<>
						<Button
							size="lg"
							title={t("userConfigModal.submitAction")}
							variant="confirmation-solid"
							onClick={() => handleSubmit(onSubmit)()}
						/>
						<Button
							size="lg"
							title={t("userConfigModal.cancelAction")}
							variant="cancellation"
							onClick={() => setEditOn(false)}
						/>
					</>
				)}
			</div>
			{/* <div className="profile-menu-options">
				<div className="profile-menu-item">
					<Presentation size={32} />
					<label>
						{t("navbar.userDropdown.reviewTutorials").toUpperCase()}
					</label>
				</div>
			</div> */}
			{isLoading && <LoadingProgress />}
			<UpdatePasswordMenu
				show={showMenuPassword}
				onClose={() => setShowMenuPassword(false)}
			/>
		</div>
	);
};
