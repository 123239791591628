import { Button, Input, LoadingProgress, Modal } from "@praticabr/ppsa";
import { LockIcon } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import { updateAccountRequest } from "../../models/usecases/users";
import { UserPasswordModal } from "../UserPasswordModal";
import "./styles.scss";

type Props = {
	onClose: () => void;
};

export const UserConfigModal = ({ onClose }: Props) => {
	const { isLoading, onUpdateUser, user } = useContext(AuthContext);
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {}, [onUpdateUser]);

	const {
		handleSubmit,
		register,
		formState: { errors },
		control
	} = useForm<updateAccountRequest>({
		defaultValues: {
			partner: user.partner,
			token: user.token
		}
	});

	const onSubmit = (data: any) => {
		const { contact, email, token } = data;

		const updatedData = {
			email,
			contact,
			token
		};

		onUpdateUser(updatedData, onClose);
	};

	return (
		<>
			<Modal.root>
				<Modal.header title={t("userConfigModal.title")} onClose={onClose} />
				<Modal.body>
					<form
						className="user-config-modal"
						onSubmit={() => handleSubmit(onSubmit)()}
					>
						<Modal.body.main>
							<div className="content">
								<div className="form">
									<div className="form-item">
										<label htmlFor="email">
											{t("userConfigModal.emailInput.label")}
										</label>
										<Input
											variant="light"
											defaultValue={user.email}
											errors={errors.email}
											placeholder={t("userConfigModal.emailInput.placeholder")}
											{...register("email", {
												required: {
													value: true,
													message: t("userConfigModal.emailInput.requiredAlert")
												},
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: t("userConfigModal.emailInput.invalidAlert")
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="contact">
											{t("userConfigModal.contactInput.label")}
										</label>
										<Input
											variant="light"
											defaultValue={user.contact}
											errors={errors.contact}
											placeholder={t(
												"userConfigModal.contactInput.placeholder"
											)}
											{...register("contact", {
												required: {
													value: true,
													message: t(
														"userConfigModal.contactInput.requiredAlert"
													)
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="partnerCode">
											{t("userConfigModal.partnerCodeInput")}
										</label>
										<Input
											name="partnerCode"
											variant="light"
											disabled
											value={
												user.partner.code.slice(0, 6) +
												"-" +
												user.partner.code.slice(6, 8)
											}
										/>
									</div>
								</div>
								<div className="change-password-action">
									<LockIcon width="16" height="16" />
									<span onClick={() => setShowChangePasswordModal(true)}>
										{t("userConfigModal.changePasswordAction")}
									</span>
								</div>
							</div>
						</Modal.body.main>
						<Modal.body.actions>
							<div className="user-config-modal-actions">
								<Button
									size="lg"
									type="submit"
									variant="confirmation-solid"
									title={t("userConfigModal.submitAction")}
								/>
								<Button
									size="lg"
									type="button"
									onClick={onClose}
									variant="cancellation"
									title={t("userConfigModal.cancelAction")}
								/>
							</div>
						</Modal.body.actions>
					</form>
				</Modal.body>
			</Modal.root>
			{showChangePasswordModal && (
				<UserPasswordModal onClose={() => setShowChangePasswordModal(false)} />
			)}
			{isLoading && <LoadingProgress />}
		</>
	);
};
