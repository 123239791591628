import React, { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context";
import { PraticaProvider } from "../../context/PraticaContext";
import { useViewVariable } from "../../context/ViewContext";
import { api } from "../../services";
import { store } from "../../store";
import { updatePass } from "../../store/modules/auth/actions";
import { UpdatePasswordMenu } from "../UpdatePasswordMenu";
import { UserPasswordModal } from "../UserPasswordModal";

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
	const { user, onLogout } = useContext(AuthContext);
	const { mobileSize } = useViewVariable();

	const { alterPass = false } = store.getState().auth;
	const dispatch = useDispatch();

	api.interceptors.response.use(
		(response) => response,
		async function (error) {
			if (error.response.status === 401 && user.token) {
				return onLogout();
			}
			return Promise.reject(error);
		}
	);

	const { i18n } = useTranslation();

	const language =
		i18n.language ||
		store.getState().auth.language ||
		navigator.language?.split("-")[0] ||
		"pt";

	return user.token ? (
		<PraticaProvider>
			{children}
			{alterPass &&
				(mobileSize ? (
					<UpdatePasswordMenu
						show={alterPass}
						onClose={() => dispatch(updatePass(false))}
					/>
				) : (
					<UserPasswordModal onClose={() => dispatch(updatePass(false))} />
				))}
		</PraticaProvider>
	) : (
		<Navigate to={`/${language}/login`} />
	);
};
