import { Button, Input, LoadingProgress } from "@praticabr/ppsa";
import { t } from "i18next";
import { ChevronLeft } from "lucide-react";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context";
import { newPasswordRequest } from "../../models/usecases/users";
import { store } from "../../store";
import "./style.scss";

interface UpdatePasswordMenuProps {
	show: boolean;
	onClose: () => void;
}

export const UpdatePasswordMenu: React.FC<UpdatePasswordMenuProps> = ({
	show,
	onClose
}) => {
	const { isLoading, onRecoverPassword, user } = useContext(AuthContext);
	const alterPass = store.getState().auth.alterPass;

	const {
		handleSubmit,
		register,
		getValues,
		formState: { errors }
	} = useForm<newPasswordRequest>({ defaultValues: { token: user.token } });

	const onSubmit = (data: any) => {
		onRecoverPassword(data, onClose);
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			autoComplete="off"
			className={`update-password-menu ${show ? "show" : ""}`}
		>
			{!alterPass ? (
				<div className="update-password-menu-item" onClick={onClose}>
					<ChevronLeft size={32} />
				</div>
			) : (
				<div className="update-password-menu-title">
					<label>{t("userPasswordModal.title")}</label>
				</div>
			)}
			<div className="update-password-menu-data">
				<div className="update-password-menu-line">
					<label htmlFor="password">
						{t("userPasswordModal.oldPassword.label")}
					</label>
					<Input
						variant="light"
						type="password"
						errors={errors.oldPassword}
						placeholder={t("userPasswordModal.oldPassword.placeholder")}
						{...register("oldPassword", {
							required: {
								value: true,
								message: t("userPasswordModal.oldPassword.requiredAlert")
							}
						})}
					/>
				</div>
				<div className="update-password-menu-line">
					<label htmlFor="password">
						{t("userPasswordModal.passwordInput.label")}
					</label>
					<Input
						variant="light"
						type="password"
						errors={errors.password}
						placeholder={t("userPasswordModal.passwordInput.placeholder")}
						{...register("password", {
							required: {
								value: true,
								message: t("userPasswordModal.passwordInput.requiredAlert")
							},
							validate: {
								value: (value) => {
									if (value === getValues("oldPassword"))
										return t(
											"userPasswordModal.passwordInput.passwordMismatchAlert"
										);
								}
							},
							pattern: {
								value:
									/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
								message: t("userPasswordModal.passwordInput.patternAlert")
							}
						})}
					/>
				</div>
				<div className="update-password-menu-line">
					<label htmlFor="passwordConfirmationInput">
						{t("userPasswordModal.passwordConfirmationInput.label")}
					</label>
					<Input
						variant="light"
						type="password"
						errors={errors.passwordConfirmation}
						placeholder={t(
							"userPasswordModal.passwordConfirmationInput.placeholder"
						)}
						{...register("passwordConfirmation", {
							required: {
								value: true,
								message: t(
									"userPasswordModal.passwordConfirmationInput.requiredAlert"
								)
							},
							validate: {
								value: (value) => {
									if (value !== getValues("password"))
										return t(
											"userPasswordModal.passwordConfirmationInput.passwordMismatchAlert"
										);
								}
							}
						})}
					/>
				</div>
			</div>
			<div className="update-password-menu-action">
				<Button
					size="lg"
					type="submit"
					variant="confirmation-solid"
					title={t("userPasswordModal.submitAction")}
				/>
				{!alterPass && (
					<Button
						size="lg"
						type="button"
						onClick={onClose}
						variant="cancellation"
						title={t("userPasswordModal.cancelAction")}
					/>
				)}
			</div>
			{isLoading && <LoadingProgress />}
		</form>
	);
};
