import { zodResolver } from "@hookform/resolvers/zod";
import {
	Button,
	Divider,
	Input,
	LoadingProgress,
	Select
} from "@praticabr/ppsa";
import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SearchIcon from "../../../assets/SvgIcons/SearchIcon";
import { ProductSearch } from "../../../components/ProductsSearchModal";
import { useViewVariable } from "../../../context/ViewContext";
import { orderCreateSchema } from "../../../schemas/orderCreateSchema";
import { createOrder, updateOrder } from "../../../services";
import { store } from "../../../store";
import { FREIGHT_TYPES } from "../../../utils/protheus-functions";
import { OrderItem } from "../OrderItems";
import OrderDealerSearch from "./OrderDealerSearch";
import "./styles.scss";

type OrderCreateProps = {
	order?: any;
	isEditing?: boolean;
	handleCancel?: () => void;
};

export const OrderCreate = ({
	order,
	isEditing,
	handleCancel
}: OrderCreateProps) => {
	const [selectDealer, setSelectDealer] = React.useState<any>();
	const [dealerSearchShowModal, setDealerSearchShowModal] =
		React.useState(false);
	const [productsSearchShowModal, setProductsSearchShowModal] = useState(false);
	const [freightType, setFreightType] = useState<string>("");
	const [isLoading, setIsLoading] = useState(false);
	const [deletingItems, setDeletingItems] = useState<any[]>([]);
	const { partner, userId } = useSelector((state: any) => state?.auth);
	const { mobileSize } = useViewVariable();

	const { i18n } = useTranslation();

	const language = i18n.language || store.getState().auth.language;

	const methods = useForm<orderCreateSchema>({
		defaultValues: {
			userId,
			partnerId: partner?.id
		},
		resolver: zodResolver(orderCreateSchema)
	});

	const {
		register,
		formState: { errors },
		setValue,
		getValues,
		handleSubmit,
		unregister,
		control
	} = methods;

	const navigate = useNavigate();

	useEffect(() => {
		if (errors?.items?.type === "too_small") {
			toast.error("Selecione pelo menos um item");
		}
	}, [errors]);

	const arrayMethods = useFieldArray({
		control,
		name: "items"
	});

	const { fields, append } = arrayMethods;

	useEffect(() => {
		if (order) {
			setSelectDealer(order?.dealer);
			setValue("id", order?.id);
			setValue("dealerId", order?.dealerId);
			setValue("freightType", order?.freightType);
			order?.carrierName && setValue("carrierName", order?.carrierName);
			order?.carrierContact &&
				setValue("carrierContact", order?.carrierContact);
			setValue("observation", order?.observation);
			setValue(
				"items",
				order?.items.map((item: any) => ({
					...item,
					itemId: item.id,
					description: item.product.b1Desc,
					totalPrice: item.price * item.amount
				}))
			);
		}
	}, [order]);

	useEffect(() => {
		if (freightType === "C") {
			unregister("carrierName");
			unregister("carrierContact");
		}
	}, [freightType]);

	const onSubmit = (data: any) => {
		setIsLoading(true);
		isEditing
			? updateOrder({ order: data, deletingItems })
					.then((response) => {
						toast.success("Solicitação atualizado com sucesso!");
						setIsLoading(false);
						onCancel();
					})
					.catch((error) => {
						setIsLoading(false);
						toast.error("Error ao atualizar solicitação");
						console.log(error);
					})
			: createOrder(data)
					.then((response) => {
						toast.success("Solicitação criado com sucesso!");
						setIsLoading(false);
						navigate(`/${language}/request/${response.data.id}`);
					})
					.catch((error) => {
						setIsLoading(false);
						toast.error("Error ao criar solicitação");
						console.log(error);
					});
	};

	const handleDeleteItem = (data: any) => {
		setDeletingItems([...deletingItems, data.itemId]);
	};

	const onCancel = () => {
		if (handleCancel) {
			handleCancel();
			methods.reset();
		}
	};

	return (
		<div className="order-create">
			{!mobileSize && (
				<div className="header-info">
					<span>
						O valor das peças reflete unicamente o valor de tabela da Prática.
						Não estão inclusos impostos, frete e acréscimos por custos
						operacionais da concessionária.
					</span>
				</div>
			)}
			{order && (
				<>
					<div className="content-header">
						<div>
							<strong>Solicitação: </strong>
							{order?.id}
						</div>
						<div>
							<strong>Data: </strong>
							{new Date(order?.createdAt.toString()).toLocaleDateString(
								"pt-BR"
							)}
						</div>
					</div>
					<Divider title={"Detalhes da solicitação"} />
				</>
			)}
			<div className="content-form">
				<div className="form">
					<div className="form-item">
						<header>Concessionária</header>
						<div
							onClick={() => {
								setDealerSearchShowModal(true);
							}}
							className={`order-customer-container ${
								errors.dealerId ? "error-order-customer-container" : ""
							}`}
						>
							<span
								className={selectDealer?.name ? "" : "fake-placeholder"}
								{...register("dealerId", {
									required: {
										value: true,
										message: "O campo tipo de pedido é obrigatório!"
									}
								})}
							>
								{selectDealer?.name
									? selectDealer?.name
									: "Selecione um cliente"}
							</span>
							<small>
								{errors.dealerId && (
									<span className="select-error-span">
										{errors.dealerId.message}
									</span>
								)}
							</small>
							<div>
								<SearchIcon
									width="40px"
									height="40px"
									fill="#5DAEFF"
									onClick={() => {
										setDealerSearchShowModal(true);
									}}
								/>
							</div>
						</div>
					</div>
					<div className="form-item">
						<header>Tipo de frete</header>
						<Select
							autoComplete="off"
							id="freightType"
							options={FREIGHT_TYPES}
							selectedOption={[getValues("freightType")]}
							onSelectOptions={(option) => {
								setValue("freightType", option[0]);
								setFreightType(option[0]);
							}}
							{...register("freightType", {
								required: {
									value: true,
									message: "O campo Tipo de frete é obrigatório!"
								}
							})}
							errors={errors.freightType}
						/>
					</div>
					{freightType === "F" && (
						<>
							<div className="form-item">
								<header>Nome da Transportadora</header>
								<Input
									variant="light"
									{...register("carrierName")}
									errors={errors.carrierName}
								/>
							</div>
							<div className="form-item">
								<header>Contato da Transportadora</header>
								<Input
									variant="light"
									{...register("carrierContact")}
									errors={errors.carrierContact}
								/>
							</div>
						</>
					)}
					<div className="form-item message-for-invoice">
						<header>Observação</header>
						<Input variant="light" {...register("observation")} />
					</div>
				</div>
			</div>
			<Divider title={"Items"} />
			<div className="content-items">
				<FormProvider {...methods}>
					<OrderItem.header />
					<OrderItem.editing
						fieldArray={arrayMethods}
						{...{ handleDeleteItem }}
					/>
					<OrderItem.add
						showModal={() => {
							setProductsSearchShowModal(true);
						}}
					/>
				</FormProvider>
			</div>
			<div className="content-action">
				<Button
					variant="confirmation-solid"
					size="lg"
					title={isEditing ? "Salvar" : "Criar solicitação"}
					onClick={handleSubmit(onSubmit)}
				/>
				<Button
					variant="cancellation"
					size="lg"
					title="Cancelar"
					onClick={() => {
						isEditing ? onCancel() : navigate(`/${language}/requests`);
					}}
				/>
			</div>
			{dealerSearchShowModal && (
				<OrderDealerSearch
					onClose={() => setDealerSearchShowModal(false)}
					onChangeDealer={(item: any) => {
						setValue("dealerId", item.id);
						setSelectDealer(item);
						setDealerSearchShowModal(false);
					}}
				/>
			)}
			{productsSearchShowModal && (
				<ProductSearch
					onClose={() => setProductsSearchShowModal(false)}
					previousSelectedItens={fields}
					onConfirmSetSelectedEquipments={(e) => {
						append(e);
						setProductsSearchShowModal(false);
					}}
				/>
			)}
			{isLoading && <LoadingProgress />}
		</div>
	);
};
