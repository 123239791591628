import { GenericTable } from "@praticabr/ppsa";
import React from "react";
import {
    handleStatusItemTypes,
    handleStatusRequestTypes
} from "../../utils/HandleTypes";
import { InfoExpansive } from "../InfoExpansive";
import "./styles.scss";

type PropsEvent = {
	eventList: any[];
};

export const EventList = ({ eventList }: PropsEvent) => {
	// const showAttachments = (attachments: any[]) => {
	// 	return (
	// 		<span className="show-attachments">
	// 			{attachments.map((attachment, index) => (
	// 				<button
	// 					key={`${attachment?.file?.id}${index}`}
	// 					onClick={() => window.open(attachment.file.link)}
	// 					disabled={!!attachment.deletedAt}
	// 					className={`attachment-button ${
	// 						attachment.deletedAt ? "deleted" : ""
	// 					}`}
	// 				>
	// 					Anexo {index + 1}
	// 				</button>
	// 			))}
	// 		</span>
	// 	);
	// };

	const contentArray = (content: string) => {
		try {
			return JSON?.parse(content);
		} catch (error) {
			return [];
		}
	};

	// useEffect(() => {
	// 	eventList.map((event) => {
	// 		console.log(contentArray(event?.content)?.length);
	// 		return null;
	// 	});
	// }, [eventList]);

	return (
		<>
			{eventList?.map((event, index) => (
				<div key={index} className="event">
					<div className="map">
						<div className="line" />
						<div className="ball" />
					</div>
					<div className="info">
						<span className="event-date">
							{new Date(event?.createdAt)?.toLocaleString("pt-BR")}
						</span>
						<span className="event-title">
							{handleStatusRequestTypes(event?.type)}
						</span>
						<span className="event-content">
							{contentArray(event?.content)?.length > 0 ? (
								<GenericTable
									data={[
										...contentArray(event?.content).map((item: any) => ({
											...item,
											status: handleStatusItemTypes(item?.status)
										}))
									]}
									columns={[
										{
											key: "code",
											header: "Código",
											width: "20%"
										},
										{
											key: "description",
											header: "Descrição",
											width: "60%"
										},
										{
											key: "amountServed",
											header: "Qtd. Atendida",
											width: "20%"
										}
									]}
								/>
							) : (
								<InfoExpansive text={event?.content} />
							)}
						</span>
						<span className="event-user">{event?.contact}</span>
						{/* {!!event?.attachments?.length &&
							showAttachments(event?.attachments)} */}
					</div>
				</div>
			))}
		</>
	);
};
