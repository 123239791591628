import React, {
	ReactNode,
	createContext,
	useEffect,
	useRef,
	useState
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Sc5010Overview } from "../models/usecases/get-sc5010-overview";
import { GetSc5010sCustomList } from "../models/usecases/get-sc5010s-custom-list";
import {
	getBillets,
	getPartsToReturn,
	getSc5010sCustomList,
	onGetSc5010Overview
} from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	clearListParams,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export interface ContextType {
	sc5010: Sc5010Overview | null;
	orders: GetSc5010sCustomList.Return["list"];
	billets: any[];
	partsToReturn: any[];
	isLoading: boolean;
	limit: number;
	total: number;
	offset: number;
	onGetBillet: (data: any) => void;
	onUpdateList: () => void;
	onChangeParams: ({
		offset,
		limit,
		term,
		category,
		status
	}: GetSc5010sCustomList.Params) => void;
}

export const PraticaContext = createContext<ContextType>({
	sc5010: null,
	orders: [],
	billets: [],
	partsToReturn: [],
	isLoading: false,
	limit: 20,
	total: 1,
	offset: 1,
	onUpdateList: () => {},
	onGetBillet: () => {},
	onChangeParams: () => {}
});

const defaultParams = defaultListParams();

export const PraticaProvider = ({ children }: { children: ReactNode }) => {
	const [orders, setOrders] = useState<GetSc5010sCustomList.Return["list"]>([]);
	const [billets, setBillets] = useState<any[]>([]);
	const [sc5010, setSc5010] = useState<Sc5010Overview | null>(null);
	const [partsToReturn, setPartsToReturn] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const params = useRef<ListParams>(defaultParams);
	const { pathname } = location;

	const { language, c5Filial, c5Num } = useParams();

	useEffect(() => {
		if (pathname === `/${language}/pratica/billets`) {
			updateParams("", "search");
			updateParams(1, "offset");
			onGetBillets(params.current);
		}

		if (pathname === `/${language}/pratica/orders`) {
			updateParams("", "search");
			updateParams(1, "offset");
			onGetOrders(params.current);
		}

		if (
			pathname === `/${language}/pratica/order/${c5Filial}/${c5Num}` &&
			c5Filial &&
			c5Num
		) {
			onGetSc5010({ c5Filial, c5Num });
		}

		if (pathname === `/${language}/pratica/partsToReturn`) {
			updateParams("", "search");
			updateParams(1, "offset");
			onGetPartsToReturn(params.current);
		}
	}, [pathname]);

	const { t } = useTranslation();

	const onGetOrders = (newParams: GetSc5010sCustomList.Params) => {
		if (isLoading) return;
		setIsLoading(true);
		getSc5010sCustomList({ ...newParams })
			.then((response) => {
				setOrders(response.data.list);
				updateParams(
					Math.ceil(response.data.count / params.current.limit),
					"total"
				);
			})
			.catch(() => {
				toast.error(t("ProtheusOrderContext.getOrdersError"));
				clearParams(true);
			})
			.finally(() => setIsLoading(false));
	};

	const onGetPartsToReturn = (newParams: ListParams) => {
		if (isLoading) return;

		setIsLoading(true);
		getPartsToReturn(newParams)
			.then((response) => {
				setPartsToReturn(response.data.list);
				updateParams(
					Math.ceil(response.data.count / params.current.limit),
					"total"
				);
			})
			.catch(() => {
				toast.error(t("ProtheusOrderContext.getPartsToReturnError"));
				clearParams(true);
			})
			.finally(() => setIsLoading(false));
	};

	const onGetBillets = (newParams: ListParams) => {
		if (isLoading) return;
		setIsLoading(true);
		getBillets(newParams)
			.then((response) => {
				setBillets(response.data.list);
				updateParams(
					Math.ceil(response.data.count / params.current.limit),
					"total"
				);
			})
			.catch(() => {
				toast.error(t("ProtheusOrderContext.getBilletsError"));
				clearParams(true);
			})
			.finally(() => setIsLoading(false));
	};

	const onGetSc5010 = (data: { c5Filial: string; c5Num: string }) => {
		setSc5010(null);
		setIsLoading(true);
		onGetSc5010Overview(data)
			.then((response) => setSc5010(response.data))
			.catch(() => toast.error(t("ProtheusOrderContext.getOrdersError")))
			.finally(() => setIsLoading(false));
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		params.current = updateListParams(value, field, params.current);
	};

	const clearParams = (clearAll = false) => {
		params.current = clearListParams(params.current, clearAll);
	};

	const onChangeParams = (newParams: GetSc5010sCustomList.Params) => {
		updateParams(newParams.term || "", "search");
		updateParams(newParams.status || "", "status");
		newParams.offset && updateParams(newParams.offset, "offset");
		newParams.limit && updateParams(newParams.limit, "limit");
		newParams.category && updateParams(newParams.category, "category");

		pathname === `/${language}/pratica/billets` && onGetBillets(params.current);
		pathname === `/${language}/pratica/orders` && onGetOrders(params.current);
		pathname === `/${language}/pratica/partsToReturn` &&
			onGetPartsToReturn(params.current);
	};

	const onGetBillet = async (data: any) => {
		const { e1Numbor, e1Prefixo, e1Num, e1Parcela, e1Tipo } = data;
		if (!e1Numbor || !e1Prefixo || !e1Num || !e1Parcela || !e1Tipo) {
			toast.error("Boleto não encontrado, verifique com o financeiro!");
			return;
		}

		const url = `http://boleto.cloud.praticabr.com:8788/${e1Numbor}-${e1Prefixo}${e1Num}${e1Parcela}-${e1Tipo}.pdf`;
		const teste = window.open(url, "_blank");

		if (!teste) {
			baixarPDF(url);
		}
	};

	const baixarPDF = (url: string) => {
		fetch(url)
			.then((response) => response.blob())
			.then((blob) => {
				const urlBlob = window.URL.createObjectURL(blob);

				const link = document.createElement("a");
				link.href = urlBlob;
				link.download = "arquivo.pdf";

				document.body.appendChild(link);
				link.click();

				window.URL.revokeObjectURL(urlBlob);
				document.body.removeChild(link);
			})
			.catch((error) => {
				console.error("Ocorreu um erro ao baixar o PDF:", error);
			});
	};

	const value = {
		orders,
		billets,
		sc5010,
		partsToReturn,
		isLoading,
		...params.current,
		onChangeParams,
		onGetBillet,
		onUpdateList: () => {
			onGetBillets(params.current);
			onGetOrders(params.current);
		}
	};

	return (
		<PraticaContext.Provider value={value}>{children}</PraticaContext.Provider>
	);
};
