import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { store } from "../../store";
import "./styles.scss";

type LinkListContainProps = {
	activeLinkClass: string[];
	link: string;
	title: string;
	icon: React.JSX.Element;
	nextToBottom?: boolean;
	joyride?: string;
};

const languageTechnicalMaterials: any = {
	pt: "pt-br",
	en: "en-us",
	es: "es-bo"
};

const LinkListContain = ({
	activeLinkClass,
	link,
	title,
	icon,
	nextToBottom = false,
	joyride
}: LinkListContainProps) => {
	const { i18n } = useTranslation();
	const navigator = useNavigate();

	const language = i18n.language || store.getState().auth.language;
	const pathname = window.location.pathname;

	return (
		<li className={"list-item"} style={{ flex: nextToBottom ? "auto" : "" }}>
			<a
				id={joyride}
				onClick={() => navigator(link)}
				className={`list-link ${activeLinkClass?.includes(pathname) ? "active" : ""}`}
			>
				{icon}
				<span className={"span-link show-span"}>{title}</span>
			</a>
		</li>
	);
};

export default LinkListContain;
