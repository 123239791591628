import { GenericTable, Modal, Paginator, Search } from "@praticabr/ppsa";
import React, { useContext, useEffect } from "react";
import { FieldArrayWithId, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SearchSvg from "../../../assets/SvgIcons/SearchSvg";
import LoadingList from "../../../components/LoadingList";
import { TechnicalContext } from "../../../context/TechnicalContext";
import { updateAccountRequest } from "../../../models/usecases/users";
import "./styles.scss";

interface PartnerSearchProps {
	onClose: (value: boolean) => void;
	onChangePartner: (item: any) => void;
	arrayPartners?: FieldArrayWithId<
		updateAccountRequest,
		"availablePartners",
		"id"
	>[];
}

export const PartnerSearch = ({
	onClose,
	onChangePartner,
	arrayPartners
}: PartnerSearchProps) => {
	const {
		onSearchParams,
		list,
		isLoading,
		limit,
		offset,
		total,
		onPageChange
	} = useContext(TechnicalContext);

	const { userId } = useSelector((state: any) => state?.auth);

	const {
		formState: { errors }
	} = useForm();

	useEffect(() => {
		onSearchParams("");
	}, []);

	const onChangeValues = (value: string) => {
		onSearchParams(value.toUpperCase());
	};

	const changePartner = (technical: any) => {
		if (
			!arrayPartners?.some(
				(partner) => partner.code === `${technical?.a1Cod}${technical?.a1Loja}`
			)
		) {
			const formatPartner = {
				...technical,
				code: `${technical?.a1Cod}${technical?.a1Loja}`,
				name: technical?.a1Nreduz,
				table: ""
			};
			onChangePartner(formatPartner);
		} else {
			toast.error(`${technical?.a1Nreduz} ja foi adicionado`);
		}
	};

	return (
		<Modal.root>
			<Modal.header
				title="BUSCAR PARCEIRO"
				onClose={() => {
					onClose(false);
				}}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="partners-list-container">
						<div className="partners-input-container">
							<div>
								<Search.root>
									<Search.icon>
										<SearchSvg />
									</Search.icon>
									<Search.input
										{...{ onChangeValues }}
										placeholder={"Buscar parceiro..."}
									/>
								</Search.root>
							</div>
						</div>
						<div className="partners-body">
							{isLoading ? (
								<LoadingList show={true} />
							) : (
								<GenericTable
									data={[
										...list.map((item) => {
											return {
												id: item?.rECNO,
												a1Cod: item?.a1Cod,
												a1Nreduz: item?.a1Nreduz,
												a1Email: item?.a1Email,
												a1Loja: item?.a1Loja
											};
										})
									]}
									columns={[
										{
											header: "Código",
											key: "a1Cod",
											width: "12%"
										},
										{
											header: "Loja",
											key: "a1Loja",
											width: "8%"
										},
										{
											header: "Nome",
											key: "a1Nreduz",
											width: "30%"
										},
										{
											header: "E-mail",
											key: "a1Email",
											width: "50%"
										}
									]}
									onRowClicked={(e) => changePartner(e)}
								/>
							)}
						</div>
						<div className="parter-search-paginator">
							<Paginator
								totalPages={total}
								currentPage={offset}
								onPageChange={(value) => onPageChange(value)}
							/>
						</div>
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	);
};
