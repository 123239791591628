import React, { useEffect, useRef, useState } from "react";
import { useViewVariable } from "../../context/ViewContext";
import "./styles.scss";

type Props = {
	label?: string;
	text: string;
};

export const InfoExpansive = ({ text, label }: Props) => {
	const { screenSize } = useViewVariable();

	const [expanded, setExpanded] = useState(false);
	const [shouldDisplayButton, setShouldDisplayButton] = useState(false);
	const divRef = useRef<HTMLDivElement>(null);
	const spanRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		if (divRef.current && spanRef.current) {
			const lineHeight = parseFloat(
				window.getComputedStyle(divRef.current).lineHeight || "0"
			);
			const maxHeight = lineHeight * 4;
			const actualHeight = spanRef.current.offsetHeight || 0;
			setShouldDisplayButton(actualHeight > maxHeight);
		}
	}, [text, screenSize]);

	const toggleExpanded = () => {
		setExpanded(!expanded);
	};

	const displayStyleDiv: React.CSSProperties = {
		maxHeight: expanded ? "none" : "68px",
		overflow: "hidden",
		position: "relative",
		lineHeight: "17px"
	};

	return (
		<div>
			<div className="info-expansive">
				<div ref={divRef} className="container" style={displayStyleDiv}>
					<span ref={spanRef} className="text">
						<strong>{label} </strong>
						{text}
					</span>
					{shouldDisplayButton && expanded && (
						<span className="view-less" onClick={toggleExpanded}>
							ver menos
						</span>
					)}
					{shouldDisplayButton && !expanded && (
						<span className="view-more" onClick={toggleExpanded}>
							{"... ver mais"}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};
