import React from "react";
import { handleStatusRequestTypes } from "../../utils/HandleTypes";
import { FREIGHT_TYPES, handleType } from "../../utils/protheus-functions";
import "./styles.scss";

type Props = {
	order?: any;
};

export const InfoTimeLine = ({ order }: Props) => {
	return (
		<>
			<div className="info-timeline">
				<div className="info-timeline-header">
					<h1>
						<strong>Solicitação: </strong>
						{order?.id}
					</h1>
					<div className="info-timeline-info">
						<strong>Data: </strong>
						{new Date(order?.createdAt.toString()).toLocaleDateString("pt-BR")}
					</div>
				</div>
				<div className="info-timeline-main-contain">
					<div className="info-timeline-info">
						<header>Status</header>
						<span title={handleStatusRequestTypes(order?.status)}>
							{handleStatusRequestTypes(order?.status)}
						</span>
					</div>
					<div className="info-timeline-info">
						<header>Concessionária</header>
						<span title={order?.dealer?.name}>{order?.dealer?.name}</span>
					</div>
					<div className="info-timeline-info">
						<header>Cliente</header>
						<span title={order?.owner?.name}>{order?.owner?.name}</span>
					</div>
					<div className="info-timeline-info">
						<header>Solicitante</header>
						<span>{order?.user?.contact}</span>
					</div>
					<div className="info-timeline-double">
						<div className="info-timeline-info">
							<header>Tipo de frete</header>
							<span>
								{handleType({
									value: order?.freightType,
									types: FREIGHT_TYPES
								})}
							</span>
						</div>
						{order?.freightType === "F" && (
							<>
								<div className="info-timeline-info">
									<header>Nome da Transportadora</header>
									<span>{order?.carrierName}</span>
								</div>
								<div className="info-timeline-info">
									<header>Contato da Transportadora</header>
									<span>{order?.carrierContact}</span>
								</div>
							</>
						)}
					</div>
					<div className="info-timeline-info">
						<header>Observação</header>
						<span>{order?.observation || "-"}</span>
					</div>
				</div>
			</div>
		</>
	);
};
