import React from "react";
import "./styles.scss";

export const OrderItemsHeader = () => (
	<div className="items-list-header">
		<ul className="items-list-orders items-list-order-product-code">
			<span>Código</span>
		</ul>
		<ul className="items-list-orders items-list-order-description">
			<span>Descrição</span>
		</ul>
		<ul className="items-list-orders items-list-order-amount">
			<span>Quant.</span>
		</ul>
		<ul className="items-list-orders items-list-order-unit-price">
			<span>Valor unitário (R$)</span>
		</ul>
		<ul className="items-list-orders items-list-order-total-price">
			<span>Total merc. (R$)</span>
		</ul>
		<ul className="items-list-order-item items-list-order-ipi">
			<span>IPI</span>
		</ul>
		<ul className="items-list-order-item items-list-order-alert" />
		<ul className="items-list-order-item items-list-order-options" />
	</div>
);
