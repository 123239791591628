import React, { useContext, useEffect, useState } from "react";
import { OrderContext } from "../../context/OrderContext";
import { Order } from "./OrderRoot";

export const OrderDetails = () => {
	const [isEditing, setIsEditing] = useState(false);

	const { handleOrderById, isLoading, order } = useContext(OrderContext);

	useEffect(() => {
		if (!order || !isEditing) {
			const orderId = Number(location.pathname.split("/")[3]);
			handleOrderById(orderId);
		}
	}, [isEditing, location.pathname]);

	return (
		<>
			{isEditing ? (
				<Order.editing
					order={order}
					isEditing={isEditing}
					handleCancel={() => setIsEditing(false)}
				/>
			) : (
				<Order.view handleEdit={() => setIsEditing(true)} order={order} />
			)}
		</>
	);
};
