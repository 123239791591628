import React, { ReactNode } from "react";
import "./styles.scss";

type Props = {
	children: ReactNode;
};

export const UploadRoot = ({ children }: Props) => {
	return <div className="new-upload-file-root">{children}</div>;
};
