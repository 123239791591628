import { GenericTable, Modal, Paginator, Search } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import { FieldArrayWithId, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { DealerContext } from "../../context/DealerContext";
import { updatePartnerRequest } from "../../models/usecases";
import LoadingList from "../LoadingList";
import "./styles.scss";

interface OrderDealerSearchProps {
	onClose: (value: boolean) => void;
	onChangeDealer: (item: any) => void;
	arrayDealers?: FieldArrayWithId<
		updatePartnerRequest,
		"sellersOnPartners",
		"id"
	>[];
}

export const DealerSearchModal = ({
	onClose,
	onChangeDealer,
	arrayDealers
}: OrderDealerSearchProps) => {
	const { onSearchParams, onPageChange, list, isLoading, total, offset } =
		useContext(DealerContext);

	const { userId } = useSelector((state: any) => state?.auth);

	const {
		formState: { errors }
	} = useForm();

	useEffect(() => {
		onSearchParams("");
	}, []);

	const onChangeValues = (value: string) => {
		onSearchParams(value.toUpperCase());
	};

	const changeDealer = (selectDealer: any) => {
		if (!arrayDealers?.some((partner) => partner.code === selectDealer.code)) {
			onChangeDealer(selectDealer);
		} else {
			toast.error(`${selectDealer?.name} ja foi adicionado`);
		}
	};

	return (
		<Modal.root>
			<Modal.header
				title="BUSCAR CONCESSIONÁRIA"
				onClose={() => {
					onClose(false);
				}}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="dealers-list-container">
						<div className="dealer-input-container">
							<div>
								<Search.root>
									<Search.icon>
										<SearchSvg />
									</Search.icon>
									<Search.input
										{...{ onChangeValues }}
										placeholder={"Buscar concessionária"}
									/>
								</Search.root>
							</div>
						</div>
						<div className="dealers-body">
							{isLoading ? (
								<LoadingList show={true} />
							) : (
								<>
									<div className="dealers-body-list">
										<GenericTable
											onRowClicked={changeDealer}
											data={[
												...list.map((item, index) => ({
													...item,
													code: item.code,
													name: item.name,
													table: item.table
												}))
											]}
											columns={[
												{
													key: "code",
													header: t("managePartnerModal.inputCode.label"),
													width: "20%"
												},
												{
													key: "name",
													header: t(
														"managePartnerModal.inputPartnerName.label"
													),
													width: "60%"
												},
												{
													key: "table",
													header: t("managePartnerModal.selectTable.label"),
													width: "20%"
												}
											]}
										/>
									</div>
									<Paginator
										totalPages={total}
										currentPage={offset}
										onPageChange={(value) => onPageChange(value)}
									/>
								</>
							)}
						</div>
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	);
};
