import React from "react";
import "./styles.scss";

interface SvgProps extends React.SVGProps<SVGSVGElement> {
	width: string;
	height: string;
	fill?: string;
	id?: string;
}

const PraticaIcon: React.FC<SvgProps> = ({
	fill = "#004687",
	width = "295px",
	height = "72px",
	id = "ptp_2"
}) => {
	return (
		<svg
			id={id}
			fill={fill}
			width={width}
			height={height}
			viewBox="0 0 295 72"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M30.5067 6.88757H44.4294L45.4939 7.09015L46.4744 7.4953L47.1467 8.10303L47.763 8.82651L48.1272 9.81045L48.3233 10.9102V12.2124L48.1272 13.7173L45.0177 25.9297L44.0652 28.7368L42.8886 31.023L41.628 32.8462L40.1993 34.1485L38.6306 35.0456L36.9498 35.6533L35.1009 35.9427L33.252 35.8559H22.943L30.5347 6.88757H30.5067ZM18.5169 0L0 71.625H13.5305L20.842 43.9589H32.4116L34.8768 43.6695L37.0899 43.2644L39.3309 42.8014L41.3759 42.0779L43.3088 41.3544L45.1577 40.4573L46.9226 39.4444L48.5754 38.3447L50.1441 37.1582L51.6289 35.8559L52.9735 34.4668L54.2341 32.962L55.4667 31.3703L56.5592 29.6918L57.5117 27.8686L58.4922 25.9586L60.9293 16.7559L61.1254 15.1642V13.5726L61.0414 12.1835L60.8453 10.7944L60.4531 9.49212L59.9769 8.18985L59.2765 6.97439L58.5762 5.8747L57.6237 4.89076L56.6433 3.9647L55.4667 3.06757L54.1221 2.34409L52.6653 1.64954L51.0966 1.04182L49.3318 0.40515L47.4829 0.0289401H18.4609L18.5169 0Z"
				fill={fill}
			/>
			<path
				d="M130.291 25.9297L93.0328 65.2873L88.0464 56.2871L89.8953 55.4768L91.6321 54.3771L93.2009 53.1617L94.6856 51.6857L95.8341 49.9494L96.8987 48.0683L97.7951 45.9847L98.5795 43.6695L99.0557 41.6727L99.4479 39.7338L99.644 38.0553V36.4347L99.4479 34.9588L99.1397 33.5407L98.6635 32.3542L97.9912 31.1098L97.2068 30.1548L96.3104 29.2288L95.2459 28.4185L94.0973 27.7239L92.6966 27.1451L91.352 26.6242L89.6992 26.2191L88.0464 25.9297H65.7477L53.6738 71.625H63.2264L74.712 31.2256L86.0014 31.1098H87.3741L88.5226 31.3414L89.4191 31.8333L89.9793 32.6436L90.3995 33.7433L90.4836 35.1324L90.3995 36.8688L89.9793 38.8367L86.0854 49.0812L85.4411 50.2677L84.7408 51.2517L83.9564 52.062L83.088 52.6697L82.1075 53.0748L81.1271 53.2774L80.0626 53.1617L78.914 52.988L89.6151 71.625H95.442L133.708 30.8204H135.949L127.461 66.0107H113.259L109 71.625H135.837L147.827 25.9297H130.291Z"
				fill={fill}
			/>
			<path
				d="M152.225 25.9297L150.825 31.2256H165.84L155.503 71.625H166.148L177.017 31.2256H190.688L191.64 25.9297H152.225Z"
				fill={fill}
			/>
			<path
				d="M276.717 25.9297L238.871 66.3001H217.665L216.096 66.0107L214.807 65.5767L213.939 64.911L213.351 64.1007L213.07 63.0879V61.9882L213.266 60.8017L213.631 59.3836L214.051 57.8788L214.415 56.4897L214.695 54.9848L215.115 53.451L215.48 52.062L215.9 50.5861L216.18 49.0812L216.544 47.5474L216.964 46.1583L217.328 44.6824L217.749 43.1486L218.029 41.7595L218.393 40.2547L218.813 38.7498L219.177 37.3607L219.598 35.8559L220.074 34.4379L220.662 33.3382L221.334 32.5568L222.287 31.8333L223.491 31.4571L225.032 31.2256L226.965 31.023L229.402 30.9362L231.083 30.8204L232.652 30.9362L233.912 31.2256L234.977 31.7465L235.761 32.4411L236.238 33.3382L236.406 34.5536L236.238 35.8559L236.125 36.7241L235.929 37.6501L235.649 38.3447L235.481 39.0682L235.145 39.7338L234.781 40.3704L234.473 40.9492L234.08 41.4701H235.565H236.91H238.395H239.739H241.112H242.569H243.941H245.37L245.79 38.171L245.678 35.1324L245.006 32.6436L243.941 30.5311L242.288 28.7368L240.243 27.4345L237.61 26.4217L234.473 25.9297H233.408H232.344H231.279H230.299H229.234H228.17H227.105H226.013L224.556 26.0454L223.183 26.1323L221.727 26.4217L220.354 26.8268L219.093 27.232L217.749 27.8107L216.46 28.5342L215.311 29.3445L214.135 30.3574L212.986 31.4571L211.894 32.7304L210.801 34.1485L209.849 35.6533L208.868 37.4476L208 39.3576L207.103 41.4701L203.91 57.5894L203.714 60.6859L204.106 63.493L204.89 65.6924L206.151 67.6024L207.804 69.0783L209.849 70.3227L212.286 71.1041L215.115 71.625H241.868L279.154 31.2256H281.703L273.72 66.3001H259.209L254.838 71.625H283.944L294.169 25.9297H276.717Z"
				fill={fill}
			/>
			<path
				d="M153.094 4.4856L149.396 18.7817L159.341 4.4856H153.094Z"
				fill={fill}
			/>
			<path
				d="M196.599 25.9297L184.917 71.625H195.338L206.936 25.9297H196.599Z"
				fill={fill}
			/>
		</svg>
	);
};

export default PraticaIcon;
