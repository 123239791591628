import { Button } from "@praticabr/ppsa";
import React from "react";
import { LuPlus } from "react-icons/lu";
import { useViewVariable } from "../../context/ViewContext";

type Props = {
	text: string | React.ReactNode;
	onClick?: () => void;
};

export const ButtonHeader = ({ text, onClick }: Props) => {
	const { mobileSize } = useViewVariable();

	return (
		<Button
			{...{ onClick }}
			title={!mobileSize ? text : <LuPlus />}
			variant="confirmation-solid"
			size="lg"
		/>
	);
};
