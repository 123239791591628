import { Divider } from "@praticabr/ppsa";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateAccountTutorials } from "../../../services";
import { store } from "../../../store";
import { signOut, updateTutorials } from "../../../store/modules/auth/actions";
import "./styles.scss";

interface Params {
	handleConfigModalToggle: () => void;
}

export const UserSelector: React.FC<Params> = ({ handleConfigModalToggle }) => {
	const user = store.getState().auth;
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();

	const language = i18n.language || store.getState().auth.language;

	const handleLogout = () => {
		dispatch(signOut());
		window.location.href = `/${language}/login`;
	};

	const handleUpdateTutorial = async () => {
		await updateAccountTutorials([]);
		dispatch(updateTutorials([]));
		window.location.reload();
	};

	return (
		<section className="user-area-selector">
			<Divider title={`${user.contact.split(" ")[0]}`} />
			<div
				className="user-area-selector-item"
				onClick={handleConfigModalToggle}
			>
				<span>{t("navbar.userDropdown.editProfile")}</span>
			</div>
			{/* <div className="user-area-selector-item" onClick={handleUpdateTutorial}>
				<span>{t("navbar.userDropdown.reviewTutorials")}</span>
			</div> */}
			<div className="user-area-selector-item" onClick={handleLogout}>
				<span>{t("navbar.userDropdown.logout")}</span>
			</div>
		</section>
	);
};
