import React, { ReactNode, createContext, useRef, useState } from "react";
import { TechnicalModel } from "../models";
import { getTechnicalList } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const TechnicalContext = createContext({
	list: [] as TechnicalModel[],
	isLoading: false,
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	onPageChange: (page: number, whece?: string) => {},
	onSearchParams: (searchTerm: string) => {},
	onGetTechnicalList: (params: any) => {}
});

const defaultParams = defaultListParams();

export const TechnicalProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [technicalList, setTechnicalList] = useState<TechnicalModel[]>(
		[] as TechnicalModel[]
	);
	const listParams = useRef<ListParams>(defaultParams);

	const handleGetTechnicalList = () => {
		const { limit, offset, term } = { ...listParams.current };
		const currentPage = offset - 1;
		setIsLoading(true);
		getTechnicalList({
			limit,
			term: term || "",
			offset: currentPage
		})
			.then((response) => {
				setTechnicalList(response.data.technicalList);
				updateParams(Math.ceil(response.data.total / limit), "total");
			})
			.catch((err) => console.log("Ocorreu um erro", err))
			.finally(() => setIsLoading(false));
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		handleGetTechnicalList();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetTechnicalList();
	};

	const value = {
		list: technicalList,
		...listParams.current,
		onPageChange,
		isLoading,
		onSearchParams,
		onGetTechnicalList: handleGetTechnicalList
	};

	return (
		<TechnicalContext.Provider value={value}>
			{children}
		</TechnicalContext.Provider>
	);
};
