import { Input } from "@praticabr/ppsa";
import React, { ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { loginRequestForm } from "../../pages/Authenticate";
import "./styles.scss";

type Props = {
	children: ReactNode;
	form: UseFormReturn<loginRequestForm>;
	view: React.Dispatch<
		React.SetStateAction<"login" | "recoverPassword" | "forgotPassword">
	>;
	submit: (data: loginRequestForm) => Promise<void>;
};

export const LoginFormContain = ({ children, form, view, submit }: Props) => {
	const { t } = useTranslation();
	const {
		register,
		formState: { errors }
	} = form;
	return (
		<form className="authenticate-form" onSubmit={form.handleSubmit(submit)}>
			<div className="authenticate-input">
				<p>{t("loginPage.loginInput.label")}</p>
				<Input
					variant="light"
					errors={errors.email}
					placeholder={t("loginPage.loginInput.placeholder")}
					{...register("email", {
						required: {
							value: true,
							message: t("loginPage.loginInput.requiredAlert")
						}
					})}
				/>
			</div>
			<div className="authenticate-input">
				<p>{t("loginPage.passwordInput.label")}</p>
				<Input
					type="password"
					variant="light"
					errors={errors.password}
					placeholder={t("loginPage.passwordInput.placeholder")}
					{...register("password", {
						required: {
							value: true,
							message: t("loginPage.passwordInput.requiredAlert")
						}
					})}
				/>
			</div>
			<a className="forgot-password" onClick={() => view("forgotPassword")}>
				{t("loginPage.forgotPasswordAction")}
			</a>
			<div className="authenticate-actions">{children}</div>
		</form>
	);
};
