import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context";
import "./style.scss";

interface MenuMobileProps {
	show: boolean;
	onClose: () => void;
}

export const MenuMobile: React.FC<MenuMobileProps> = ({ show, onClose }) => {
	const [selectedTab, setSelectedTab] = useState<string>("");
	const { pathname } = useLocation();
	const { roles } = useContext(AuthContext).user;
	const isTechnicalAssistance = roles?.includes(4) || roles?.includes(3);
	const navigate = useNavigate();

	useEffect(() => {
		if (pathname.split("/").includes("orders")) {
			return setSelectedTab("orders");
		}

		if (pathname.split("/").includes("billets")) {
			return setSelectedTab("billets");
		}

		if (pathname.split("/").includes("partsToReturn")) {
			return setSelectedTab("partsToReturn");
		}

		if (pathname.split("/").includes("catalog")) {
			return setSelectedTab("catalog");
		}

		if (pathname.split("/").includes("tags")) {
			return setSelectedTab("tags");
		}

		if (pathname.split("/").includes("subTags")) {
			return setSelectedTab("subTags");
		}
		if (pathname.split("/").includes("users")) {
			return setSelectedTab("users");
		}
		if (pathname.split("/").includes("partners")) {
			return setSelectedTab("partners");
		}
	}, [pathname]);

	return (
		<div className="menu-mobile">
			<div
				className={`menu-mobile-container ${show ? "open" : ""}`}
				onClick={onClose}
			>
				{pathname.includes("/pratica") ? (
					<div className="menu-mobile-sidebar">
						<div
							className={`menu-mobile-options ${
								selectedTab === "orders" && "menu-mobile-option-selected"
							}`}
							onClick={() => {
								navigate("/pt/pratica/orders");
								onClose();
							}}
						>
							<span>PEDIDOS</span>
						</div>
						<div
							className={`menu-mobile-options ${
								selectedTab === "partsToReturn" && "menu-mobile-option-selected"
							}`}
							onClick={() => {
								navigate("/pt/pratica/partsToReturn");
								onClose();
							}}
						>
							<span>PEÇAS A DEVOLVER</span>
						</div>
						<div
							className={`menu-mobile-options ${
								selectedTab === "billets" && "menu-mobile-option-selected"
							}`}
							onClick={() => {
								navigate("/pt/pratica/billets");
								onClose();
							}}
						>
							<span>BOLETOS</span>
						</div>
					</div>
				) : pathname.includes("/editor") && isTechnicalAssistance ? (
					<div className="menu-mobile-sidebar">
						<div
							className={`menu-mobile-options ${
								selectedTab === "catalog" && "menu-mobile-option-selected"
							}`}
							onClick={() => {
								navigate("/pt/editor/catalog");
								onClose();
							}}
						>
							<span>CATALOGO</span>
						</div>
						<div
							className={`menu-mobile-options ${
								selectedTab === "tags" && "menu-mobile-option-selected"
							}`}
							onClick={() => {
								navigate("/pt/editor/tags");
								onClose();
							}}
						>
							<span>TAGS</span>
						</div>
						<div
							className={`menu-mobile-options ${
								selectedTab === "subTags" && "menu-mobile-option-selected"
							}`}
							onClick={() => {
								navigate("/pt/editor/subTags");
								onClose();
							}}
						>
							<span>SUBTAGS</span>
						</div>
					</div>
				) : pathname.includes("/users") || pathname.includes("/partners") ? (
					<div className="menu-mobile-sidebar">
						<div
							className={`menu-mobile-options ${
								selectedTab === "users" && "menu-mobile-option-selected"
							}`}
							onClick={() => {
								navigate("/pt/users");
								onClose();
							}}
						>
							<span>USUÁRIOS</span>
						</div>
						<div
							className={`menu-mobile-options ${
								selectedTab === "partners" && "menu-mobile-option-selected"
							}`}
							onClick={() => {
								navigate("/pt/partners");
								onClose();
							}}
						>
							<span>PARCEIROS</span>
						</div>
					</div>
				) : (
					<div className="menu-mobile-sidebar"></div>
				)}
			</div>
		</div>
	);
};
