import React, { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context";
import { store } from "../../store";

export const UnprotectedRoutes = ({ children }: { children: ReactNode }) => {
	const { user } = useContext(AuthContext);

	const { i18n } = useTranslation();

	const language = i18n.language || store.getState().auth.language;

	return user.token ? (
		<Navigate to={`/${language}/requests`} />
	) : (
		<>{children}</>
	);
};
