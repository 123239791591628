import { t } from "i18next";
import { ChevronLeft, CirclePower, Settings } from "lucide-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Ptp from "../../assets/SvgIcons/Ptp";
import { store } from "../../store";
import { signOut } from "../../store/modules/auth/actions";
import { ProfileMenu } from "../ProfileMenu";
import "./style.scss";

interface ListLinks {
	hasHole: boolean;
	link: string;
	icon: React.JSX.Element;
	title: string;
	joyride: string;
	activeLinkClass: string[];
}

interface MenuPageProps {
	show: boolean;
	onClose: () => void;
	data?: ListLinks[];
}

export const MenuPage: React.FC<MenuPageProps> = ({ show, data, onClose }) => {
	const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { i18n } = useTranslation();

	const language = i18n.language || store.getState().auth.language;

	const handleLogout = () => {
		dispatch(signOut());
		window.location.href = `/${language}/login`;
	};

	return (
		<div className={`menu-page ${show ? "show" : ""}`}>
			<div className="menu-page-return" onClick={onClose}>
				<ChevronLeft size={32} />
			</div>
			<div className="menu-page-icon">
				<Ptp height="100%" width="100%" />
			</div>
			<div className="menu-page-options">
				{data &&
					data
						.filter((option) => option.hasHole)
						.map((option, index) => (
							<div
								className="menu-page-item"
								key={index}
								onClick={() => {
									navigate(option.link);
									onClose();
								}}
							>
								{option.icon}
								<label>{option.title}</label>
							</div>
						))}
				<div
					className="menu-page-item"
					onClick={() => setShowProfileMenu(true)}
				>
					<Settings size={32} />
					<label>{t("navbar.userDropdown.settings").toUpperCase()}</label>
				</div>
				<div className="menu-page-item" onClick={handleLogout}>
					<CirclePower size={32} />
					<label>{t("navbar.userDropdown.logout").toUpperCase()}</label>
				</div>
			</div>
			<ProfileMenu
				show={showProfileMenu}
				onClose={() => setShowProfileMenu(false)}
			/>
		</div>
	);
};
