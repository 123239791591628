import { Button } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../../context";
import "./styles.scss";

export function HeaderMenuTabsWeb() {
	const { pathname } = useLocation();
	const [selectedTab, setSelectedTab] = useState<string>("");
	const { roles } = useContext(AuthContext).user;
	const isTechnicalAssistance = roles?.includes(4) || roles?.includes(3);

	useEffect(() => {
		if (pathname.split("/").includes("orders")) {
			return setSelectedTab("orders");
		}

		if (pathname.split("/").includes("billets")) {
			return setSelectedTab("billets");
		}

		if (pathname.split("/").includes("partsToReturn")) {
			return setSelectedTab("partsToReturn");
		}

		if (pathname.split("/").includes("catalog")) {
			return setSelectedTab("catalog");
		}

		if (pathname.split("/").includes("tags")) {
			return setSelectedTab("tags");
		}

		if (pathname.split("/").includes("subTags")) {
			return setSelectedTab("subTags");
		}
		if (pathname.split("/").includes("users")) {
			return setSelectedTab("users");
		}
		if (pathname.split("/").includes("partners")) {
			return setSelectedTab("partners");
		}
	}, [pathname]);

	return pathname.includes("/pratica") ? (
		<div className="header-menu-tabs-switch">
			<div className="header-menu-tabs">
				<NavLink to="/pt/pratica/orders">
					<Button
						size="md"
						title="PEDIDOS"
						variant={selectedTab === "orders" ? "outline" : "outline-dark"}
					/>
				</NavLink>
			</div>
			<div className="header-menu-tabs-larger">
				<NavLink to="/pt/pratica/partsToReturn">
					<Button
						size="md"
						title="PEÇAS A DEVOLVER"
						variant={
							selectedTab === "partsToReturn" ? "outline" : "outline-dark"
						}
					/>
				</NavLink>
			</div>
			<div className="header-menu-tabs">
				<NavLink to="/pt/pratica/billets">
					<Button
						size="md"
						title="BOLETOS"
						variant={selectedTab === "billets" ? "outline" : "outline-dark"}
					/>
				</NavLink>
			</div>
		</div>
	) : pathname.includes("/editor") && isTechnicalAssistance ? (
		<div className="header-menu-tabs-switch">
			<div className="header-menu-tabs">
				<NavLink to="/pt/editor/catalog">
					<Button
						size="md"
						title="CATALOGO"
						variant={selectedTab === "catalog" ? "outline" : "outline-dark"}
					/>
				</NavLink>
			</div>
			<div className="header-menu-tabs">
				<NavLink to="/pt/editor/tags">
					<Button
						size="md"
						title="TAGS"
						variant={selectedTab === "tags" ? "outline" : "outline-dark"}
					/>
				</NavLink>
			</div>
			<div className="header-menu-tabs">
				<NavLink to="/pt/editor/subTags">
					<Button
						size="md"
						title="SUBTAGS"
						variant={selectedTab === "subTags" ? "outline" : "outline-dark"}
					/>
				</NavLink>
			</div>
		</div>
	) : pathname.includes("/users") || pathname.includes("/partners") ? (
		<div className="header-menu-tabs-switch">
			<div className="header-menu-tabs">
				<NavLink to="/pt/users">
					<Button
						size="md"
						title="USUÁRIOS"
						variant={selectedTab === "users" ? "outline" : "outline-dark"}
					/>
				</NavLink>
			</div>
			<div className="header-menu-tabs">
				<NavLink to="/pt/partners">
					<Button
						size="md"
						title="PARCEIROS"
						variant={selectedTab === "partners" ? "outline" : "outline-dark"}
					/>
				</NavLink>
			</div>
		</div>
	) : (
		<div className="header-menu-tabs-switch"></div>
	);
}
