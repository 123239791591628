import { GenericTable } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext } from "react";
import { LuCalendarCheck2, LuCalendarClock, LuCalendarX } from "react-icons/lu";
import { PraticaContext } from "../../../context/PraticaContext";
import {
    BRANCH_TYPES,
    dateProtheusToDate,
    handleType
} from "../../../utils/protheus-functions";
import "./styles.scss";

export function PraticaOrderBillets() {
	const { sc5010, onGetBillet } = useContext(PraticaContext);

	const legends = {
		overdueInvoice: {
			key: "overdueInvoice",
			status: <LuCalendarX color="#C21618" size={18} />,
			label: t("PraticaBillets.legends.overdueInvoice")
		},
		today: {
			key: "today",
			status: <LuCalendarClock color="#5DAEFF" size={18} />,
			label: t("PraticaBillets.legends.today")
		},
		future: {
			key: "future",
			status: <LuCalendarCheck2 color="#048243" size={18} />,
			label: t("PraticaBillets.legends.future")
		}
	};

	const statusChange = (e1Vencto: string, e1Saldo: number) => {
		if (e1Saldo === 0) {
			return "future";
		}
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		const currentMonth = currentDate.getMonth() + 1;
		const currentDay = currentDate.getDate();

		const year = parseInt(e1Vencto.substring(0, 4));
		const mes = parseInt(e1Vencto.substring(4, 6));
		const day = parseInt(e1Vencto.substring(6, 8));

		if (
			year > currentYear ||
			(year === currentYear && mes > currentMonth) ||
			(year === currentYear && mes === currentMonth && day > currentDay)
		) {
			return "future";
		} else if (
			year === currentYear &&
			mes === currentMonth &&
			day === currentDay
		) {
			return "today";
		} else {
			return "overdueInvoice";
		}
	};

	return (
		<div className="pratica-order-items">
			<GenericTable
				onRowClicked={onGetBillet}
				data={sc5010?.se1010.map((item) => ({
					...item,
					id: item.rECNO,
					status: legends[statusChange(item?.e1Vencto, item.e1Saldo)]?.status,
					issueDate: dateProtheusToDate(item.e1Emissao)?.toLocaleDateString(
						"pt-BR"
					),
					dueDate: dateProtheusToDate(item.e1Vencto)?.toLocaleDateString(
						"pt-BR"
					),
					invoiceTitle: `${item.e1Num} - ${item.e1Prefixo}`,
					total: item.e1Valor.toLocaleString("pt-BR", {
						style: "currency",
						currency: "BRL"
					}),
					balance: item.e1Saldo.toLocaleString("pt-BR", {
						style: "currency",
						currency: "BRL"
					}),
					branch: handleType({
						value: item.e1Filorig,
						types: BRANCH_TYPES
					}),
					order: item.e1Prefixo === "FAT" ? "-" : item.e1Pedido
				}))}
				columns={[
					{
						key: "status",
						header: "",
						width: "38px"
					},
					{
						key: "issueDate",
						header: t("PraticaBillets.tableColumns.issueDate"),
						width: "8%"
					},
					{
						key: "branch",
						header: t("PraticaBillets.tableColumns.branch"),
						width: "9%"
					},
					{
						key: "order",
						header: t("PraticaBillets.tableColumns.order"),
						width: "9%"
					},
					{
						key: "invoiceTitle",
						header: t("PraticaBillets.tableColumns.invoiceTitle"),
						width: "12%"
					},
					{
						key: "e1Xcodbar",
						header: t("PraticaBillets.tableColumns.e1Xcodbar"),
						width: "calc(30% - 38px)"
					},
					{
						key: "total",
						header: t("PraticaBillets.tableColumns.total"),
						width: "10%"
					},
					{
						key: "balance",
						header: t("PraticaBillets.tableColumns.balance"),
						width: "10%"
					},
					{
						key: "dueDate",
						header: t("PraticaBillets.tableColumns.dueDate"),
						width: "12%"
					}
				]}
			/>
		</div>
	);
}
