import { OrderItemsContentEditingMode } from "./ItensListBody/OrderItemsContentEditingMode";
import { OrderItemsContentViewingMode } from "./ItensListBody/OrderItemsContentViewingMode";
import { OrderItemsHeader } from "./ItensListBody/OrderItemsHeader/OrderItemsHeader";
import { OrderItemsSectionAddItem } from "./ItensListBody/OrderItemsSectionAddItem";

export const OrderItem = {
	view: OrderItemsContentViewingMode,
	editing: OrderItemsContentEditingMode,
	header: OrderItemsHeader,
	add: OrderItemsSectionAddItem
};
