import React from "react";

interface TrashIconProps {
	width: string;
	height?: string;
	fill?: string;
}

const TrashIcon: React.FC<TrashIconProps> = ({ fill }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="14 5 23 30"
		>
			<g transform="translate(-238 -8)" fill={fill}>
				<path
					className="trash-icon"
					d="M3.875,27.187A2.812,2.812,0,0,0,6.687,30H20.229a2.812,2.812,0,0,0,2.813-2.812L24.708,7.5H2.208Zm15.1-15a.937.937,0,1,1,1.875,0l-.833,13.125a.938.938,0,0,1-1.875,0Zm-6.458,0a.937.937,0,1,1,1.875,0V25.312a.937.937,0,1,1-1.875,0Zm-6.458,0a.937.937,0,1,1,1.875,0l.833,13.125a.937.937,0,1,1-1.875,0ZM23.146,1.875H18.615l-.551-1.1A1.406,1.406,0,0,0,16.8,0h-6.7A1.39,1.39,0,0,0,8.853.779L8.3,1.875H3.771c-.518,0-.937.42-1.771,2.812a.937.937,0,0,0,.937.937H23.979a.937.937,0,0,0,.938-.937C24.083,2.295,23.664,1.875,23.146,1.875Z"
					transform="translate(250 13)"
				/>
			</g>
		</svg>
	);
};

export default TrashIcon;
