import { GenericTable, LoadingProgress, Paginator } from "@praticabr/ppsa";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderSection, ManageUserModal } from "../../components";
import { RegisterUserModal } from "../../components/RegisterUserModal";
import { UserContext } from "../../context";
import { useViewVariable } from "../../context/ViewContext";
import { store } from "../../store";
import "./style.scss";

export const Users = () => {
	const { mobileSize } = useViewVariable();

	const { list, isLoading, onPageChange, offset, total, onSearchParams } =
		useContext(UserContext);
	const [showNewUserModal, setShowNewUserModal] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<any>();
	const [showUpdateUserModal, setShowUpdateUserModal] =
		useState<boolean>(false);
	const { t } = useTranslation();
	const user = store.getState().auth;
	const adminCS = user.roles.includes(4);

	return (
		<>
			<div className="users">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={(value) => onSearchParams(value)}
						text={t("usersPage.searchHeader")}
					/>
					<HeaderSection.button
						text={t("usersPage.buttonHeader")}
						onClick={() => setShowNewUserModal(true)}
					/>
				</HeaderSection.root>
				<div className="users-table">
					<GenericTable
						data={(adminCS
							? list
							: list.filter(
									(user: any) => user && user.roles && !user.roles.includes(4)
								)
						)
							.sort((a, b) => b.id - a.id)
							.map((user) => {
								return {
									...user,
									partnerCode:
										user.partner.code.slice(0, 6) + " - " + user.partner.name,
									isActive:
										user.isActive === 0
											? t("usersPage.userStatus.active")
											: t("usersPage.userStatus.inactive")
								};
							})}
						columns={[
							{
								key: "contact",
								header: t("usersPage.tableColumns.name"),
								width: !mobileSize ? "30%" : "35%"
							},
							{
								key: "partnerCode",
								header: t("usersPage.tableColumns.partnerCode"),
								width: !mobileSize ? "40%" : "25%"
							},
							{
								key: "email",
								header: t("usersPage.tableColumns.email"),
								width: "30%"
							}
						]}
						onRowClicked={(user) => {
							setShowUpdateUserModal(true);
							setSelectedUser(user);
						}}
					/>
				</div>
				<Paginator
					totalPages={total}
					currentPage={offset}
					onPageChange={(value) => onPageChange(value)}
				/>
				{showNewUserModal && (
					<RegisterUserModal onClose={() => setShowNewUserModal(false)} />
				)}
				{showUpdateUserModal && selectedUser?.id && (
					<ManageUserModal
						user={selectedUser}
						onClose={() => setShowUpdateUserModal(false)}
					/>
				)}
				{isLoading && <LoadingProgress />}
			</div>
		</>
	);
};
