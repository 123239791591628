export const LANGUAGES: { [key: string]: { urlLang: string } } = {
	pt: {
		urlLang: "pt"
	},
	en: {
		urlLang: "en"
	},
	es: {
		urlLang: "es"
	}
};
