import { LoadingProgress, Paginator, Select } from "@praticabr/ppsa";
import { t } from "i18next";
import { Boxes, SearchX } from "lucide-react";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LuAlertTriangle } from "react-icons/lu";
import { useSelector } from "react-redux";
import { HeaderSection } from "../../components";
import { InfoExpansive } from "../../components/InfoExpansive";
import { ModalAlternativeProduct } from "../../components/ModalAlternativeProduct";
import { Tag } from "../../components/Tag";
import { ProductsContext } from "../../context/ProductsContext";
import { useViewVariable } from "../../context/ViewContext";
import { store } from "../../store";
import "./styles.scss";

export function CatalogPage() {
	const {
		productList,
		tags,
		subTags,
		onSearchParams,
		onPageChange,
		total,
		offset,
		isLoading,
		selectedTags,
		setSelectedTags,
		selectedSubTags,
		setSelectedSubTags
	} = useContext(ProductsContext);

	const [showAlternativeProduct, setShowAlternativeProduct] = useState<any>();

	const { screenSize } = useViewVariable();

	const { contact } = useSelector(
		(state: { auth: { contact: string } }) => state?.auth
	);

	const { i18n } = useTranslation();

	const language = i18n.language || store.getState().auth.language;

	return (
		<>
			<div className="catalog-page">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={onSearchParams}
						text={t("catalogPage.searchPlaceholder")}
					/>
					<div className="selects">
						<div className="select-tags">
							<Select
								id="tag"
								autoComplete="off"
								onSelectOptions={(option) => {
									setSelectedTags(option.map((value) => Number(value)));
								}}
								selectedOption={selectedTags.map((tag) => tag.toString())}
								placeholder={t("catalogPage.selectTagPlaceholder")}
								options={
									tags
										?.map((tag) => ({
											label: tag.label,
											value: String(tag.id)
										}))
										.sort((a, b) => a.label.localeCompare(b.label)) || []
								}
							/>
						</div>
						<div className="select-sub-tags">
							<Select
								id="sub-tag"
								autoComplete="off"
								onSelectOptions={(option) => {
									setSelectedSubTags(option.map((value) => Number(value)));
								}}
								selectedOption={selectedSubTags.map((subTag) =>
									subTag.toString()
								)}
								placeholder={t("catalogPage.selectSubTagPlaceholder")}
								options={
									subTags
										?.map((subTag) => ({
											label: subTag.label,
											value: String(subTag.id)
										}))
										.sort((a, b) => a.label.localeCompare(b.label)) || []
								}
							/>
						</div>
						{(!!selectedTags.length || !!selectedSubTags.length) && (
							<div className="erase-action">
								<SearchX
									size={24}
									color="#004992"
									onClick={() => {
										setSelectedTags([]);
										setSelectedSubTags([]);
									}}
								/>
							</div>
						)}
					</div>
				</HeaderSection.root>
				<div className="products">
					{productList.map((product) => (
						<div className="product" key={product.b1Cod}>
							<div className="product-image">
								{product.details?.files.length > 0 ? (
									product.details?.files
										?.sort((a, b) => a.id - b.id)
										?.map(
											(file: {
												id: React.Key | null | undefined;
												url: string | undefined;
											}) => (
												<img
													key={file.id}
													src={file.url}
													alt={product.b1Cod}
													onClick={() => window.open(file.url)}
												/>
											)
										)
								) : (
									<div className="no-image">
										<Boxes size={48} color="#004992" />
									</div>
								)}
							</div>
							<div className="product-info">
								<div
									className="header"
									onClick={() =>
										product?.sgi010?.length > 0 &&
										setShowAlternativeProduct(product)
									}
								>
									<h1>{`${product.b1Cod} - ${product.b1Desc}`}</h1>
									{product.sgi010?.length > 0 && (
										<>
											{screenSize.width <= 900 ? (
												<LuAlertTriangle title="Produto Com Código Alternativo" />
											) : (
												<Tag
													label="Produto com código alternativo"
													color="#C21618"
												/>
											)}
										</>
									)}
								</div>
								<div className="contain">
									<div className="info">
										<InfoExpansive
											label={"Aplicação:"}
											text={product.details?.application}
										/>
									</div>
									<div className="info">
										<InfoExpansive
											label={"Observação:"}
											text={product.details?.observation}
										/>
									</div>
								</div>
								<div className="contain">
									<div className="procedures">
										{product?.details?.procedures.map((procedure, index) => (
											<a
												title={procedure.name}
												key={index}
												href={`${procedure.link}`}
												target="_blank"
												rel="noreferrer"
											>
												{procedure.name}
											</a>
										))}
									</div>
								</div>
								<div className="footer">
									<div className="tags">
										{product?.details?.tags?.map((tagId, index) => {
											const currentTag = tags?.filter(
												(tag) => tag?.id === tagId
											);
											return (
												<Tag
													selected={selectedTags.includes(currentTag[0].id)}
													label={currentTag[0]?.label}
													color={currentTag[0]?.color}
													key={currentTag[0]?.id + product.b1Cod + index}
												/>
											);
										})}
									</div>
									<div className="subTags">
										{product?.details?.subTags?.map((subTagId, index) => {
											const currentSubTag = subTags?.filter(
												(tag) => tag?.id === subTagId
											);
											return (
												<Tag
													selected={selectedSubTags?.includes(
														currentSubTag[0].id
													)}
													label={currentSubTag[0]?.label}
													color={currentSubTag[0]?.color}
													key={currentSubTag[0]?.id + product.b1Cod + index}
												/>
											);
										})}
									</div>
								</div>
							</div>
							<div className="product-action">
								<h1>
									{product.da1010?.find((item) => item?.da1Codtab === "C20")
										?.da1Prcven &&
										Number(
											product.da1010?.find((item) => item?.da1Codtab === "C20")
												?.da1Prcven
										)?.toLocaleString("pt-BR", {
											style: "currency",
											currency: "BRL"
										})}
								</h1>
							</div>
						</div>
					))}
				</div>
				{showAlternativeProduct && (
					<ModalAlternativeProduct
						item={showAlternativeProduct}
						onClose={() => setShowAlternativeProduct(undefined)}
					/>
				)}
				<Paginator
					currentPage={offset}
					totalPages={total || 1}
					onPageChange={(value) => onPageChange(value)}
				/>
			</div>
			{isLoading && <LoadingProgress />}
		</>
	);
}
