import { useDispatch } from "react-redux";
import { updateNotificationsTypes } from "../../../models/usecases";
import { partner } from "../../../models/usecases/users";

export const useSignInRequest = () => {
	const dispatch = useDispatch();

	const signInRequest = async ({
		email,
		password
	}: {
		email: string;
		password: string;
	}) => {
		return new Promise((resolve, reject) => {
			try {
				dispatch({
					type: "@auth/SIGN_IN_REQUEST",
					payload: { email, password },
					resolve,
					reject
				});
			} catch (error) {
				reject(error);
			}
		});
	};

	return signInRequest;
};

export const signInSuccess = ({
	availablePartners,
	contact,
	token,
	email,
	roles,
	partner,
	userId,
	alterPass,
	allowEmailNotification,
	notificationManager
}: {
	availablePartners: partner[];
	contact: string;
	token: string;
	email: string;
	roles: number[];
	partner: partner;
	userId: number;
	alterPass: boolean;
	allowEmailNotification: boolean;
	notificationManager: number[];
}) => ({
	type: "@auth/SIGN_IN_SUCCESS",
	payload: {
		availablePartners,
		contact,
		token,
		email,
		roles,
		partner,
		userId,
		alterPass,
		allowEmailNotification,
		notificationManager
	}
});

export const signOut = () => ({
	type: "@auth/SIGN_OUT"
});

export const updatePass = (alterPass: boolean) => {
	return {
		type: "@auth/UPDATE_PASS",
		payload: alterPass
	};
};

export function updateLanguage(language: string) {
	return {
		type: "@auth/SET_LANGUAGE",
		payload: language
	};
}

export function updateTutorials(tutorials: Array<number>) {
	return {
		type: "@auth/SET_TUTORIALS",
		payload: tutorials
	};
}

export function updatePartner(payload: partner) {
	return {
		type: "@auth/UPDATE_PARTNER",
		payload
	};
}

export function updateNotificationManager(
	notificationManager: updateNotificationsTypes.Return
) {
	return {
		type: "@auth/SET_UPDATE_NOTIFICATION_MANAGER",
		payload: notificationManager
	};
}
