import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { history } from "../../../routes/history";
import { FREIGHT_TYPES, handleType } from "../../../utils/protheus-functions";
import "./styles.scss";

type InfoTimelineProps = {
	order: any;
};

export const InfoTimelineMobile = ({ order }: InfoTimelineProps) => {
	const { userId } = useSelector((state: any) => state?.auth);
	const isDealer = () => {
		return order?.userId === userId;
	};

	return (
		<div className="info-timeline-mobile">
			<div className="info-timeline-mobile-back"></div>

			<div className="info-timeline-mobile-header">
				<IoIosArrowBack
					color="#004687"
					size={30}
					onClick={() => history.back()}
				/>
				<header>Solicitação: {order?.id}</header>
			</div>
			<div className="info-timeline-mobile-infos">
				<div className="info-timeline-mobile-info-first-line">
					<div className="info-timeline-mobile-info">
						<header>Data: </header>
						{new Date(order?.createdAt?.toString())?.toLocaleDateString(
							"pt-BR"
						)}
					</div>
					<div className="info-timeline-mobile-info">
						<header>Tipo de frete: </header>
						<span>
							{handleType({
								value: order?.freightType,
								types: FREIGHT_TYPES
							})}
						</span>
					</div>
				</div>
				<div className="info-timeline-mobile-info">
					{isDealer() ? (
						<>
							<header>Concessionária: </header>
							<span title={order?.dealer?.contact}>
								{order?.dealer?.contact}
							</span>
						</>
					) : (
						<>
							<header>Cliente: </header>
							<span title={order?.user?.contact}>{order?.user?.contact}</span>
						</>
					)}
				</div>
				{order?.freightType === "F" && (
					<>
						<div className="info-timeline-mobile-info">
							<header>Nome da Transportadora: </header>
							<span>{order?.carrierName}</span>
						</div>
						<div className="info-timeline-mobile-info">
							<header>Contato da Transportadora: </header>
							<span>{order?.carrierContact}</span>
						</div>
					</>
				)}
				<div className="info-timeline-mobile-info">
					<header>Observação: </header>
					<span>{order?.observation}</span>
				</div>
			</div>
		</div>
	);
};
