import {
    Button,
    CheckBox,
    Divider,
    LoadingProgress,
    Modal
} from "@praticabr/ppsa";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NotificationContext } from "../../context/NotificationContext";
import { store } from "../../store";
import { updateNotificationManager } from "../../store/modules/auth/actions";
import "./styles.scss";

type INotificationManagerModal = {
	onClose: () => void;
};

export const NotificationManagerModal = ({
	onClose
}: INotificationManagerModal) => {
	const {
		notificationTypes,
		isLoading,
		handleGetNotificationsTypes,
		handleUpdateNotificationUserType
	} = useContext(NotificationContext);

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const user = store.getState().auth;
	const { notificationManager = [], allowEmailNotification } = user;
	const { register, handleSubmit, getValues, setValue } = useForm({
		defaultValues: {
			allowEmailNotification,
			notificationTypesId: notificationManager || []
		}
	});

	useEffect(() => {
		handleGetNotificationsTypes();
	}, []);

	const onSubmit = (data: any) => {
		handleUpdateNotificationUserType(data, (response) => {
			dispatch(updateNotificationManager(response));
			onClose();
		});
	};

	const handleSelectCheckbox = (item: number) => {
		const currentIndex = getValues("notificationTypesId")?.findIndex(
			(type: number) => type === item
		);
		const newChecked = [
			...getValues("notificationTypesId")?.map((type: number) => type)
		];

		if (currentIndex === -1) {
			newChecked.push(item);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setValue("notificationTypesId", newChecked);
	};

	return (
		<Modal.root>
			<Modal.header
				{...{ onClose }}
				title={t("notificationManagerModal.title")}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="notification-manager-component">
						<Divider title={t("notificationManagerModal.dividers.email")} />
						<div className="notification-manager-email">
							<label htmlFor="email-notifications">
								<CheckBox
									id="email-notifications"
									variant="standard"
									format="square"
									defaultChecked={allowEmailNotification}
									{...register("allowEmailNotification")}
									onChange={(e) =>
										setValue("allowEmailNotification", e.currentTarget.checked)
									}
								/>
								<span>
									{t(
										"notificationManagerModal.modalOptions.allowEmailNotifications"
									)}
								</span>
							</label>
						</div>
						<Divider title={t("notificationManagerModal.dividers.request")} />
						<div className="notification-manager">
							{!!notificationTypes?.length &&
								notificationTypes?.map((type: any) => (
									<label key={type.id} htmlFor={type.id}>
										<CheckBox
											id={type.id}
											variant="standard"
											format="square"
											defaultChecked={getValues("notificationTypesId")?.some(
												(obj: number) => obj === type.id
											)}
											onChange={() => handleSelectCheckbox(type.id)}
										/>
										<span>
											{t(
												`notificationManagerModal.modalOptions.${type.description}`
											)}
										</span>
									</label>
								))}
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="notification-manager-action">
						<Button
							variant="confirmation-solid"
							title={t("notificationManagerModal.button.confirmation")}
							size={"lg"}
							onClick={handleSubmit(onSubmit)}
						/>
						<Button
							variant="cancellation"
							title={t("notificationManagerModal.button.cancellation")}
							size={"lg"}
							onClick={onClose}
						/>
					</div>
				</Modal.body.actions>
				{isLoading && <LoadingProgress />}
			</Modal.body>
		</Modal.root>
	);
};
