import {
    Button,
    CheckBox,
    GenericTable,
    Input,
    Modal,
    Select
} from "@praticabr/ppsa";
import React, { useContext } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { OrderContext } from "../../context/OrderContext";

import "./styles.scss";

type Props = {
	requestId: number;
	items: any[];
	onClose: () => void;
};

const options = [
	{ value: "out_of_stock", label: "Sem estoque" },
	{ value: "others", label: "Outros" }
];

export const ToMeetModal = ({ requestId, items, onClose }: Props) => {
	const { handleMeetRequest } = useContext(OrderContext);

	const {
		register,
		getValues,
		setValue,
		control,
		watch,
		getFieldState,
		formState: { errors },
		setError,
		clearErrors,
		handleSubmit
	} = useForm({
		defaultValues: {
			type: "",
			requestId,
			items: items?.map((item) => ({
				...item,
				reason:
					!item?.observation || item?.observation === "Sem estoque"
						? ["out_of_stock"]
						: ["others"],
				amountRequested: item.amount - item.amountServed,
				valueServed: 0
			}))
		}
	});

	watch("items");

	const onSubmit = async (data: any) => {
		let type: string = "TOTALLY_MET";

		for await (const item of data.items) {
			item.amountServed = item.valueServed;
			if (item.amountServed === item.amountRequested) {
				item.status = "TOTAL";
			}
			if (item.status !== "TOTAL") {
				type = "PARTIALLY_MET";
			}
			if (item.amountServed === 0) {
				item.status = "NOT_MET";
			}
		}

		data.type = type;
		handleMeetRequest(data, (error: Error) => {
			if (!error) {
				onClose();
			}
		});
	};

	const { fields } = useFieldArray({
		control,
		name: "items"
	});

	const handleChangeAmount = (index: number, value: number, max: number) => {
		if (value < max) {
			setValue(`items.${index}.status`, "PARTIAL");
			const element = document.getElementById(
				"serve-everyone"
			) as HTMLInputElement;
			element.checked = false;
		}
		if (value === 0) {
			setValue(`items.${index}.status`, "NOT_MET");
			const element = document.getElementById(
				"serve-everyone"
			) as HTMLInputElement;
			element.checked = false;
		}
		value >= max && setValue(`items.${index}.status`, "TOTAL");
		setValue(`items.${index}.valueServed`, value);
	};

	const serveEveryone = (checked: boolean) => {
		checked &&
			fields.map((item: any, index) => {
				setValue(`items.${index}.valueServed`, item.amountRequested);
				setValue(`items.${index}.status`, "TOTAL");
				return null;
			});
	};

	return (
		<Modal.root>
			<Modal.header onClose={onClose} title="Atender" />
			<Modal.body>
				<Modal.body.main>
					<div className="to-meet">
						<div className="table">
							<GenericTable
								data={[
									...fields
										.map((field, index) => ({
											...field,
											code: field.code,
											description: field.product.b1Desc,
											amountRequested: field.amountRequested,
											valueServed: (
												<Input
													variant="light"
													type="number"
													{...register(`items.${index}.valueServed`, {
														valueAsNumber: true
													})}
													onChange={(e) =>
														handleChangeAmount(
															index,
															Number(e.target.value),
															field.amount
														)
													}
													errors={
														getFieldState(`items.${index}.valueServed`).error
													}
												/>
											),
											reason:
												getValues(`items.${index}.status`) === "PARTIAL" ||
												getValues(`items.${index}.status`) === "NOT_MET" ? (
													<Select
														id={`items-${index}-reason`}
														selectedOption={getValues(`items.${index}.reason`)}
														onSelectOptions={(selected) => {
															setValue(`items.${index}.reason`, selected);
														}}
														options={options}
														variant="light"
														{...register(`items.${index}.reason`, {
															required: true
														})}
														errors={
															getFieldState(`items.${index}.reason`).error
														}
													/>
												) : (
													"-"
												),
											observation:
												(getValues(`items.${index}.status`) === "PARTIAL" ||
													getValues(`items.${index}.status`) === "NOT_MET") &&
												getValues(`items.${index}.reason`)[0] !==
													"out_of_stock" ? (
													<Input
														variant="light"
														{...register(`items.${index}.observation`, {
															required: {
																value:
																	getValues(`items.${index}.reason`) ===
																	"others",
																message: "Insira uma observação"
															}
														})}
														errors={
															getFieldState(`items.${index}.observation`).error
														}
													/>
												) : (
													"-"
												),
											expectedAt:
												getValues(`items.${index}.status`) === "PARTIAL" ||
												getValues(`items.${index}.status`) === "NOT_MET" ? (
													<Input
														type="date"
														variant="light"
														{...register(`items.${index}.expectedAt`)}
													/>
												) : (
													"-"
												)
										}))
										.filter((field) => field.amount !== field.amountServed)
								]}
								columns={[
									{ key: "code", header: "Código", width: "10%" },
									{ key: "description", header: "Descrição", width: "24%" },
									{
										key: "amountRequested",
										header: "Qtd. Solicitada",
										width: "12%"
									},
									{ key: "valueServed", header: "Quantidade", width: "10%" },
									{ key: "reason", header: "Motivo", width: "15%" },
									{ key: "observation", header: "Observação", width: "15%" },
									{ key: "expectedAt", header: "Data prevista", width: "14%" }
								]}
							/>
						</div>
						<div className="footer">
							<label htmlFor="serve-everyone">
								<CheckBox
									variant="standard"
									format="square"
									id="serve-everyone"
									onChange={(e) => serveEveryone(e.target.checked)}
								/>
								<span>Atender todos</span>
							</label>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="to-meet-actions">
						<Button
							size="lg"
							variant="confirmation-solid"
							title="Atender"
							onClick={handleSubmit(onSubmit)}
						/>
						<Button
							size="lg"
							variant="cancellation"
							title="Cancelar"
							onClick={onClose}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	);
};
