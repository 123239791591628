import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
	forgotPasswordRequest,
	loginRequest,
	newPasswordRequest,
	recoveryPasswordRequest,
	updateUserRequest,
	userLogged
} from "../models/usecases/users";
import {
	forgotMyPass,
	newPassword,
	recoveryPass,
	userUpdateProfile
} from "../services";
import { store } from "../store";
import {
	signInSuccess,
	signOut,
	useSignInRequest
} from "../store/modules/auth/actions";

export const AuthContext = createContext({
	user: {} as userLogged,
	isLoading: false,
	isCompleted: false,
	onLogin: (data: loginRequest) => {},
	onLogout: () => {},
	onForgotPassword: (data: forgotPasswordRequest) => {},
	onUpdateUser: (data: updateUserRequest, callback?: () => void) => {},
	onRecoverPassword: (data: newPasswordRequest, callback: () => void) => {},
	handleRecoveryPassword: (data: any, callback?: () => void) => {}
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isCompleted, setIsCompleted] = useState<boolean>(false);
	const userRef = useRef<userLogged>(store.getState().auth);

	const signInRequest = useSignInRequest();

	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();

	const language = i18n.language || store.getState().auth.language;

	const handleLogin = async (data: loginRequest) => {
		try {
			setIsLoading(true);
			await signInRequest(data);
			toast.success(t("authContext.loginSuccess"));
		} catch (error) {
			setIsLoading(false);
			toast.error(t("authContext.loginError"));
		}
	};

	const handleForgotPassword = (data: forgotPasswordRequest) => {
		setIsLoading(true);
		forgotMyPass(data)
			.then(() => toast.success(t("authContext.updatePasswordSendEmail")))
			.catch(() => toast.error(t("authContext.updatePasswordError")))
			.finally(() => {
				setIsLoading(false);
				setIsCompleted(true);
			});
	};

	const handleRecoverPassword = (
		data: newPasswordRequest,
		callback: () => void
	) => {
		setIsLoading(true);
		newPassword(userRef.current.userId, data)
			.then((response) => {
				dispatch(
					signInSuccess({
						...userRef.current,
						token: response.data.token,
						alterPass: false
					})
				);
				toast.success(t("authContext.updatePasswordSuccess"));
				callback();
			})
			.catch(() => toast.error(t("authContext.updatePasswordError")))
			.finally(() => setIsLoading(false));
	};

	const onUpdateUser = (data: updateUserRequest, callback?: () => void) => {
		setIsLoading(true);
		userUpdateProfile(userRef.current.userId, data)
			.then(() => {
				toast.success(t("authContext.updateUserSuccess"));
				callback && callback();
			})
			.catch((e) => {
				toast.error(t("authContext.updateUserError"));
			})
			.finally(() => setIsLoading(false));
	};

	const handleRecoveryPassword = (
		data: recoveryPasswordRequest,
		callback?: () => void
	) => {
		setIsLoading(true);
		recoveryPass(data)
			.then(async () => {
				toast.success(t("authContext.updatePasswordSuccess"));
				await signInRequest(data);
				callback && callback();
			})
			.catch(() => toast.error(t("authContext.updatePasswordError")))
			.finally(() => setIsLoading(false));
	};

	const handleLogout = () => {
		userRef.current = {} as userLogged;
		dispatch(signOut());
		window.location.href = `/${language}/login`;
	};

	const value = {
		user: userRef.current,
		isLoading,
		isCompleted,
		onLogin: handleLogin,
		onLogout: handleLogout,
		onUpdateUser,
		onForgotPassword: handleForgotPassword,
		onRecoverPassword: handleRecoverPassword,
		handleRecoveryPassword
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
