import React from "react";
import { useViewVariable } from "../../context/ViewContext";
import HeaderMenuTabsMobile from "./HeaderMenuTabsMobile";
import { HeaderMenuTabsWeb } from "./HeaderMenuTabsWeb";

const HeaderMenuTabs = () => {
	const { mobileSize } = useViewVariable();

	if (mobileSize) {
		return <HeaderMenuTabsMobile />;
	} else {
		return <HeaderMenuTabsWeb />;
	}
};

export default HeaderMenuTabs;
