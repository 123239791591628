import { Button, Input, LoadingProgress, Modal } from "@praticabr/ppsa";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import { newPasswordRequest } from "../../models/usecases/users";
import { store } from "../../store";
import "./styles.scss";

type Props = {
	onClose: () => void;
};

export const UserPasswordModal = ({ onClose }: Props) => {
	const { isLoading, onRecoverPassword, user } = useContext(AuthContext);
	const { t } = useTranslation();
	const alterPass = store.getState().auth.alterPass;

	const {
		handleSubmit,
		register,
		getValues,
		formState: { errors }
	} = useForm<newPasswordRequest>({ defaultValues: { token: user.token } });

	const onSubmit = (data: any) => {
		onRecoverPassword(data, onClose);
	};

	return (
		<>
			<Modal.root>
				<Modal.header title={t("userPasswordModal.title")} onClose={onClose} />
				<Modal.body>
					<form
						autoComplete="off"
						className="user-password-modal"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Modal.body.main>
							<div className="content">
								<div className="form">
									<div className="form-item">
										<label htmlFor="password">
											{t("userPasswordModal.oldPassword.label")}
										</label>
										<Input
											variant="light"
											type="password"
											errors={errors.oldPassword}
											placeholder={t(
												"userPasswordModal.oldPassword.placeholder"
											)}
											{...register("oldPassword", {
												required: {
													value: true,
													message: t(
														"userPasswordModal.oldPassword.requiredAlert"
													)
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="password">
											{t("userPasswordModal.passwordInput.label")}
										</label>
										<Input
											variant="light"
											type="password"
											errors={errors.password}
											placeholder={t(
												"userPasswordModal.passwordInput.placeholder"
											)}
											{...register("password", {
												required: {
													value: true,
													message: t(
														"userPasswordModal.passwordInput.requiredAlert"
													)
												},
												validate: {
													value: (value) => {
														if (value === getValues("oldPassword"))
															return t(
																"userPasswordModal.passwordInput.passwordMismatchAlert"
															);
													}
												},
												pattern: {
													value:
														/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
													message: t(
														"userPasswordModal.passwordInput.patternAlert"
													)
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="passwordConfirmationInput">
											{t("userPasswordModal.passwordConfirmationInput.label")}
										</label>
										<Input
											variant="light"
											type="password"
											errors={errors.passwordConfirmation}
											placeholder={t(
												"userPasswordModal.passwordConfirmationInput.placeholder"
											)}
											{...register("passwordConfirmation", {
												required: {
													value: true,
													message: t(
														"userPasswordModal.passwordConfirmationInput.requiredAlert"
													)
												},
												validate: {
													value: (value) => {
														if (value !== getValues("password"))
															return t(
																"userPasswordModal.passwordConfirmationInput.passwordMismatchAlert"
															);
													}
												}
											})}
										/>
									</div>
								</div>
								<span>{t("userPasswordModal.info")}</span>
							</div>
						</Modal.body.main>
						<Modal.body.actions>
							<div className="user-password-modal-actions">
								<Button
									size="lg"
									type="submit"
									variant="confirmation-solid"
									title={t("userPasswordModal.submitAction")}
								/>
								{!alterPass && (
									<Button
										size="lg"
										type="button"
										onClick={onClose}
										variant="cancellation"
										title={t("userPasswordModal.cancelAction")}
									/>
								)}
							</div>
						</Modal.body.actions>
					</form>
				</Modal.body>
			</Modal.root>
			{isLoading && <LoadingProgress />}
		</>
	);
};
