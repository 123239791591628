import { t } from "i18next";
import React, { ReactNode, createContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getSellerList } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const SellerContext = createContext({
	list: [] as any[],
	isLoading: false,
	onSearchParams: (searchTerm: string) => {},
	onGetSellerList: (params: any) => {}
});

const defaultParams = defaultListParams();

export const SellerProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const listParams = useRef<ListParams>(defaultParams);

	const [list, setList] = useState<any[]>([]);

	const handleGetSellerList = async () => {
		try {
			const { term } = { ...listParams.current };
			const params = {
				term
			};
			setIsLoading(true);
			const response = await getSellerList(params);
			setList(response.data);
		} catch (error) {
			toast.error(t("equipmentContext.getEquipmentsListError"));
		} finally {
			setIsLoading(false);
		}
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		handleGetSellerList();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const value = {
		list,
		...listParams.current,
		isLoading,
		onSearchParams,
		onGetSellerList: handleGetSellerList
	};

	return (
		<SellerContext.Provider value={value}>{children}</SellerContext.Provider>
	);
};
