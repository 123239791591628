import { produce } from "immer";

const INITIAL_STATE = {
	availablePartners: [],
	token: null,
	contact: null,
	userId: null,
	email: null,
	language: "pt",
	partner: null,
	alterPass: false,
	roles: [],
	notificationManager: [],
	allowEmailNotification: null
};

export default function auth(state = INITIAL_STATE, action: any) {
	return produce(state, (draft) => {
		switch (action.type) {
			case "@auth/SIGN_IN_SUCCESS": {
				draft.availablePartners = action.payload.availablePartners;
				draft.token = action.payload.token;
				draft.contact = action.payload.contact;
				draft.userId = action.payload.userId;
				draft.email = action.payload.email;
				draft.partner = action.payload.partner;
				draft.roles = action.payload.roles;
				draft.alterPass = action.payload.alterPass;
				draft.allowEmailNotification = action.payload.allowEmailNotification;
				draft.notificationManager = action.payload.notificationManager;
				break;
			}
			case "@auth/SIGN_OUT": {
				draft.availablePartners = [];
				draft.token = null;
				draft.contact = null;
				draft.userId = null;
				draft.email = null;
				draft.partner = null;
				draft.roles = [];
				draft.alterPass = false;
				break;
			}
			case "@auth/UPDATE_PASS": {
				draft.alterPass = action.payload;
				break;
			}
			case "@auth/UPDATE_PARTNER": {
				draft.partner = action.payload;
				break;
			}
			case "@auth/SET_UPDATE_NOTIFICATION_MANAGER":
				draft.notificationManager = action.payload.notificationManager;
				draft.allowEmailNotification = action.payload.allowEmailNotification;
				break;
			default: {
				return state;
			}
		}
	});
}
