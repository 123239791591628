import { Input } from "@praticabr/ppsa";
import React, { ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { recoveryPasswordRequest } from "../../models/usecases/users";
import "./styles.scss";

type Props = {
	children: ReactNode;
	form: UseFormReturn<recoveryPasswordRequest>;
};

export const NewPasswordContain = ({ form, children }: Props) => {
	const { t } = useTranslation();

	const {
		register,
		formState: { errors },
		setValue,
		getValues
	} = form;

	const email = window?.location?.search
		?.substring(1)
		?.split("=")[2]
		?.split("&")[0];

	const code_auth = window?.location?.search?.substring(1)?.split("=")[3];

	setValue("email", email);
	setValue("code_auth", code_auth);

	return (
		<div className="authenticate-form">
			<div className="authenticate-input">
				<p>{t("loginPage.newPasswordInput.label")}</p>
				<Input
					variant="light"
					type="password"
					autoComplete="off"
					errors={errors.password}
					placeholder={t("loginPage.newPasswordInput.placeholder")}
					{...register("password", {
						required: {
							value: true,
							message: t("loginPage.newPasswordInput.requiredAlert")
						},
						pattern: {
							value:
								/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
							message: t("loginPage.newPasswordInput.patternAlert")
						}
					})}
				/>
			</div>
			<div className="authenticate-input">
				<p>{t("loginPage.newPasswordConfirmationInput.label")}</p>
				<Input
					variant="light"
					type="password"
					autoComplete="off"
					errors={errors.passwordConfirmation}
					placeholder={t("loginPage.newPasswordConfirmationInput.placeholder")}
					{...register("passwordConfirmation", {
						required: {
							value: true,
							message: t("loginPage.newPasswordConfirmationInput.requiredAlert")
						},
						validate: {
							value: (value) => {
								if (value !== getValues("password"))
									return t(
										"loginPage.newPasswordConfirmationInput.passwordMismatchAlert"
									);
							}
						}
					})}
				/>
			</div>
			<div className="actions">{children}</div>
		</div>
	);
};
