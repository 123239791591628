import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

type Props = {
	handleEdit: () => void;
	handleDelete: () => void;
	handleObservation: () => void;
	handleToMeet: () => void;
	isDealer: boolean;
	order: any;
};

export const MenuSelector = ({
	handleDelete,
	handleEdit,
	handleObservation,
	handleToMeet,
	isDealer,
	order
}: Props) => {
	const {
		i18n: { language },
		t
	} = useTranslation();

	const allAttended = () => {
		if (order?.items?.length > 0) {
			return order?.items.every(
				(item: any) => item?.amountServed >= item?.amount
			);
		}
		return false;
	};

	return (
		<section className="menu-area-selector">
			{isDealer ? (
				<>
					<div className="menu-area-selector-item" onClick={handleEdit}>
						<span>{`Editar`}</span>
					</div>
					{order?.status === "NEW" ? (
						<div className="menu-area-selector-item" onClick={handleDelete}>
							<span>{`Excluir`}</span>
						</div>
					) : (
						order?.status !== "CANCELLATION_REQUEST" && (
							<div className="menu-area-selector-item" onClick={handleDelete}>
								<span>{`Solicitar cancelamento`}</span>
							</div>
						)
					)}
					<div className="menu-area-selector-item" onClick={handleObservation}>
						<span>{`Adicionar observação`}</span>
					</div>
				</>
			) : (
				<>
					{!allAttended() && (
						<div className="menu-area-selector-item" onClick={handleToMeet}>
							<span>{`Atender`}</span>
						</div>
					)}
					<div className="menu-area-selector-item" onClick={handleObservation}>
						<span>{`Adicionar observação`}</span>
					</div>
				</>
			)}
		</section>
	);
};
