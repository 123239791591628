import {
    Button,
    ConfirmModal,
    Input,
    LoadingProgress,
    Modal
} from "@praticabr/ppsa";
import { Trash2Icon } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Sb1010sContext } from "../../context/Sb1010sContext";
import "./style.scss";

type ManageTagModalProps = {
	onClose: () => void;
	type: "TAG" | "SUBTAG";
	data: any;
};

export const ManageTagModal = ({
	onClose,
	type,
	data
}: ManageTagModalProps) => {
	const [showModal, setShowModal] = useState<boolean>(false);

	const isTagPage = type === "TAG";

	const {
		isLoading,
		onDeleteTag,
		onDeleteSubTag,
		onUpdateTag,
		onUpdateSubTag,
		onGetTagsList,
		onGetSubTagsList,
		countProductsWithSubTag,
		countProductsWithTag,
		onCountProductsWithSubTag,
		onCountProductsWithTag
	} = useContext(Sb1010sContext);

	const {
		handleSubmit,
		register,
		formState: { errors },
		setValue
	} = useForm();

	useEffect(() => {
		if (isTagPage) {
			onCountProductsWithTag(data?.id);
		} else {
			onCountProductsWithSubTag(data?.id);
		}
	}, []);

	const onSubmit = (newData: any) => {
		const updateData = {
			id: data?.id,
			label: newData?.label,
			color: newData?.color
		};

		if (isTagPage) {
			onUpdateTag(updateData, handleClose);
		} else {
			onUpdateSubTag(updateData, handleClose);
		}
	};

	const handleClose = () => {
		onClose();
		if (isTagPage) {
			onGetTagsList();
		} else {
			onGetSubTagsList();
		}
	};

	return (
		<Modal.root>
			<Modal.header onClose={onClose} title={`EDITAR ${type}`} />
			<Modal.body>
				<Modal.body.main>
					<div className="manage-tag-modal">
						<div className="manage-tag-modal-form">
							<div className="manage-tag-modal-form-item">
								<label>Nome</label>
								<Input
									variant="light"
									defaultValue={data?.label}
									{...register("label", {
										required: {
											value: true,
											message: "Campo nome é obrigatório"
										}
									})}
									onChange={(e) => setValue("label", e.target.value)}
								/>
							</div>
							<div className="manage-tag-modal-form-item">
								<label>Cor</label>
								<Input
									variant="light"
									type="color"
									defaultValue={data?.color}
									{...register("color", {
										required: {
											value: true,
											message: "Campo cor é obrigatório"
										}
									})}
									onChange={(e) => setValue("color", e.target.value)}
								/>
							</div>
							<div className="manage-tag-modal-trash">
								<label>
									{`${isTagPage ? countProductsWithTag : countProductsWithSubTag} produto${
										isTagPage
											? countProductsWithTag === 1
												? ""
												: "s"
											: countProductsWithSubTag === 1
												? ""
												: "s"
									} com essa ${type}`}
								</label>
							</div>
							<div
								className="manage-tag-modal-trash"
								onClick={() => {
									if (isTagPage) {
										countProductsWithTag <= 0 && setShowModal(true);
									} else {
										countProductsWithSubTag <= 0 && setShowModal(true);
									}
								}}
							>
								<Trash2Icon
									color={
										isTagPage
											? countProductsWithTag > 0
												? "#CCCCCC"
												: "red"
											: countProductsWithSubTag > 0
												? "#CCCCCC"
												: "red"
									}
								/>
								<label
									style={{
										color: isTagPage
											? countProductsWithTag > 0
												? "#CCCCCC"
												: "#333333"
											: countProductsWithSubTag > 0
												? "#CCCCCC"
												: "#333333",
										cursor: isTagPage
											? countProductsWithTag > 0
												? "not-allowed"
												: "pointer"
											: countProductsWithSubTag > 0
												? "not-allowed"
												: "pointer"
									}}
								>
									Excluir
								</label>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="manage-tag-modal-action">
						<Button
							size="lg"
							title="Salvar"
							variant="confirmation-solid"
							onClick={() => handleSubmit(onSubmit)()}
						/>
						<Button
							size="lg"
							title="Cancelar"
							variant="cancellation"
							onClick={onClose}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
			{isLoading && <LoadingProgress />}
			{showModal && (
				<ConfirmModal
					title="Exclusão"
					text={<span>Deseja excluir essa {type} ?</span>}
					subText="Essa ação não é reversível!"
					onConfirm={() => {
						if (isTagPage) {
							onDeleteTag({ id: data?.id }, handleClose);
						} else {
							onDeleteSubTag({ id: data?.id }, handleClose);
						}
					}}
					onCancel={() => setShowModal(false)}
				/>
			)}
		</Modal.root>
	);
};
