/* eslint-disable prettier/prettier */
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { history } from "../routes/history";
import { store } from "../store";
import { updateLanguage } from "../store/modules/auth/actions";
import { LANGUAGES } from "./languages";

export function LanguageSwitcher() {
	const { user } =
		useContext(AuthContext);

	const { i18n } = useTranslation();
	const location = useLocation();

	const storeLanguage = store.getState().auth.language;

	const token = store.getState().auth.token;

	const dispatch = useDispatch();

	// const onChangeLanguage = (language: string) => {
	// 	onUpdateUserLanguage({
	// 		language
	// 	});
	// };

	useEffect(() => {
		const currentUrlLang = location.pathname.split("/")[1] || storeLanguage;

		const currentLanguage = LANGUAGES[currentUrlLang];

		const currentPathName = location.pathname.replace(/^\/[a-zA-Z0-9]+/, "");

		if (!currentLanguage) {
			i18n.changeLanguage("pt");
			dispatch(updateLanguage("pt"));

			if (currentPathName) {
				window.location.href = `/${store
					.getState()
					.auth.language?.split("-")[0]}${currentPathName !== "/"
						? currentPathName
						: token
							? "/"
							: `/login`
					}`;
			} else {
				history.replace(
					`/${store.getState().auth.language?.split("-")[0] || "pt"}/home`
				);
			}
		} else {
			i18n.changeLanguage(currentLanguage.urlLang);
			dispatch(updateLanguage(currentLanguage.urlLang));

			if (!location.pathname.split("/")[1]) {
				window.location.href = `/${store.getState().auth.language?.split("-")[0]}${currentPathName !== "/"
					? currentPathName
					: token
						? "/"
						: `/login`
					}`;
			}
		}
	}, []);

	return null;
}
